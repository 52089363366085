// @ts-nocheck
import {Column} from 'react-table'
import {ClassActionsCell} from './ClassActionsCell'
import {ClassCustomHeader} from './ClassCustomHeader'
import {Class} from '../../core/_models'

const classColumns: ReadonlyArray<Column<Class>> = [
  {
    Header: (props) => (
      <ClassCustomHeader tableProps={props} title='Code' className='min-w-125px' />
    ),
    accessor: 'code',
  },
  {
    Header: (props) => (
      <ClassCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    accessor: 'name',
    Cell: ({...props}) =>
      props.data[props.row.index].name ?? props.data[props.row.index].courseName,
  },
  {
    Header: (props) => (
      <ClassCustomHeader tableProps={props} title='Type' className='min-w-125px' />
    ),
    accessor: 'typeName',
  },
  {
    Header: (props) => (
      <ClassCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'dataStatusName',
  },
  {
    Header: (props) => (
      <ClassCustomHeader tableProps={props} title='Actions' className='text-center min-w-200px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <ClassActionsCell
        id={props.data[props.row.index].id}
        name={props.data[props.row.index].name}
        statusId={props.data[props.row.index].dataStatusId}
      />
    ),
  },
]

export {classColumns}
