import axios from 'axios'
import {IBaseResponse} from '../../../../auth'
import {LecturerNotAttendReport} from './_model'

const API_URL = process.env.REACT_APP_BASE_API_URL
const REPORT_LECTURER_NOT_ATTEND = `${API_URL}/reports/lecturer-not-attend`

const getLecturerNotAttend = async (
  query: string
): Promise<IBaseResponse<LecturerNotAttendReport[]>> => {
  const response = await axios.get(`${REPORT_LECTURER_NOT_ATTEND}?${query}`)
  return response.data
}

export {getLecturerNotAttend}
