import {MeetAttendanceSearch} from './MeetAttendanceSearch'
import {MeetAttendanceToolbar} from './MeetAttendanceToolbar'

const MeetAttendanceHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <MeetAttendanceSearch />
      <div className='card-toolbar'>
        <MeetAttendanceToolbar />
      </div>
    </div>
  )
}

export {MeetAttendanceHeader}
