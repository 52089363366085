import {useMemo, Fragment, useState, useRef, useEffect} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {useQueryResponse, useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {initialQueryState, KTCardBody} from '../../../../../../_metronic/helpers'
import {ReportLoading} from '../../../components/ReportLoading'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import clsx from 'clsx'
import {formatDateTime} from '../../../../../utils/date'
import {quizResultByGroupColumns} from './columns/_columns'
import {QuizResultByGroup} from '../core/_model'
import BlockLoading from '../../../../../components/BlockLoading'

const QuizResultByGroupTable = () => {
  const d = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => d, [d])
  const columns = useMemo(() => quizResultByGroupColumns, [])
  const {state, updateState} = useQueryRequest()
  const {refetch} = useQueryResponse()
  const [sortBy, setSortBy] = useState('rank')
  const [sortDir, setSortDir] = useState<'asc' | 'desc' | undefined>('asc')

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: data ?? [],
  })

  useEffect(() => {
    updateState({...initialQueryState, sortName: sortBy, sortDir: sortDir})
    setTimeout(() => {
      refetch()
    }, 100)
  }, [sortBy, sortDir])

  const handleSort = (name: string) => {
    if (name === sortBy) {
      setSortDir(sortDir === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(name)
      setSortDir('asc')
    }
  }
  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive' style={{height: 'calc(100vh - 406px)'}}>
        {rows.length > 0 ? (
          rows.map((row: Row<any>, i) => {
            return (
              <table
                key={i}
                id='kt_table_attendance'
                className='table table-striped table-rounded dataTable align-middle table-row-dashed no-footer gs-7 fs-6 gy-5'
                {...getTableProps()}
              >
                <thead className='sticky-top'>
                <tr className='group-header'>
                  <td
                    colSpan={99}
                    className='text-center fw-bold fs-7 text-center text-uppercase border-primary text-black'
                  >
                    {row.original.groupClassName}
                  </td>
                </tr>
                <tr className="text-center text-muted fw-bolder fs-7 text-uppercase gs-0 bg-white">
                  <th className="w-100px text-center">No</th>
                  <th
                    className={clsx(
                      'text-start cursor-pointer',
                      {'table-sort-asc': sortBy === 'name' && sortDir === 'asc'},
                      {'table-sort-desc': sortBy === 'name' && sortDir === 'desc'},
                    )}
                    onClick={() => handleSort('name')}
                  >
                    Name
                  </th>
                  <th
                    className={clsx(
                      'text-start cursor-pointer',
                      {'table-sort-asc': sortBy === 'nim' && sortDir === 'asc'},
                      {'table-sort-desc': sortBy === 'nim' && sortDir === 'desc'},
                    )}
                    onClick={() => handleSort('nim')}
                  >
                    Nim
                  </th>
                  <th
                    className={clsx(
                      'text-center cursor-pointer w-100px',
                      {'table-sort-asc': sortBy === 'rank' && sortDir === 'asc'},
                      {'table-sort-desc': sortBy === 'rank' && sortDir === 'desc'},
                    )}
                    onClick={() => handleSort('rank')}
                  >
                    Rank
                  </th>
                  <th className="align-middle w-200px">Correct</th>
                  <th className="align-middle w-200px">Score</th>
                  {/*<th className='align-middle w-100px'>Correct 2</th>*/}
                  {/*<th className='align-middle w-100px'>Score 2</th>*/}
                  {/*<th className='align-middle w-100px'>Correct 3</th>*/}
                  {/*<th className='align-middle w-100px'>Score 3</th>*/}
                </tr>
                </thead>
                <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
                {row.original.details.map((item, j) => {
                  return (
                    <tr
                      key={j}
                      className={
                        j === row.original.details.length - 1
                          ? 'border-warning border-bottom-1'
                          : ''
                      }
                    >
                      <td className="text-center">{item.no}</td>
                      <td>{item.studentName}</td>
                      <td>{item.nim}</td>
                      <td className="text-center">{item.rank}</td>
                      <td className="text-center">
                        {item.correct ? `${item.correct}` : '-'}
                      </td>
                      <td className="text-center">{item.score > 0 || item.correct ? item.score : '-'}</td>
                      {/*<td className='text-center'>*/}
                      {/*  {item.correct2 ? `${item.correct2}` : '-'}*/}
                      {/*</td>*/}
                      {/*<td className='text-center'>{item.score2 > 0 || item.correct2 ? item.score2 : '-'}</td>*/}
                      {/*<td className='text-center'>*/}
                      {/*  {item.correct3 ? `${item.correct3}` : '-'}*/}
                      {/*</td>*/}
                      {/*<td className='text-center'>{item.score3 > 0 || item.correct3 ? item.score3 : '-'}</td>*/}
                    </tr>
                  )
                })}
                </tbody>
              </table>
            )
          })
        ) : (
          <table
            id='kt_table_attendance'
            className='table table-rounded dataTable align-middle table-row-dashed no-footer gs-7 fs-6 gy-5'
            {...getTableProps()}
          >
            <thead>
            <tr className="text-center text-muted fw-bolder fs-7 text-uppercase gs-0 bg-white">
              <th className="text-center w-100px">No</th>
              <th className="text-start">Name</th>
              <th className="text-start">Nim</th>
              <th className="text-center">Rank</th>
              <th className="align-middle">Correct</th>
              <th className="align-middle">Score</th>
              {/*<th className='align-middle'>Correct 2</th>*/}
              {/*<th className='align-middle'>Score 2</th>*/}
              {/*<th className='align-middle'>Correct 3</th>*/}
              {/*<th className='align-middle'>Score 3</th>*/}
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colSpan={99}>
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  {isLoading ? '' : ' No matching records found'}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        )}

        {/*<table*/}
        {/*  id='kt_table_attendance'*/}
        {/*  className='table table-rounded dataTable align-middle table-row-dashed no-footer gs-7 fs-6 gy-5'*/}
        {/*  {...getTableProps()}*/}
        {/*>*/}
        {/*  <thead>*/}
        {/*    <tr className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0 sticky-top bg-white'>*/}
        {/*      <th className='text-center'>Rank</th>*/}
        {/*      <th className='text-start'>Name</th>*/}
        {/*      <th className='align-middle'>Correct</th>*/}
        {/*      <th className='align-middle'>Score</th>*/}
        {/*      <th className='align-middle'>Correct 2</th>*/}
        {/*      <th className='align-middle'>Score 2</th>*/}
        {/*      <th className='align-middle'>Correct 3</th>*/}
        {/*      <th className='align-middle'>Score 3</th>*/}
        {/*    </tr>*/}
        {/*  </thead>*/}
        {/*  <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>*/}
        {/*    {rows.length > 0 ? (*/}
        {/*      rows.map((row: Row<any>, i) => {*/}
        {/*        return (*/}
        {/*          <Fragment key={i}>*/}
        {/*            <tr className='group-header'>*/}
        {/*              <td*/}
        {/*                colSpan={8}*/}
        {/*                className='text-center fw-bold fs-7 text-center text-uppercase border-warning text-black'*/}
        {/*              >*/}
        {/*                {row.original.groupClassName}*/}
        {/*              </td>*/}
        {/*            </tr>*/}
        {/*            {row.original.details.map((item, j) => {*/}
        {/*              return (*/}
        {/*                <tr key={j}>*/}
        {/*                  <td className='text-center'>{item.rank}</td>*/}
        {/*                  <td>{item.studentName}</td>*/}
        {/*                  <td className='text-center'>*/}
        {/*                    {item.correct && `${item.correct}/${item.countQuestion}`}*/}
        {/*                  </td>*/}
        {/*                  <td className='text-center'>{item.score || '-'}</td>*/}
        {/*                  <td className='text-center'>*/}
        {/*                    {item.correct2 && `${item.correct2}/${item.countQuestion}`}*/}
        {/*                  </td>*/}
        {/*                  <td className='text-center'>{item.score2 || '-'}</td>*/}
        {/*                  <td className='text-center'>*/}
        {/*                    {item.correct23 && `${item.correct3}/${item.countQuestion}`}*/}
        {/*                  </td>*/}
        {/*                  <td className='text-center'>{item.score3 || '-'}</td>*/}
        {/*                </tr>*/}
        {/*              )*/}
        {/*            })}*/}
        {/*          </Fragment>*/}
        {/*        )*/}
        {/*      })*/}
        {/*    ) : (*/}
        {/*      <tr>*/}
        {/*        <td colSpan={8}>*/}
        {/*          <div className='d-flex text-center w-100 align-content-center justify-content-center'>*/}
        {/*            {isLoading ? '' : ' No matching records found'}*/}
        {/*          </div>*/}
        {/*        </td>*/}
        {/*      </tr>*/}
        {/*    )}*/}
        {/*  </tbody>*/}
        {/*</table>*/}
      </div>
      {isLoading && <BlockLoading />}
    </KTCardBody>
  )
}

export {QuizResultByGroupTable}
