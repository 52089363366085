import {createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react'
import {ID, WithChildren} from '../../../../../_metronic/helpers'

interface AnnouncementContextProp {
  isShowed: boolean;
  setShowed: Dispatch<SetStateAction<boolean>>
}

const AnnouncementContext = createContext<AnnouncementContextProp>({isShowed: false, setShowed: () => { }})

const AnnouncementProvider: FC<WithChildren> = ({children}) => {
  const [isShowed, setShowed] = useState<boolean>(false)

  useEffect(() => {
    console.log('showed', isShowed)
  }, [isShowed])

  return (
    <AnnouncementContext.Provider
      value={{
        isShowed,
        setShowed
      }}>
      {children}
    </AnnouncementContext.Provider>
  )
}

const useAnnouncement = () => useContext(AnnouncementContext)

export {AnnouncementProvider, useAnnouncement}