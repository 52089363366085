import {useEffect} from 'react'
import {LecturerNotAttendSearch} from './LecturerNotAttendSearch'
import {LecturerNotAttendToolbar} from './LecturerNotAttendToolbar'
import {initialQueryState} from '../../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'

const LecturerNotAttendHeader = () => {
  const {refetch} = useQueryResponse()
  const {updateState} = useQueryRequest()

  useEffect(() => {
    updateState({
      ...initialQueryState,
    })
    setTimeout(() => {
      refetch()
    }, 500)
  }, [])

  return (
    <div className='card-header border-0 pt-6'>
      <div className='d-flex align-items-center'>
        <LecturerNotAttendSearch />
      </div>
      <div className='card-toolbar'>
        <LecturerNotAttendToolbar />
      </div>
    </div>
  )
}

export {LecturerNotAttendHeader}
