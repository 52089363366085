import axios from 'axios'
import {IBaseResponse} from '../../../../auth'
import {QuizResultByGroup} from './_model'

const API_URL = process.env.REACT_APP_BASE_API_URL
const QUIZ_RESULT_BY_GROUP = `${API_URL}/reports/result-quiz-by-group`

const getQuizResult = async (query: string): Promise<IBaseResponse<QuizResultByGroup[]>> => {
  const response = await axios.get(`${QUIZ_RESULT_BY_GROUP}?${query}`)
  return response.data
}

export {getQuizResult}
