import {useState} from 'react'
import {SurveyStudentInfoSeachComponent} from './SurveyStudentInfoSeachComponent'
import {SurveyStudentInfoToolbar} from './SurveyStudentInfoToolbar'

const SurveyStudentInfoHeader = () => {
  const [keyword, setKeyword] = useState<string>('')

  return (
    <div className='card-header border-0 pt-6 flex-md-row flex-column'>
      <SurveyStudentInfoSeachComponent keyword={keyword} setKeyword={setKeyword} />
      <div className='card-toolbar'>
        <SurveyStudentInfoToolbar keyword={keyword} setKeyword={setKeyword} />
      </div>
    </div>
  )
}

export {SurveyStudentInfoHeader}
