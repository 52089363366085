import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../_metronic/helpers'
import { Lecturer, LecturerQueryResponse } from './_models'
import {ex} from '@fullcalendar/core/internal-common'

const API_URL = process.env.REACT_APP_BASE_API_URL
const LECTURER_URL = `${API_URL}/lecturers`
const GET_LECTURER_URL = `${API_URL}/lecturers`
const UPLOAD_AVATAR_URL = `${API_URL}/lecturers/upload-avatar`
const GET_CATEGORY_SELECT = `${API_URL}/category/select`

const getLecturer = (query: string): Promise<LecturerQueryResponse> => {
  return axios
    .get(`${GET_LECTURER_URL}?${query}`)
    .then((d: AxiosResponse<LecturerQueryResponse>) => d.data)
}

const getLecturerSelect = (query: string): Promise<LecturerQueryResponse> => {
  return axios
    .get(`${GET_LECTURER_URL}/select?${query}`)
    .then((d: AxiosResponse<LecturerQueryResponse>) => d.data)
}

const getClassLecturerSelect = (query: string): Promise<LecturerQueryResponse> => {
  return axios
    .get(`${GET_LECTURER_URL}/class-lecturers/select?${query}`)
    .then((d: AxiosResponse<LecturerQueryResponse>) => d.data)
}

const getLecturerSelectPost = (query: string, excludeIds: any): Promise<LecturerQueryResponse> => {
  return axios
    .post(`${GET_LECTURER_URL}/select?${query}`, excludeIds)
    .then((d: AxiosResponse<LecturerQueryResponse>) => d.data)
}

const getLecturerById = (id: ID): Promise<Lecturer | undefined> => {
  return axios
    .get(`${LECTURER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Lecturer>>) => response.data)
    .then((response: Response<Lecturer>) => response.data)
}

const createLecturer = (lecturer: Lecturer): Promise<Lecturer | undefined> => {
  return axios
    .post(LECTURER_URL, lecturer)
    .then((response: AxiosResponse<Response<Lecturer>>) => response.data)
    .then((response: Response<Lecturer>) => response.data)
}


const updateLecturer = (lecturer: Lecturer): Promise<Lecturer | undefined> => {
  return axios
    .put(`${LECTURER_URL}/${lecturer.id}`, lecturer)
    .then((response: AxiosResponse<Response<Lecturer>>) => response.data)
    .then((response: Response<Lecturer>) => response.data)
}

const deleteLecturer = (lecturerId: ID): Promise<void> => {
  return axios.delete(`${LECTURER_URL}/${lecturerId}`).then(() => { })
}

const reactiveLecturer = (lecturerId: ID): Promise<void> => {
  return axios.put(`${LECTURER_URL}/reactive/${lecturerId}`).then(() => { })
}

const deleteSelectedLecturer = (lecturerIds: Array<ID>): Promise<void> => {
  const requests = lecturerIds.map((id) => axios.delete(`${LECTURER_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

export {
  getLecturer,
  deleteLecturer,
  deleteSelectedLecturer,
  getLecturerById,
  createLecturer,
  updateLecturer,
  getLecturerSelect,
  getLecturerSelectPost,
  reactiveLecturer,
  getClassLecturerSelect,
}
