import {useEffect, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useLocation} from 'react-router-dom'
import {KTCard, KTIcon, stringifyRequestQuery} from '../../../../../_metronic/helpers'
import {Confirm} from '../../../../components/Confirm'
import {downloadFile} from '../../../../utils/file'
import {MenuModel, getMenuAccess, useAuth} from '../../../auth'
import {CourseQuiz} from '../../course-list/core/_models'
import {deleteCourseQuiz, getCourseQuiz, reactiveCourseQuiz} from '../../course-list/core/_requests'
import {CourseFormQuizDetail} from './CourseFormQuizDetail'
import {CourseFormQuizModal} from './CourseFormQuizModal'

function CourseFormQuiz({id, initialQuizzes}) {
  const [showModal, setShowModal] = useState(false)
  const [quizzes, setQuizzes] = useState<CourseQuiz[]>(initialQuizzes)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const {pathname} = useLocation()
  const {currentMenu} = useAuth()
  const [menuAccess, setMenuAccess] = useState<MenuModel | undefined>(undefined)
  useEffect(() => {
    setMenuAccess(getMenuAccess(currentMenu ?? [], pathname))
  }, [currentMenu])

  useEffect(() => {
    if (initialQuizzes) setQuizzes(initialQuizzes)
  }, [initialQuizzes])

  const openModalHandler = () => {
    setSelectedIndex(null)
    setShowModal(true)
  }

  const editHandler = (id: number) => {
    setShowModal(true)
    const index = quizzes.findIndex((q) => q.id === id)
    setSelectedIndex(index)
  }

  const closeHandler = (isReset = false) => {
    setShowModal(false)
    if (isReset) {
      setSelectedIndex(null)
    }
    refetch()
  }

  const downloadHandler = async (file) => {
    await downloadFile('course-quizzes/download/' + file?.fileUniqueId, file.name)
  }

  const {refetch} = useQuery(
    'course-quiz',
    async () => {
      try {
        const response = await getCourseQuiz(
          stringifyRequestQuery({
            pageSize: 100,
            filter: {
              courseId: id,
            },
          })
        )
        setQuizzes(response.data?.records ?? [])
      } catch (ex) {
        console.log(ex)
      }
    },
    {enabled: false}
  )

  const selectedQuizHandler = (quiz: any, index) => {
    if (quiz.dataStatusId == 1) {
      setSelectedIndex(index)
    } else {
      setSelectedIndex(null)
    }
  }

  const [showDeleteQuiz, setShowDeleteQuiz] = useState(false)
  const [quizSelectedId, setQuizSelectedId] = useState<number | null>(null)

  const handleCloseDelete = () => setShowDeleteQuiz(false)

  const handleDeleteAction = async () => {
    try {
      await deleteMaterial.mutateAsync()
    } catch (err: any) {
      console.log(err)
    }
  }

  const deleteMaterial = useMutation(() => deleteCourseQuiz(quizSelectedId), {
    onSuccess: () => {
      handleCloseDelete()
      setQuizSelectedId(null)
      setSelectedIndex(null)
      // setQuizzes(prev => {
      //     return [...prev.filter(f => f.id !== quizSelectedId)]
      // })
      refetch()
    },
    onError: (err: any) => {},
  })

  const handleOpenConfirmation = (id) => {
    setQuizSelectedId(id)
    setShowDeleteQuiz(true)
  }

  const [showReactiveQuiz, setShowReactiveQuiz] = useState(false)
  const [reactiveSelectedId, setReactiveSelectedId] = useState<number | null>(null)
  const handleCloseReactive = () => setShowReactiveQuiz(false)
  const handleReactiveAction = async () => {
    try {
      await reactiveItem.mutateAsync()
    } catch (err: any) {
      console.log(err)
    }
  }

  const reactiveItem = useMutation(() => reactiveCourseQuiz(reactiveSelectedId), {
    onSuccess: () => {
      handleCloseReactive()
      setQuizSelectedId(null)
      setSelectedIndex(null)
      refetch()
    },
    onError: (err: any) => {},
  })
  const handleOpenReactive = (id) => {
    setReactiveSelectedId(id)
    setShowReactiveQuiz(true)
  }

  const handleRefetch = () => {
    refetch()
    setSelectedIndex(0)
  }

  return (
    <div className='row'>
      <div className='col-12 col-lg-3 mb-4'>
        <KTCard className='p-8'>
          <CourseFormQuizModal
            id={id}
            show={showModal}
            quiz={selectedIndex !== null ? quizzes[selectedIndex] : null}
            onClose={closeHandler}
          />
          {menuAccess?.canCreate && (
            <button
              type='button'
              onClick={openModalHandler}
              className='btn btn-warning fw-bold w-100 mb-8'
            >
              New Quiz
            </button>
          )}
          <div className='quiz-menu menu-scroll-xs-150px menu menu-column menu-rounded menu-state-bg menu-state-title-warning menu-state-icon-warning menu-state-bullet-warning mb-10'>
            {quizzes?.map((quiz, index) => (
              <div
                className={`menu-item mb-3 `}
                key={index}
                onClick={() => selectedQuizHandler(quiz, index)}
              >
                <span
                  className={`d-flex justify-content-between menu-link ${
                    index === selectedIndex && 'active'
                  }  ${quiz.dataStatusId === 4 && 'disabled'}`}
                >
                  <div className='d-flex align-items-center'>
                    <KTIcon iconName='note-2' iconType='duotone' className='fs-1 me-3' />
                    <div>
                      <div className='fs-5 menu-title fw-bold'>{quiz.name}</div>
                      <span className='text-gray-600'>{quiz.duration} minutes</span>
                    </div>
                  </div>
                  {menuAccess?.canDelete && quiz.dataStatusId == 1 && (
                    <span>
                      <span
                        onClick={(e) => {
                          e.stopPropagation()
                          handleOpenConfirmation(quiz.id)
                        }}
                      >
                        <i className='fas fa-times me-4'></i>
                      </span>
                    </span>
                  )}
                  {menuAccess?.canDelete && quiz.dataStatusId == 4 && (
                    <span>
                      <span
                        onClick={(e) => {
                          e.stopPropagation()
                          handleOpenReactive(quiz.id)
                        }}
                      >
                        <i className='fas fa-recycle me-4'></i>
                      </span>
                    </span>
                  )}
                </span>
              </div>
            ))}
          </div>
        </KTCard>
      </div>
      <div className='col-12 col-lg-9'>
        {selectedIndex !== null ? (
          <KTCard className='p-8'>
            <CourseFormQuizDetail
              quiz={quizzes[selectedIndex ?? 0]}
              onEditQuiz={editHandler}
              handleRefetch={handleRefetch}
            />
          </KTCard>
        ) : (
          <div
            className='d-flex text-center w-100 align-items-center justify-content-center text-gray-600 fw-bold'
            style={{minHeight: 300}}
          >
            No Quiz Selected
          </div>
        )}
      </div>
      <Confirm
        name={null}
        show={showDeleteQuiz}
        handleClose={handleCloseDelete}
        handleConfirm={handleDeleteAction}
      />
      <Confirm
        name={'Confirm Reactive'}
        show={showReactiveQuiz}
        handleClose={handleCloseReactive}
        handleConfirm={handleReactiveAction}
        body={'Are you sure you want to reactive this quiz?'}
      />
    </div>
  )
}

export default CourseFormQuiz
