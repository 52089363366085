import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {
  Course,
  CourseAssignment,
  CourseAssignmentQueryResponse,
  CourseDuplicate,
  CourseMaterial,
  CourseQueryResponse,
  CourseQuiz,
  CourseQuizQueryResponse,
  CourseQuizQuestion,
  CourseQuizQuestionQueryResponse,
  CourseSession,
  CourseSessionMaterial,
} from './_models'
import {Paging} from '../../../../models/Response'
import {toQueryString} from '../../../../../_metronic/helpers/UrlHelper'

const API_URL = process.env.REACT_APP_BASE_API_URL
const COURSE_URL = `${API_URL}/courses`
const GET_COURSE_URL = `${API_URL}/courses`

const COURSE_SESSION_URL = `${API_URL}/course-sessions`
const COURSE_ASSIGNMENT_URL = `${API_URL}/course-assignments`
const COURSE_QUIZ_URL = `${API_URL}/course-quizzes`
const COURSE_QUIZ_QUESTIONS_URL = `${API_URL}/course-quiz-questions`

const COURSE_SESSION_MATERIAL_URL = `${API_URL}/course-session-materials`

const COURSE_MATERIAL = `${API_URL}/course-materials`

const getCourse = (query: string): Promise<CourseQueryResponse> => {
  return axios
    .get(`${GET_COURSE_URL}?${query}`)
    .then((d: AxiosResponse<CourseQueryResponse>) => d.data)
}

const getCourseSelect = (query: string): Promise<Response<Paging<Course>>> => {
  return (
    axios
      .get(`${GET_COURSE_URL}/select?${query}`)
      // .then((d: AxiosResponse<CourseQueryResponse>) => d.data)
      .then((d: AxiosResponse<Response<Paging<Course>>>) => d.data)
  )
}

const getCourseById = (id: ID): Promise<Course | undefined> => {
  return axios
    .get(`${COURSE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Course>>) => response.data)
    .then((response: Response<Course>) => response.data)
}

const getCourseByIdHeader = (id: ID): Promise<Course | undefined> => {
  return axios
    .get(`${COURSE_URL}/header/${id}`)
    .then((response: AxiosResponse<Response<Course>>) => response.data)
    .then((response: Response<Course>) => response.data)
}

const createCourse = (course: Course): Promise<Course | undefined> => {
  return axios
    .post(COURSE_URL, course)
    .then((response: AxiosResponse<Response<Course>>) => response.data)
    .then((response: Response<Course>) => response.data)
}

const createCourseForm = (course: Course): Promise<Course | undefined> => {
  const formData = new FormData()
  if (course.syllabusFile) {
    formData.append('syllabusFile', course.syllabusFile ?? '')
  }
  formData.append('assignmentScore', course.assignmentScore + '')
  formData.append('attendanceScore', course.attendanceScore + '')
  formData.append('code', course.code)
  formData.append('description', course.description)
  formData.append('finalExamScore', course.finalExamScore + '')
  formData.append('quizScore', course.quizScore + '')
  formData.append('semesterId', course.semesterId + '')

  return axios
    .post(`${COURSE_URL}/form`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: AxiosResponse<Response<Course>>) => response.data)
    .then((response: Response<Course>) => response.data)
    .catch((error: any) => {
      console.error('Error creating course:', error)
      return undefined
    })
}

const updateCourse = (course: Course): Promise<Course | undefined> => {
  return axios
    .put(`${COURSE_URL}/${course.id}`, course)
    .then((response: AxiosResponse<Response<Course>>) => response.data)
    .then((response: Response<Course>) => response.data)
}

const updateCourseForm = (course: Course): Promise<Course | undefined> => {
  const formData = new FormData()
  if (course.syllabusFile) {
    formData.append('syllabusFile', course.syllabusFile ?? '')
  }
  formData.append('assignmentScore', course.assignmentScore + '')
  formData.append('attendanceScore', course.attendanceScore + '')
  formData.append('code', course.code)
  formData.append('description', course.description)
  formData.append('finalExamScore', course.finalExamScore + '')
  formData.append('quizScore', course.quizScore + '')
  formData.append('semesterId', course.semesterId + '')

  course.courseGradings?.forEach((item, index) => {
    Object.entries(item).forEach(([key, value]) => {
      formData.append(`courseGradings[${index}][${key}]`, value + '')
    })
  })

  return axios
    .put(`${COURSE_URL}/form/${course.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: AxiosResponse<Response<Course>>) => response.data)
    .then((response: Response<Course>) => response.data)
    .catch((error: any) => {
      console.error('Error updating course:', error)
      return undefined
    })
}

const deleteCourse = (courseId: ID): Promise<void> => {
  return axios.delete(`${COURSE_URL}/${courseId}`).then(() => {})
}

const reactiveCourse = (courseId: ID): Promise<void> => {
  return axios.put(`${COURSE_URL}/reactive/${courseId}`).then(() => {})
}

const deleteSelectedCourse = (courseIds: Array<ID>): Promise<void> => {
  const requests = courseIds.map((id) => axios.delete(`${COURSE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const createCourseSession = (courseSession: CourseSession): Promise<CourseSession | undefined> => {
  return axios
    .post(COURSE_SESSION_URL, courseSession)
    .then((response: AxiosResponse<Response<CourseSession>>) => response.data)
    .then((response: Response<CourseSession>) => response.data)
}

const updateCourseSession = (course: CourseSession): Promise<CourseSession | undefined> => {
  return axios
    .put(`${COURSE_SESSION_URL}/${course.id}`, course)
    .then((response: AxiosResponse<Response<CourseSession>>) => response.data)
    .then((response: Response<CourseSession>) => response.data)
}

const updateCourseMaterial = (
  courseId: ID,
  courseMaterial: CourseMaterial[]
): Promise<CourseMaterial | undefined> => {
  return axios
    .put(`${COURSE_MATERIAL}/course/${courseId}`, courseMaterial)
    .then((response: AxiosResponse<Response<CourseMaterial>>) => response.data)
    .then((response: Response<CourseMaterial>) => response.data)
}

const deleteCourseSession = (sessionId: ID): Promise<void> => {
  return axios.delete(`${COURSE_SESSION_URL}/${sessionId}`).then(() => {})
}

const uploadSessionMaterial = (
  file: File,
  material: CourseSessionMaterial
): Promise<CourseSessionMaterial | undefined> => {
  const formData = new FormData()
  formData.append('file', file) // Add your course session fields here
  formData.append('sessionId', material.sessionId + '')

  return axios
    .post(COURSE_SESSION_MATERIAL_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart
      },
    })
    .then((response: AxiosResponse<Response<CourseSessionMaterial>>) => response.data)
    .then((response: Response<CourseSessionMaterial>) => response.data)
    .catch((error: any) => {
      // Handle error
      console.error('Error creating course session:', error)
      return undefined
    })
}

const uploadCourseMaterial = (
  file: File,
  courseId: ID
): Promise<CourseSessionMaterial | undefined> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('courseId', courseId + '')

  return axios
    .post(COURSE_MATERIAL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
    .catch((error: any) => {
      // Handle error
      console.error('Error creating course material:', error)
      return undefined
    })
}

const deleteSessionMaterial = (id: ID): Promise<void> => {
  return axios.delete(`${COURSE_SESSION_MATERIAL_URL}/${id}`).then(() => {})
}

const deleteCourseMaterial = (id: ID): Promise<void> => {
  return axios.delete(`${COURSE_MATERIAL}/${id}`).then(() => {})
}

const createCourseAssignment = (
  assignment: CourseAssignment
): Promise<CourseAssignment | undefined> => {
  const formData = new FormData()
  formData.append('assignmentFile', assignment.assignmentFile ?? '') // Add your course session fields here
  formData.append('courseId', assignment.courseId + '')
  formData.append('name', assignment.name + '')
  formData.append('description', assignment.description + '')

  return axios
    .post(COURSE_ASSIGNMENT_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart
      },
    })
    .then((response: AxiosResponse<Response<CourseAssignment>>) => response.data)
    .then((response: Response<CourseAssignment>) => response.data)
    .catch((error: any) => {
      // Handle error
      console.error('Error creating course session:', error)
      return undefined
    })
}

const updateCourseAssignment = (
  assignment: CourseAssignment
): Promise<CourseAssignment | undefined> => {
  const formData = new FormData()
  if (assignment.assignmentFile) {
    formData.append('assignmentFile', assignment.assignmentFile ?? '')
  }
  formData.append('courseId', assignment.courseId + '')
  formData.append('name', assignment.name + '')
  formData.append('description', assignment.description + '')

  return axios
    .put(COURSE_ASSIGNMENT_URL + '/' + assignment.id, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart
      },
    })
    .then((response: AxiosResponse<Response<CourseAssignment>>) => response.data)
    .then((response: Response<CourseAssignment>) => response.data)
    .catch((error: any) => {
      // Handle error
      console.error('Error creating course session:', error)
      return undefined
    })
}

const getCourseAssignment = (query: string): Promise<CourseAssignmentQueryResponse> => {
  return axios
    .get(`${COURSE_ASSIGNMENT_URL}?${query}`)
    .then((d: AxiosResponse<CourseAssignmentQueryResponse>) => d.data)
}

const deleteCourseAssignment = (id: ID): Promise<void> => {
  return axios.delete(`${COURSE_ASSIGNMENT_URL}/${id}`).then(() => {})
}

const createCourseQuiz = (courseQuiz: CourseQuiz): Promise<CourseQuiz | undefined> => {
  return axios
    .post(COURSE_QUIZ_URL, courseQuiz)
    .then((response: AxiosResponse<Response<CourseQuiz>>) => response.data)
    .then((response: Response<CourseQuiz>) => response.data)
}

const updateCourseQuiz = (quiz: CourseQuiz): Promise<CourseQuiz | undefined> => {
  return axios
    .put(`${COURSE_QUIZ_URL}/${quiz.id}`, quiz)
    .then((response: AxiosResponse<Response<CourseSession>>) => response.data)
    .then((response: Response<CourseSession>) => response.data)
}

const getCourseQuiz = (query: string): Promise<CourseQuizQueryResponse> => {
  return axios
    .get(`${COURSE_QUIZ_URL}?${query}`)
    .then((d: AxiosResponse<CourseQuizQueryResponse>) => d.data)
}

const getCourseQuizSelect = (query: string): Promise<CourseQuizQueryResponse> => {
  return axios
    .get(`${COURSE_QUIZ_URL}/select?${query}`)
    .then((d: AxiosResponse<CourseQuizQueryResponse>) => d.data)
}

const deleteCourseQuiz = (id: ID): Promise<void> => {
  return axios.delete(`${COURSE_QUIZ_URL}/${id}`).then(() => {})
}

const createCourseQuizQuestion = (
  courseQuizQuestion: CourseQuizQuestion
): Promise<CourseQuizQuestion | undefined> => {
  return axios
    .post(COURSE_QUIZ_QUESTIONS_URL, courseQuizQuestion)
    .then((response: AxiosResponse<Response<CourseQuizQuestion>>) => response.data)
    .then((response: Response<CourseQuizQuestion>) => response.data)
}

const updateCourseQuizQuestion = (
  quiz: CourseQuizQuestion
): Promise<CourseQuizQuestion | undefined> => {
  return axios
    .put(`${COURSE_QUIZ_QUESTIONS_URL}/${quiz.id}`, quiz)
    .then((response: AxiosResponse<Response<CourseSession>>) => response.data)
    .then((response: Response<CourseSession>) => response.data)
}

const getCourseQuizQuestion = (query: string): Promise<CourseQuizQuestionQueryResponse> => {
  return axios
    .get(`${COURSE_QUIZ_QUESTIONS_URL}?${query}`)
    .then((d: AxiosResponse<CourseQuizQuestionQueryResponse>) => d.data)
}

const deleteCourseQuizQuestion = (id: ID): Promise<void> => {
  return axios.delete(`${COURSE_QUIZ_QUESTIONS_URL}/${id}`).then(() => {})
}

const duplicateCourse = (
  courseDuplicate: CourseDuplicate
): Promise<CourseDuplicate | undefined> => {
  return axios
    .post(`${GET_COURSE_URL}/clone`, courseDuplicate)
    .then((response: AxiosResponse<Response<CourseDuplicate>>) => response.data)
    .then((response: Response<CourseDuplicate>) => response.data)
}

const recalculateQuiz = (id: ID, data: number[]): Promise<Response<number>> => {
  return axios
    .put(`${COURSE_QUIZ_URL}/recalculate/${id}`, data)
    .then((response: AxiosResponse<Response<number>>) => response.data)
}

const reactiveCourseQuiz = (id: ID): Promise<Response<number>> => {
  return axios
    .put(`${COURSE_QUIZ_URL}/reactive/${id}`)
    .then((response: AxiosResponse<Response<number>>) => response.data)
}

const getMaterialCourseByCourseId = (id: ID): Promise<Response<CourseMaterial[]>> => {
  return axios
    .get(`${COURSE_MATERIAL}/course/${id}`)
    .then((response: AxiosResponse<Response<CourseMaterial[]>>) => response.data)
}

const duplicateQuiz = (id: ID, newName: string): Promise<Response<number>> => {
  const query = toQueryString({
    newName,
  })

  return axios
    .post(`${COURSE_QUIZ_URL}/duplications/${id}${query}`, {})
    .then((response: AxiosResponse<Response<number>>) => response.data)
}

export {
  getCourse,
  deleteCourse,
  deleteSelectedCourse,
  getCourseById,
  createCourse,
  updateCourse,
  createCourseSession,
  uploadSessionMaterial,
  updateCourseSession,
  deleteSessionMaterial,
  deleteCourseSession,
  createCourseAssignment,
  getCourseAssignment,
  updateCourseAssignment,
  deleteCourseAssignment,
  createCourseQuiz,
  updateCourseQuiz,
  getCourseQuiz,
  deleteCourseQuiz,
  createCourseQuizQuestion,
  updateCourseQuizQuestion,
  getCourseQuizQuestion,
  deleteCourseQuizQuestion,
  getCourseByIdHeader,
  getCourseSelect,
  createCourseForm,
  updateCourseForm,
  duplicateCourse,
  reactiveCourse,
  recalculateQuiz,
  getCourseQuizSelect,
  reactiveCourseQuiz,
  uploadCourseMaterial,
  getMaterialCourseByCourseId,
  updateCourseMaterial,
  deleteCourseMaterial,
  duplicateQuiz,
}
