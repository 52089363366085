import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import BlockLoading from '../../../components/BlockLoading'
import {showError} from '../../../utils/toast'
import {getStudentAttendanceDetail} from '../core/_request'
import {IAttendanceDetail} from '../../../models/IAttendanceChart'
import {formatDateTime} from '../../../utils/date'
import {stringifyRequestQuery} from '../../../../_metronic/helpers'

type Props = {
  show: boolean
  id: number | null
  studentName: string | null
  courseName: string
  params: string
  onClose(): void
}

const AttendanceDetailModal: FC<Props> = ({show, id, studentName, courseName, params, onClose}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<IAttendanceDetail[]>([])

  const handleClose = () => {
    onClose()
  }

  const getData = async (id) => {
    setIsLoading(true)
    try {
      console.log(params)

      const result = await getStudentAttendanceDetail(id, params)
      setIsLoading(false)
      if (result.code === '0000') {
        setData(result.data)
      } else {
        showError(result.message)
      }
    } catch (e: any) {
      showError(e)
    }
  }

  useEffect(() => {
    if (show && id) {
      getData(id)
    } else {
      setData([])
    }
  }, [show, id])

  return (
    <Modal show={show} onHide={handleClose} size='lg' className='modal-notif-survey'>
      <Modal.Header closeButton>
        <Modal.Title>
          <div>{studentName}</div>
          <div className='text-muted fs-6'>{courseName}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <BlockLoading />}
        <div className='table-responsive table-meet'>
          <table className='table table-bordered table-striped table-rounded '>
            <thead>
              <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0'>
                <th className='text-center w-75px'>No</th>
                <th className='min-w-100px'>Category</th>
                <th className='min-w-100px'>Type</th>
                <th className='min-w-100px'>Start Date</th>
                <th className='min-w-100px'>Join Time</th>
                <th className='min-w-100px'>Status</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className='text-center'>{index + 1}</td>
                    <td>{item.categoryName}</td>
                    <td>{item.typeName}</td>
                    <td>{formatDateTime(item.startDate)}</td>
                    <td>{formatDateTime(item.joinTime)}</td>
                    <td>{item.statusName}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AttendanceDetailModal
