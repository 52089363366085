import clsx from 'clsx'
import {FC, PropsWithChildren, useMemo} from 'react'
import {HeaderProps} from 'react-table'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {initialQueryState} from '../../../../../../../_metronic/helpers'
import {LecturerAttendanceType} from '../../core/_model'

type Props = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<LecturerAttendanceType>>
  enableSort: boolean
}
const LecturerAttendanceCustomHeader: FC<Props> = ({
  className,
  title,
  tableProps,
  enableSort = false,
}) => {
  const id = tableProps.column.id
  const {state, updateState} = useQueryRequest()

  const isSelectedForSorting = useMemo(() => {
    return state.sortName && state.sortName === id
  }, [state, id])
  const sortDir: 'asc' | 'desc' | undefined = useMemo(() => state.sortDir, [state])

  const sortNameColumn = () => {
    // avoid sortNameing for these columns
    if (id === 'actions' || id === 'selection') {
      return
    }

    if (!isSelectedForSorting) {
      // enable sortName asc
      updateState({sortName: id, sortDir: 'asc', ...initialQueryState})
      return
    }

    if (isSelectedForSorting && sortDir !== undefined) {
      if (sortDir === 'asc') {
        // enable sortName desc
        updateState({sortName: id, sortDir: 'desc', ...initialQueryState})
        return
      }

      // disable sortName
      updateState({sortName: undefined, sortDir: undefined, ...initialQueryState})
    }
  }

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        enableSort && 'cursor-pointer',
        isSelectedForSorting &&
          sortDir !== undefined &&
          `table-sort-${sortDir === 'asc' ? 'asc' : 'desc'}`
      )}
      onClick={enableSort ? sortNameColumn : undefined}
    >
      {title}
    </th>
  )
}

export {LecturerAttendanceCustomHeader}
