import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {SemesterQueryResponse} from './_model'
import {ISemester} from '../../../../models/ISemester'

const API_URL = process.env.REACT_APP_BASE_API_URL
const URL = `${API_URL}/semesters`
const CLASSES_URL = `${API_URL}/classes`

const getSemester = (query: string): Promise<SemesterQueryResponse> => {
  return axios.get(`${URL}?${query}`).then((d: AxiosResponse<SemesterQueryResponse>) => d.data)
}

const getSemesterSelect = (query: string): Promise<SemesterQueryResponse> => {
  return axios.get(`${URL}/lov?${query}`).then((d: AxiosResponse<SemesterQueryResponse>) => d.data)
}

const getSemesterById = (id: ID): Promise<ISemester | undefined> => {
  return axios
    .get(`${URL}/${id}`)
    .then((response: AxiosResponse<Response<ISemester>>) => response.data)
    .then((response: Response<ISemester>) => response.data)
}

const createSemester = (data: ISemester): Promise<ISemester | undefined> => {
  return axios
    .post(URL, data)
    .then((response: AxiosResponse<Response<ISemester>>) => response.data)
    .then((response: Response<ISemester>) => response.data)
}

const updateSemester = (data: ISemester): Promise<ISemester | undefined> => {
  return axios
    .put(`${URL}/${data.id}`, data)
    .then((response: AxiosResponse<Response<ISemester>>) => response.data)
    .then((response: Response<ISemester>) => response.data)
}

const deleteSemester = (id: ID): Promise<void> => {
  return axios.delete(`${URL}/${id}`).then(() => {})
}

const deleteSelectedSemester = (ids: Array<ID>): Promise<void> => {
  const requests = ids.map((id) => axios.delete(`${URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const updateGpaSemester = (id: ID): Promise<any | undefined> => {
  return axios
    .put(`${CLASSES_URL}/generate-gpa-semester/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

export {
  getSemester,
  getSemesterById,
  createSemester,
  updateSemester,
  deleteSelectedSemester,
  deleteSemester,
  getSemesterSelect,
  updateGpaSemester,
}
