import {FC, useCallback, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik, Field, useFormikContext} from 'formik'
import {KTCard, KTIcon, isNotEmpty, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import {Course, CourseSession, initialCourse} from '../../course-list/core/_models'
import {useQueryResponse} from '../../course-list/core/QueryResponseProvider'
import {
  createCourse,
  createCourseSession,
  deleteCourseSession,
  updateCourse,
} from '../../course-list/core/_requests'
import {CourseListLoading} from '../../course-list/components/loading/CourseListLoading'
import Dropzone, {useDropzone} from 'react-dropzone'
import {CourseFormSessionDetail} from './CourseFormSessionDetail'
import {useMutation} from 'react-query'
import {Confirm} from '../../../../components/Confirm'
import {useLocation} from 'react-router-dom'
import {useAuth, MenuModel, getMenuAccess} from '../../../auth'

type Props = {
  isLoading: boolean
  course: Course
}

let yupValidation = {
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  phone: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Phone is required'),
}

const addCourseSchema = Yup.object().shape({
  ...yupValidation,
  password: Yup.string().required('Password is required'),
})
const editCourseSchema = Yup.object().shape(yupValidation)

function CourseFormSession({id, initialSessions}) {
  const [course, setCourse] = useState(initialCourse)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const [sessions, setSessions] = useState<CourseSession[]>([])
  const {pathname} = useLocation()
  const {currentMenu} = useAuth()
  const [menuAccess, setMenuAccess] = useState<MenuModel | undefined>(undefined)
  useEffect(() => {
    setMenuAccess(getMenuAccess(currentMenu ?? [], pathname))
  }, [currentMenu])

  useEffect(() => {
    if (initialSessions) {
      setSessions(initialSessions)
      if (initialSessions.length > 0) {
        setSelectedIndex(0)
      }
    }
  }, [initialSessions])

  const [courseForEdit] = useState<Course>({
    ...course,
    id: course.id,
    description: course.description,
  })

  const addSessionHandler = async () => {
    const model: CourseSession = {
      courseId: id,
      sequence: sessions.length + 1,
      name: 'Session ' + (sessions.length + 1),
      description: '',
      materials: [],
    }
    setIsLoading(true)
    const res = await createCourseSession(model)
    if (res !== undefined) {
      setIsLoading(false)
      setSessions((prev) => {
        let newState = [...prev, res]
        return newState
      })
      setSelectedIndex(sessions.length)
    }
  }

  const selectedSessionHandler = (index) => {
    setSelectedIndex(index)

    // if (lastUpdated) {
    //   const {idx, value} = lastUpdated
    //   setSessions((prev) => {
    //     // const idx = prev.findIndex((q) => q.id === value.id)
    //     console.log(idx)
    //     let newItems = [...prev]
    //     newItems[idx] = value
    //     console.log(newItems)
    //     return newItems
    //   })
    //   setLastUpdated(null)
    // }

    // console.log(sessions)
  }

  const [showDeleteSession, setShowDeleteSession] = useState(false)
  const [sessionSelectedId, setSessionSelectedId] = useState<number | null>(null)

  const handleCloseDelete = () => setShowDeleteSession(false)

  const handleDeleteAction = async () => {
    try {
      await deleteMaterial.mutateAsync()
    } catch (err: any) {
      console.log(err)
    }
  }

  const deleteMaterial = useMutation(() => deleteCourseSession(sessionSelectedId), {
    onSuccess: () => {
      handleCloseDelete()
      setSessionSelectedId(null)
      setSelectedIndex(null)
      setSessions((prev) => {
        return [...prev.filter((f) => f.id !== sessionSelectedId)]
      })
    },
    onError: (err: any) => {},
  })

  const handleOpenConfirmation = (id) => {
    setSessionSelectedId(id)
    setShowDeleteSession(true)
  }

  const [lastUpdated, setLastUpdated] = useState<any | null>(null)

  const courseSessionChangeHandler = (idx, value) => {
    // setLastUpdated({
    //   idx,
    //   value,
    // })
    // console.log(value)
    // console.log(sessions)
    // setSessions((prev) => {
    //   // const idx = prev.findIndex((q) => q.id === value.id)
    //   console.log(idx)
    //   let newItems = [...prev]
    //   newItems[idx] = value
    //   console.log(newItems)
    //   return newItems
    // })
  }

  return (
    <div className='row'>
      <div className='col-12 col-lg-3 mb-4'>
        <KTCard>
          <div className='card-body'>
            {menuAccess?.canCreate && (
              <button
                type='button'
                disabled={isLoading}
                onClick={addSessionHandler}
                className='btn btn-warning fw-bold w-100 mb-8'
              >
                {isLoading && <i className='fas fa-spinner fa-spin p-0 me-2'></i>} New Session
              </button>
            )}
            <div className='session-menu  menu-scroll-xs-150px menu menu-column menu-rounded menu-state-bg menu-state-title-warning menu-state-icon-warning menu-state-bullet-warning mb-10'>
              {sessions?.map((session, index) => (
                <div
                  className='menu-item mb-3'
                  key={index}
                  onClick={() => selectedSessionHandler(index)}
                >
                  <span
                    className={`d-flex justify-content-between menu-link ${
                      index === selectedIndex && 'active'
                    }`}
                  >
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='bookmark' iconType='duotone' className='me-3' />
                      <span className='menu-title fw-bold'>Session {index + 1}</span>
                    </div>
                    <span>
                      {menuAccess?.canDelete && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation()
                            handleOpenConfirmation(session.id)
                          }}
                        >
                          <i className='fas fa-times me-4'></i>
                        </span>
                      )}
                    </span>
                  </span>
                </div>
              ))}
            </div>
          </div>
        </KTCard>
      </div>
      <div className='col-12 col-lg-9'>
        {sessions.map((m, index) => (
          <div className={clsx({'d-none': index !== selectedIndex})} key={index}>
            <KTCard className='p-10'>
              <h2 className='mb-10'>Session {index + 1}</h2>
              <CourseFormSessionDetail key={index} selectedIndex={index} courseSession={m} />
            </KTCard>
          </div>
        ))}
      </div>
      <Confirm
        name={null}
        show={showDeleteSession}
        handleClose={handleCloseDelete}
        handleConfirm={handleDeleteAction}
      />
    </div>
  )
}

export {CourseFormSession}
