import axios from 'axios'
import {IBaseResponse} from '../../../../auth'
import {StudentAttendanceSummary} from './_model'

const API_URL = process.env.REACT_APP_BASE_API_URL
const SUM_ATT_STUDENT = `${API_URL}/reports/student-attendance-summary`

const getStudentAttendanceSummary = async (query: string): Promise<IBaseResponse<StudentAttendanceSummary[]>> => {
  const response = await axios.get(`${SUM_ATT_STUDENT}?${query}`)
  return response.data
}

const getStudentAttendanceSummaryDetail = async (
  courseQuizId: number,
  query: string
): Promise<IBaseResponse<StudentAttendanceSummary[]>> => {
  const response = await axios.get(`${SUM_ATT_STUDENT}/${courseQuizId}?${query}`)
  return response.data
}

export {getStudentAttendanceSummary, getStudentAttendanceSummaryDetail}
