// @ts-nocheck
import {Column} from 'react-table'
import {SurveyStudentInfo} from '../../core/_model'
import {formatDateTime} from '../../../../../../utils/date'
import {SurveyCustomHeader} from '../../components/header/SurveyCustomHeader'

const surveyStudentInfoColumns: ReadonlyArray<Column<SurveyStudentInfo>> = [
  {
    accessor: 'studentName',
    id: 'studentName',
    className: 'text-center',
    Header: (props) => (
      <SurveyCustomHeader
        tableProps={props}
        title='Student Name'
        className='min-w-125px'
        enableSort={true}
      />
    ),
  },
  {
    accessor: 'subjectName',
    id: 'subjectName',
    className: 'text-center',
    Header: (props) => (
      <SurveyCustomHeader
        tableProps={props}
        title='Class'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    Cell: ({...props}) =>
      `${props.data[props.row.index].subjectName} (${props.data[props.row.index].classCode})`,
  },
  {
    accessor: 'semesterName',
    id: 'semesterName',
    className: 'text-center',
    Header: (props) => (
      <SurveyCustomHeader
        tableProps={props}
        title='Semester'
        className='min-w-125px'
        enableSort={false}
      />
    ),
  },
  {
    accessor: 'scheduleMeetingDate',
    id: 'scheduleMeetingDate',
    className: 'text-center',
    Cell: ({...props}) =>
      formatDateTime(
        props.data[props.row.index].scheduleMeetingDate
          ? new Date(props.data[props.row.index].scheduleMeetingDate)
          : null
      ),
    Header: (props) => (
      <SurveyCustomHeader
        tableProps={props}
        title='Meeting Date'
        className='min-w-125px'
        enableSort={false}
      />
    ),
  },
  {
    accessor: 'isPending',
    id: 'isPending',
    className: 'text-center',
    Cell: ({...props}) => (props.data[props.row.index].isPending ? 'Pending' : '-'),
    Header: (props) => (
      <SurveyCustomHeader
        tableProps={props}
        title='Pending?'
        className='min-w-125px'
        enableSort={true}
      />
    ),
  },
]

export {surveyStudentInfoColumns}
