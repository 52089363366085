import {FC, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {UpcomingClass} from '../core/_model'
import {
  formatMonthAndYear,
  getDate,
  formatTime,
  getDateFormatted,
  startOfToday,
  endOfToday,
} from '../../../utils/date'
import {useQuery} from 'react-query'
import {getTodayMeets, getUpcomingClass} from '../core/_request'
import Skeleton from 'react-loading-skeleton'
import clsx from 'clsx'
import {ImageViewer} from '../../../components/ImagePicker'
import {getLecturerImageThumbnailUrl, getLecturerImageUrl} from '../../../utils/img'
import moment from 'moment/moment'
import {chunkArray} from '../../../utils/array'
import {Link} from 'react-router-dom'

type Props = {
  isLecturer?: boolean
}

const TodayMeetsCard: FC<Props> = ({isLecturer = false}) => {
  const startDate = startOfToday().toISOString()
  const endDate = endOfToday().toISOString()
  const {data, isError, isLoading, error} = useQuery(
    ['get-today-meets'],
    () => getTodayMeets(`startDate=${startDate}&endDate=${endDate}`),
    {
      select: (d) => d.data,
    }
  )

  if (isError) {
    // @ts-ignore
    return <p>{error?.message}</p>
  }

  if (isLoading) {
    return (
      <>
        <div className='row'>
          <div className='col-12 col-lg-12 mb-4'>
            <Skeleton count={1} height={200} />
          </div>
          <div className='col-12 col-lg-12 mb-4'>
            <Skeleton count={1} height={200} />
          </div>
        </div>
      </>
    )
  }

  if (data?.length === 0) {
    return (
      <div>
        <TodayMeetsNotFound isLecturer={isLecturer} />
      </div>
    )
  }

  const groupedData = chunkArray(data ?? [], 3)

  return (
    <div className='today-meets-wrapper'>
      <div className='row'>
        {groupedData.map((data, i) => (
          <div className='col-md-12 col-lg-4' key={i}>
            {data?.map((v, i) => {
              return (
                <div className='card mb-7' key={i}>
                  <div className={clsx('card-body pe-4')}>
                    <Item data={v} index={i} isLast={i === data?.length - 1} />
                  </div>
                </div>
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}

const Header: FC = () => {
  return (
    <div className='d-flex justify-content-center flex-column me-3 mb-3'>
      <p className='fs-5 fw-bolder text-gray-700 text-hover-primary me-1 mb-2 mt-4 lh-1'>
        Today Class Meets
      </p>
    </div>
  )
}

const TodayMeetsNotFound: FC<Props> = ({isLecturer = false}) => {
  return (
    <>
      {/* <Header /> */}
      <div className='card text-center'>
        <div className='card-body d-flex flex-column justify-content-center align-items-center today-meets-wrapper'>
          <h5 className='card-title'>
            <KTIcon iconName='calendar' iconType='solid' className='fs-4x' />
          </h5>
          <p className='card-text text-muted'>No Class Meet Found</p>
        </div>
      </div>
    </>
  )
}

type ItemsProps = {
  data: UpcomingClass
  index: number
  isLast: boolean
}

const Item: FC<ItemsProps> = ({data, index, isLast}) => {
  const [show, setShow] = useState(false)

  return (
    <div>
      <div className={clsx('card-upcoming d-flex')}>
        <div className={clsx('flex-grow-1 px-5 py-5')}>
          <Link to={'/classes/' + data.classId}>
            <h4 className='text-hover-primary'>{data.name}</h4>
          </Link>
          {data.code && <span className='text-gray-600 fw-bold fs-6 mb-2'>{data.code}</span>}
          <div className='text-gray-500 fw-semibold d-flex fs-6 mb-4 align-items-center'>
            <div className='d-flex'>
              <span className='pe-5'>
                <KTIcon iconName='time' iconType='solid' className='me-1 fs-4' />{' '}
                {formatTime(data.startDate)} - {formatTime(data.endDate)}
              </span>
              <span className='text-muted fw-bold'>
                <i className='fas fa-user-friends pe-1'></i> {data.totalActiveStudent}
              </span>
            </div>
          </div>
          <div className='mb-5'>
            <div className='badge badge-primary fs-7 px-4 me-2 mt-2'>{data.classMeetTypeName}</div>
            {data.roomName && (
              <div className='badge badge-success fs-7 px-4 mt-2'>{data.roomName}</div>
            )}
          </div>

          {/* <div className={`${show ? 'show' : 'collapse'}`}> */}
          <div className={`show`}>
            {data.lecturers.map((l, i) => (
              <div className='d-flex align-items-center mb-2' key={i}>
                <div data-bs-toggle='tooltip' title={l.name}>
                  <div style={{width: 32, borderRadius: '50%', overflow: 'hidden'}}>
                    <ImageViewer
                      initialValue={getLecturerImageThumbnailUrl(l?.id)}
                      width={25}
                      height={25}
                      className='symbol symbol-25px symbol-circle me-2'
                    />
                  </div>
                </div>
                <div className='fs-7 text-gray-700' style={{fontWeight: 500}}>
                  {l && <span>{l.name}</span>}
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <div className='d-flex align-items-center'>
          <button onClick={() => setShow(!show)} type='button' className='btn btn-light btn-xs'>
            <i className={`fas fa-chevron-${show ? 'up' : 'down'} p-0`}></i>
          </button>
        </div> */}
      </div>
    </div>
  )
}

export default TodayMeetsCard
