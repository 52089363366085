import {FC, useContext, useEffect, useMemo, useState} from 'react'
import {
  PaginationState,
  QUERIES,
  WithChildren,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  stringifyRequestQuery,
} from '../../../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {useQueryRequest} from './QueryRequestProvider'
import {QuizResultByGroup} from './_model'
import {getQuizResult} from './_request'

const QueryResponseContext = createResponseContext<QuizResultByGroup>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.REPORT_STUDENT_EXAM}-${query}`,
    () => {
      return getQuizResult(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false, enabled: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading}
