import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import {
  QUERIES,
  WithChildren,
  createResponseContext,
  initialQueryResponse,
  stringifyRequestQuery
} from '../../../../../../_metronic/helpers'
import { showToastError } from '../../../../auth'
import { StudentAttendanceSummary } from './_model'
import { getStudentAttendanceSummary } from './_request'
import { useQueryRequest } from './QueryRequestProvider'

const QueryResponseContext = createResponseContext<StudentAttendanceSummary>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  useEffect(() => {
    const q = stringifyRequestQuery(state)
    if (q === query) {
      refetch()
    }
  }, [state])

  // const {
  //   isFetching,
  //   refetch,
  //   data: response,
  // } = useQuery(
  //   `${QUERIES.REPORT_RESULT_QUIZ}-${query}`,
  //   () => {
  //     return getStudentAttendanceSummary(query)
  //   },
  //   {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  // )

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.REPORT_RESULT_QUIZ}-${query}`,
    async () => {
      try {
        const response = await getStudentAttendanceSummary(query)
        return response
      } catch (ex: any) {
        showToastError(ex.message)
      }
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading:isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  console.log("useQueryResponseLoading", isLoading);
  
  return isLoading
}

export { QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading }

