import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {getScoresById} from '../../profile/core/_request'
import BlockLoading from '../../../components/BlockLoading'
import DetailScoreTable from '../../profile/components/DetailScoreTable'
import {showError} from '../../../utils/toast'
import {IClassStudentScore} from '../../../models/IClassStudent'

type Props = {
  show: boolean
  id: number | null
  onClose(): void
}

const AverageGpaDetailModal: FC<Props> = ({show, id, onClose}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<IClassStudentScore | null>()

  const handleClose = () => {
    onClose()
  }

  const getData = async (id) => {
    setIsLoading(true)
    try {
      const result = await getScoresById(id)
      setIsLoading(false)
      if (result.code === '0000') {
        setData(result.data)
      } else {
        showError(result.message)
      }
    } catch (e: any) {
      showError(e)
    }
  }

  useEffect(() => {
    if (show && id) {
      getData(id)
    } else {
      setData(null)
    }
  }, [show, id])

  return (
    <Modal show={show} onHide={handleClose} size='lg' className='modal-notif-survey'>
      <Modal.Header closeButton>
        <Modal.Title>
          <div>{data?.name} </div>
          <div className='text-muted fs-6'>{data?.nim}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <BlockLoading />}
        <div style={{height: 'calc( 100vh - 240px)', overflow: 'auto'}}>
          <div className='table-responsive'>
            <div style={{width: 1200}}>{data && <DetailScoreTable data={data} />}</div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AverageGpaDetailModal
