import {GraduatedStudentSearch} from './GraduatedStudentSearch'
import {GraduatedStudentToolbar} from './GraduatedStudentToolbar'

const GraduatedStudentHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <GraduatedStudentSearch />

      <div className='card-toolbar'>
        <GraduatedStudentToolbar />
      </div>
    </div>
  )
}

export {GraduatedStudentHeader}
