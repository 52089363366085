import {KTCard} from '../../../../../_metronic/helpers'
import {MeetAttendanceHeader} from './components/header/MeetAttendanceHeader'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {MeetAttendanceTable} from './table/MeetAttendanceTable'

const MeetAttendanceList = () => {
  return (
    <>
      <div className='d-flex flex-row justify-content-between mb-8'>
        <h1 className='page-heading text-dark fw-bold fs-3 my-0'>Attendance Percentage</h1>
      </div>
      <KTCard>
        <MeetAttendanceHeader />
        <MeetAttendanceTable />
      </KTCard>
    </>
  )
}

const MeetAttendanceListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <MeetAttendanceList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {MeetAttendanceListWrapper}
