// CustomSelect.js
import {FC, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import Select from 'react-select'
import clsx from 'clsx'
import {stringifyRequestQuery, useDebounce} from '../../../../_metronic/helpers'
import { getRoomSelect } from '../room-list/core/_requests'

interface RoomSelectProps {
  field?: any
  form?: any
  className?: string
  defaultOption?: any
  initialOptions?: any[]
  disabled?: boolean
  isUseFormik?: boolean
  onChange?(selected: any): void
  selectedValue?: number | null
}

const RoomSelect: FC<RoomSelectProps> = ({
  isUseFormik = true,
  field,
  form,
  className = '',
  defaultOption,
  initialOptions = [],
  disabled = false,
  onChange,
  selectedValue,
  ...rest
}) => {
  const [keyword, setKeyword] = useState<string | undefined>(undefined)
  const debouncedSearchTerm = useDebounce(keyword, 150)

  const {isLoading, data, refetch} = useQuery(
    'room-select',
    async () => {
      try {
        const response = await getRoomSelect(
          stringifyRequestQuery({
            keyword: debouncedSearchTerm,
          })
        )
        return response.data?.records
      } catch (ex) {
        console.log(ex)
      }
    },
    {enabled: false}
  )

  const handleChange = (selectedOption) => {
    if (isUseFormik) {
      form.setFieldValue(field.name, selectedOption.value)
    } else {
      if (onChange) onChange(selectedOption)
    }
  }

  const options =
    keyword === undefined
      ? initialOptions
      : data?.some((item) => item.id !== null)
      ? data?.map((option) => ({
          value: option.id,
          label: option.name,
        }))
      : []

  const handleInputChange = (value) => {
    setKeyword(value)
  }

  useEffect(() => {
    if (debouncedSearchTerm === undefined) return
    refetch()
  }, [debouncedSearchTerm])

  return isUseFormik ? (
    <Select
      {...rest}
      name={field.name}
      isLoading={isLoading}
      options={options}
      onChange={handleChange}
      value={options.find((option) => option.value === field.value)} // Set the initial selected value
      classNamePrefix='react-select'
      className={clsx(
        'react-select',
        className,
        {'is-invalid': !isUseFormik ? false : form.touched[field.name] && form.errors[field.name]},
        {'is-valid': !isUseFormik ? false : form.touched[field.name] && !form.errors[field.name]}
      )}
      onInputChange={handleInputChange}
      onMenuOpen={() => handleInputChange('')}
      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      isDisabled={disabled}
      placeholder="Type to search..."
    />
  ) : (
    <Select
      {...rest}
      isLoading={isLoading}
      options={options}
      onChange={handleChange}
      value={selectedValue ? options.find((option) => option.value === selectedValue) : null}
      classNamePrefix='react-select'
      className={clsx(
        'react-select',
        className,
        {'is-invalid': !isUseFormik ? false : form.touched[field.name] && form.errors[field.name]},
        {'is-valid': !isUseFormik ? false : form.touched[field.name] && !form.errors[field.name]}
      )}
      onInputChange={handleInputChange}
      onMenuOpen={() => handleInputChange('')}
      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      isDisabled={disabled}
      placeholder="Type to search..."
    />
  )
}

export default RoomSelect
