import {FC} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Classes} from '../core/_model'
import {useQuery} from 'react-query'
import {getClasses} from '../core/_request'
import Skeleton from 'react-loading-skeleton'
import {ImageViewer} from '../../../components/ImagePicker'
import {Link} from 'react-router-dom'
import {getLecturerImageUrl} from '../../../utils/img'
import {MyCourseModel} from '../../profile/core/_model'
import ClassCard from '../../classes/class-list/ClassCard'

const ClassesCard: FC = () => {
  const {data, isError, isLoading, error} = useQuery(['get-classes'], () => getClasses(), {
    select: (d) => d.data,
  })

  if (isLoading) {
    return (
      <>
        <div className='row'>
          <div className='col-12 col-lg-6 mb-4'>
            <Skeleton count={1} height={160} />
          </div>
          <div className='col-12 col-lg-6 mb-4'>
            <Skeleton count={1} height={160} />
          </div>
          <div className='col-12 col-lg-6 mb-4'>
            <Skeleton count={1} height={160} />
          </div>
          <div className='col-12 col-lg-6 mb-4'>
            <Skeleton count={1} height={160} />
          </div>
        </div>
      </>
    )
  }

  if (isError) {
    // @ts-ignore
    return <p>{error?.message}</p>
  }

  return (
    <>
      <div className='d-flex justify-content-center flex-column me-3 mb-3'>
        <p className='fs-5 fw-bolder text-gray-700 me-1 mb-2 mt-4 lh-1'>Classes</p>
      </div>
      <div className='row mb-3 g-3'>
        {data?.length === 0 && <ClassNoDataCard />}
        {data?.map((v, i) => {
          return (
            <div className='col-xl-6 col-lg-12 col-md-6' key={i}>
              <ClassCard data={v} key={i} />
            </div>
          )
        })}
      </div>
    </>
  )
}

const ClassNoDataCard: FC = () => {
  return (
    <div className='card text-center'>
      <div className='card-body'>
        <h5 className='card-title'>
          <KTIcon iconName='calendar' iconType='solid' className='fs-4x' />
        </h5>
        <p className='card-text text-muted'>No Classes Found</p>
      </div>
    </div>
  )
}

export default ClassesCard
