import axios from 'axios'
import {IBaseResponse} from '../../../../auth'
import {StudentAttendanceType} from './_model'

const API_URL = process.env.REACT_APP_BASE_API_URL
const STUDENT_SEMESTER = `${API_URL}/reports/attendance`

const getStudentAttendance = async (
  query: string
): Promise<IBaseResponse<StudentAttendanceType[]>> => {
  const response = await axios.get(`${STUDENT_SEMESTER}?${query}`)
  return response.data
}

const getStudentAttendanceDetail = async (
  classId: number,
  query: string
): Promise<IBaseResponse<StudentAttendanceType[]>> => {
  const response = await axios.get(`${STUDENT_SEMESTER}/${classId}?${query}`)
  return response.data
}

export {getStudentAttendance, getStudentAttendanceDetail}
