import {FieldArray, Form, Formik} from 'formik'
import {useEffect, useRef, useState} from 'react'
import {useQuery} from 'react-query'
import {useLocation} from 'react-router-dom'
import {KTCard, KTIcon} from '../../../../../_metronic/helpers'
import {MenuModel, getMenuAccess, useAuth} from '../../../auth'
import {ClassListLoading} from '../../class-list/components/loading/ClassListLoading'
import {Class, ClassLecturer, initialClass, initialClassLecturer} from '../../class-list/core/_models'
import {getClassById, updateClass} from '../../class-list/core/_requests'
import ClassFormLecturerSelect from './ClassFormLecturerSelect'
import LoadingButtonWrapper from '../../../../components/LoadingButtonWrapper'
import {getClassLecturers, updateClassLecturers} from '../core/_request'
import {generateGUID} from '../../../../utils/string'

function ClassFormLecturer({id, data}) {
  const [isLoading, setIsLoading] = useState(false)
  const [classForEdit, setClassForEdit] = useState<Class>({...initialClass, id: id})
  // const [classLecturer, setClassLecturer] = useState<ClassLecturer>(initialClassLecturer)
  // const [list, setList] = useState<ClassLecturer[]>([])
  const containerRef = useRef<any>(null)
  const {pathname} = useLocation()
  const {currentMenu} = useAuth()
  const [menuAccess, setMenuAccess] = useState<MenuModel | undefined>(undefined)
  useEffect(() => {
    setMenuAccess(getMenuAccess(currentMenu ?? [], pathname))
  }, [currentMenu])

  // useEffect(() => {
  //   if (data) {
  //     setClassForEdit(data)
  //     setClassLecturer({
  //       classId: id || initialClassLecturer.classId,
  //       lecturerId: initialClassLecturer.lecturerId,
  //       name: initialClassLecturer.name,
  //       state: initialClassLecturer.state,
  //     })
  //   }
  // }, [data])

  useEffect(() => {
    refetch()
  }, [])

  const {refetch} = useQuery(
    'class-lecturer',
    async () => {
      try {
        const response = await getClassLecturers(id)
        if (response.code === '0000') {
          setClassForEdit((prev) => {
            const newInstance = {...prev}
            for (const item of response.data || []) {
              item.guid = generateGUID()
            }
            newInstance.classLecturers = response.data
            return newInstance
          })
        } else {
          setClassForEdit((prev) => {
            const newInstance = {...prev}
            newInstance.classLecturers = []
            return newInstance
          })
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    {enabled: true}
  )

  const scrollToBottom = () => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight
      }
    }, 100)
  }

  return (
    <KTCard>
      <div className='px-10 py-8'>
        <Formik
          initialValues={classForEdit}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            // let models = {
            //   id: values.id,
            //   classLecturers: values.classLecturers?.filter((f) => f.lecturerId),
            // }
            const models = values.classLecturers?.filter((f) => f.lecturerId) || []

            actions.setSubmitting(true)
            try {
              await updateClassLecturers(id, models)
              refetch()
              actions.setSubmitting(true)
            } catch (ex: any) {
              console.error(ex)
              actions.setStatus(ex.message)
            } finally {
              actions.setSubmitting(false)
            }
          }}
        >
          {({values, isSubmitting, status, setFieldValue, getFieldProps}) => (
            <Form>
              {status ? (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{status}</div>
                </div>
              ) : (
                ''
              )}
              <FieldArray name='classLecturers'>
                {({push, remove, unshift, form}) => (
                  <>
                    <div className='mb-8'>
                      {menuAccess?.canCreate && (
                        <button
                          type='button'
                          className='btn btn-warning'
                          onClick={() => {
                            push({...initialClassLecturer, guid: generateGUID()})
                            scrollToBottom()
                          }}
                        >
                          Add Lecturer
                        </button>
                      )}
                    </div>
                    <div
                      className='table-responsive scroll-y'
                      style={{height: 'calc(100vh - 452px)'}}
                      ref={containerRef}
                    >
                      <table className='table table-striped table-row-gray-300 align-middle gs-0 gy-4 header-fix'>
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='text-center'>No</th>
                            <th className='min-w-150px'>Lecturer</th>
                            <th className='min-w-140px'>NIP</th>
                            <th className='min-w-120px'>Email</th>
                            <th className='min-w-100px text-end'>Actions</th>
                          </tr>
                        </thead>
                        {values?.classLecturers?.length != 0 ? (
                          <tbody>
                            {values?.classLecturers?.map(
                              (item, index) =>
                                item.state != 2 && (
                                  <tr key={index} className={item.isNew ? 'table-warning' : ''}>
                                    <td className='text-center'>{index + 1}</td>
                                    <td>
                                      <div>
                                        <ClassFormLecturerSelect
                                          id={`classLecturers.${index}.lecturerId`}
                                          name={`classLecturers.${index}.name`}
                                          nip={`classLecturers.${index}.nip`}
                                          email={`classLecturers.${index}.email`}
                                          courseId={data?.courseId}
                                          excludedIds={
                                            values.classLecturers
                                              ? values.classLecturers
                                                  ?.filter(
                                                    (q) =>
                                                      q.state !== 2 &&
                                                      q.lecturerId !=
                                                        values.classLecturers?.[index].lecturerId
                                                  )
                                                  .map((m) => m.lecturerId ?? 0)
                                              : []
                                          }
                                          initialOptions={
                                            item && item.lecturerId
                                              ? [{value: item.lecturerId, label: item.name}]
                                              : []
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td>{item.nip}</td>
                                    <td>{item.email}</td>
                                    <td width={150}>
                                      {item.allowDelete &&
                                        (values.classLecturers?.filter((q) => q.state !== 2)
                                          .length || 0) > 1 && (
                                          <div className='fv-row mb-7 text-end me-2'>
                                            <div className='d-flex justify-content-end flex-shrink-0'>
                                              <button
                                                type='button'
                                                onClick={() =>
                                                  item.id
                                                    ? setFieldValue(
                                                        `classLecturers.${index}.state`,
                                                        2
                                                      )
                                                    : remove(index)
                                                }
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                              >
                                                <KTIcon iconName='trash' className='fs-3'></KTIcon>
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                    </td>
                                  </tr>
                                )
                            )}
                          </tbody>
                        ) : (
                          <tbody className='text-gray-600 fw-bold'>
                            <tr>
                              <td colSpan={5}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                  {isLoading ? '' : ' No matching records found'}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </>
                )}
              </FieldArray>
              <div className='text-end pt-15'>
                {menuAccess?.canCreate && (
                <LoadingButtonWrapper isLoading={isSubmitting || isLoading}>
                  <button
                    type='submit'
                    className='btn btn-warning'
                    disabled={isSubmitting || isLoading || values?.classLecturers?.length == 0}
                  >
                    <span className='indicator-label'>Submit</span>
                  </button>
                </LoadingButtonWrapper>
                )}
              </div>
              {(isSubmitting || isLoading) && <ClassListLoading />}
            </Form>
          )}
        </Formik>
      </div>
    </KTCard>
  )
}

export default ClassFormLecturer
