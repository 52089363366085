// @ts-nocheck
import {Column} from 'react-table'
import {ReferenceCustomHeader} from './ReferenceCustomHeader'
import {ReferenceType} from '../../core/_model'
import {ReferenceActionsCell} from './ReferenceActionsCell'
import {ReferenceStatusCell} from './ReferenceStatusCell'

const referenceColumns: ReadonlyArray<Column<ReferenceType>> = [
  {
    Header: (props) => (
      <ReferenceCustomHeader
        tableProps={props}
        title='Title'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'title',
    id: 'title',
  },
  {
    Header: (props) => (
      <ReferenceCustomHeader
        tableProps={props}
        title=''
        className='min-w-100px text-center'
        enableSort={false}
      />
    ),
    id: 'status',
    Cell: ({...props}) => (
      <ReferenceStatusCell
        statusId={props.data[props.row.index].statusId}
        allowDownload={props.data[props.row.index].allowDownload}
        allowRequest={props.data[props.row.index].allowRequest}
      />
    ),
  },
  {
    Header: (props) => (
      <ReferenceCustomHeader
        tableProps={props}
        title='Author'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'author',
    id: 'author',
  },
  {
    Header: (props) => (
      <ReferenceCustomHeader
        tableProps={props}
        title='Tags'
        className='min-w-125px'
        enableSort={false}
      />
    ),
    accessor: 'tagsNameString',
    id: 'tagsNameString',
  },
  {
    Header: (props) => (
      <ReferenceCustomHeader
        tableProps={props}
        title='Publisher'
        className='min-w-125px'
        enableSort={false}
      />
    ),
    accessor: 'publisher',
    id: 'publisher',
  },
  {
    Header: (props) => (
      <ReferenceCustomHeader
        tableProps={props}
        title='Year'
        className='min-w-250px'
        enableSort={false}
      />
    ),
    accessor: 'publicationYear',
    id: 'publicationYear',
  },
  {
    Header: (props) => (
      <ReferenceCustomHeader tableProps={props} title='Actions' className='text-end min-w-150px' enableSort={false} />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <ReferenceActionsCell
        id={props.data[props.row.index].id}
        fileId={props.data[props.row.index].fileId}
        allowDownload={props.data[props.row.index].allowDownload}
        allowRequest={props.data[props.row.index].allowRequest}
        abstractUnique={props.data[props.row.index].abstractUniqueId}
        statusId={props.data[props.row.index].statusId}
        fileName={props.data[props.row.index].title}
        downloadLink={props.data[props.row.index].downloadLink}
        title={props.data[props.row.index].title}
      />
    ),
  },
]

export {referenceColumns}
