import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {KTCardBody, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {ReportLoading} from '../../../components/ReportLoading'
import {surveyResultColumns} from './columns/_columns'
import {CustomRow} from './columns/CustomRow'

const SurveyResultTable = () => {
  const d = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => d, [d])
  const columns = useMemo(() => surveyResultColumns, [])

  const {getTableProps, getTableBodyProps, rows, prepareRow} = useTable({
    columns,
    data: data ?? [],
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          className='table table-striped table-rounded dataTable align-middle table-row-dashed no-footer gs-7 fs-6 gy-5'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-center align-middle text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th rowSpan={2}>Question</th>
              <th rowSpan={2} style={{width: '100px'}}>
                Attend Student
              </th>
              <th rowSpan={2} style={{width: '100px'}}>
                Answer
              </th>
              <th colSpan={5}>Rate</th>
              <th rowSpan={2} style={{width: '100px'}}>
                Average
              </th>
            </tr>
            <tr className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th
                style={{width: '100px', paddingLeft: '0.75rem'}}
                className='sorting_disabled rating-header'
              >
                <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
              </th>
              <th style={{width: '100px'}} className='sorting_disabled rating-header'>
                <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
              </th>
              <th style={{width: '100px'}} className='sorting_disabled rating-header'>
                <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
              </th>
              <th style={{width: '100px'}} className='sorting_disabled rating-header'>
                <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
              </th>
              <th style={{width: '100px'}} className='sorting_disabled rating-header'>
                <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
              </th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={9}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {isLoading ? '' : 'No matching records found'}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {isLoading && <ReportLoading />}
    </KTCardBody>
  )
}

export {SurveyResultTable}
