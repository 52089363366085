import {FC, useState, createContext, useContext, ReactNode} from 'react'
import {
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
} from '../../../../../_metronic/helpers'

type Props = {
  children: ReactNode,
  isTable?: boolean
}

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC<Props> = ({children, isTable = false}) => {
  const [state, setState] = useState<any>({
    keyword: '',
    pageSize: isTable ? 10 : 12,
    majorId: null,
    courseId: null,
  })

  const updateState = (updates: any) => {
    const updatedState = {...state, ...updates}
    setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export {QueryRequestProvider, useQueryRequest}
