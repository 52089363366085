import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Lecturer} from '../../../lecturers/lecturer-list/core/_models'
import {IClass} from '../../../../models/IClass'
import {MyCourseModel} from '../../../profile/core/_model'
import {ImageViewer} from '../../../../components/ImagePicker'
import {getLecturerImageThumbnailUrl} from '../../../../utils/img'
import {IClassMeet} from '../../../../models/IClassMeet'
import OperatorScanModal from './OperatorScanModal'
import {ClassFormMeetModal} from '../../../courses/class-form/components/ClassFormMeetModal'
import {Dropdown} from 'react-bootstrap'
import {endOfToday, startOfToday} from '../../../../utils/date'
import moment from 'moment'
import {getClassOperatorById} from '../../../classes/core/_request'
import {showError} from '../../../../utils/toast'
import BlockLoading from '../../../../components/BlockLoading'
import {IClassLecturer} from '../../../../models/IClassLecturer'
import {getTodayClassMeets} from '../../core/_request'
import {stringifyRequestQuery} from '../../../../../_metronic/helpers'
import MeetTtypeEnum from '../../../../enums/MeetTypeEnum'

type DataProps = {
  id: number
  title: string
  code: string
  description: string
  color: string
  progress: number
  // primaryLecturer: Lecturer
  // secondaryLecturer: Lecturer
  percentage: number
  subjectName: string
  degreeName: string
  majorName: string
  courseName: string
  classLecturers: IClassLecturer[]
  // primaryLecturerId?: number
  // primaryLecturerName?: string
  // secondaryLecturerId?: number
  // secondaryLecturerName?: string
  totalStudent?: number
  totalActiveStudent?: number
  classMeets?: IClassMeet[]
}

type ClassCardProps = {
  data: DataProps | IClass | MyCourseModel
}

const OperatorClassCard: FC<ClassCardProps> = ({data: initial}) => {
  const [showModal, setShowModal] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [data, setData] = useState(initial)
  const [selectedId, setSelectedId] = useState<number | null>(null)
  const [selectedClassMeets, setSelectedClassMeets] = useState<IClassMeet[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const refetch = async () => {
    try {
      setIsLoading(true)
      const {code, message, data: result} = await getClassOperatorById(data.id)
      if (code === '0000') {
        setData((prev) => ({...prev, classMeets: result?.classMeets}))
      } else {
        showError(message)
      }
    } catch (e: any) {
      showError(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  const openModalHandler = async (id) => {
    const resp = await getTodayClassMeets(
      id,
      stringifyRequestQuery({
        filter: {
          typeId: MeetTtypeEnum.Onsite,
          startDate: startOfToday().toISOString(),
          endDate: endOfToday().toISOString(),
        },
      })
    )

    setSelectedId(id)
    setSelectedClassMeets(resp.data || [])
    setShowModal(true)
  }
  const closeModalHandler = async () => {
    setSelectedId(null)
    setSelectedClassMeets([])
    setShowModal(false)
    refetch()
  }
  const openFormHandler = () => {
    setShowForm(true)
  }
  const closeFormHandler = (newData) => {
    if (newData) {
      refetch()
    }
    setShowForm(false)
  }

  useEffect(() => {
    setData(initial)
  }, [initial])

  return (
    <>
      <div
        className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end mb-5 h-100 card-hoverable'
        style={{
          backgroundColor: 'white',
        }}
        onClick={() => openModalHandler(data.id)}
      >
        {isLoading && <BlockLoading />}
        <div className='card-header pt-5'>
          <div className='d-flex flex-stack'>
            <div className='d-flex'>
              <div className='symbol symbol-60px me-5'>
                <span
                  className='symbol-label d-flex flex-column'
                  style={{backgroundColor: '#fcdfe2'}}
                >
                  <i className='fas fa-user-friends fs-2' style={{color: '#e8001a'}}></i>
                  <span style={{lineHeight: 1, color: '#e8001a', fontWeight: 600, marginTop: 2}}>
                    {data.totalActiveStudent}
                  </span>
                </span>
              </div>
              <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
                <span className='card-class-name text-gray-900 fw-bold text-hover-primary fs-4'>
                  {data.courseName}
                </span>
                <span className='text-gray-600 fw-bold text-hover-primary fs-6 mb-1'>
                  {data.code}
                </span>

                <div className='d-flex'>
                  <span className='text-muted fw-bold pe-5'>
                    <i className='fas fa-bookmark pe-1'></i> {data.degreeName} - {data.majorName}
                  </span>
                  {/* <span className='text-muted fw-bold'>
                    <i className='fas fa-user-friends pe-1'></i> {data.totalActiveStudent}
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body pt-0 pb-0'>
          <div className='d-flex align-items-center mt-4 w-100 mb-4'>
            <div
              className='h-8px me-3 w-100 bg-opacity-70 rounded'
              style={{backgroundColor: '#dedede'}}
            >
              <div
                className='rounded h-8px'
                role='progressbar'
                style={{width: `${data.progress}%`, backgroundColor: '#3e97ff'}}
                aria-valuenow={data.progress}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
            <div className='fw-bold fs-7 opacity-75'>
              <span>{data.progress}%</span>
            </div>
          </div>

          {data.classLecturers.map((l, i) => (
            <div className='fs-6 d-flex align-items-center mb-2' key={i}>
              <div className='symbol-group symbol-hover flex-nowrap'>
                <div data-bs-toggle='tooltip' title={l.name}>
                  <ImageViewer
                    initialValue={getLecturerImageThumbnailUrl(l.lecturerId)}
                    width={25}
                    height={25}
                    className='symbol symbol-25px symbol-circle me-2'
                  />
                </div>
              </div>
              <div className='fw-bold fs-7'>
                <span>{l.name}</span>
              </div>
            </div>
          ))}

          {/*{data.primaryLecturerId && (*/}
          {/*  <div className='fs-6 d-flex align-items-center mb-2'>*/}
          {/*    <div className='symbol-group symbol-hover flex-nowrap'>*/}
          {/*      <div data-bs-toggle='tooltip' title={data.primaryLecturerName}>*/}
          {/*        <ImageViewer*/}
          {/*          initialValue={getLecturerImageThumbnailUrl(data.primaryLecturerId)}*/}
          {/*          width={25}*/}
          {/*          height={25}*/}
          {/*          className='symbol symbol-25px symbol-circle me-2'*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className='fw-bold fs-7'>*/}
          {/*      <span>{data.primaryLecturerName}</span>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}

          {/*{data.secondaryLecturerId && (*/}
          {/*  <div className='fs-6 d-flex align-items-center mb-2'>*/}
          {/*    <div className='symbol-group symbol-hover flex-nowrap'>*/}
          {/*      <div data-bs-toggle='tooltip' title={data.secondaryLecturerName}>*/}
          {/*        <ImageViewer*/}
          {/*          initialValue={getLecturerImageThumbnailUrl(data.secondaryLecturerId)}*/}
          {/*          width={25}*/}
          {/*          height={25}*/}
          {/*          className='symbol symbol-25px symbol-circle me-2'*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className='fw-bold fs-7'>*/}
          {/*      <span>{data.secondaryLecturerName}</span>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </div>

      {showModal && (
        <OperatorScanModal
          classId={selectedId}
          classMeets={selectedClassMeets}
          show={showModal}
          classInfo={data}
          onClose={closeModalHandler}
        />
      )}
      {showForm && (
        <ClassFormMeetModal
          onlyOnsite={true}
          id={data.id}
          show={showForm}
          data={null}
          onClose={closeFormHandler}
        />
      )}
    </>
  )
}

export default OperatorClassCard
