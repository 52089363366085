import clsx from 'clsx'
import {FieldArray, FormikProvider, useFormik} from 'formik'
import {FC, useEffect, useRef, useState} from 'react'
import {QueryObserverResult, RefetchOptions, RefetchQueryFilters} from 'react-query'
import {useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import {PreviewPdf} from '../../../../components/PreviewPdf'
import RichEditor from '../../../../components/RichEditor'
import {downloadFile} from '../../../../utils/file'
import {showError, showWarning} from '../../../../utils/toast'
import {MenuModel, getMenuAccess, useAuth} from '../../../auth'
import {CourseListLoading} from '../../course-list/components/loading/CourseListLoading'
import {Course, initialCourse} from '../../course-list/core/_models'
import {createCourseForm, updateCourseForm} from '../../course-list/core/_requests'
import LoadingButtonWrapper from '../../../../components/LoadingButtonWrapper'

const OneMB = 1048576

let yupValidation = {
  description: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .when('syllabusFile', {
      is: true,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required('Description is required'),
    }),
  attendanceScore: Yup.number().required('Attendance Score is required'),
  quizScore: Yup.number().required('Quiz Score is required'),
  assignmentScore: Yup.number().required('Assignment Score is required'),
  finalExamScore: Yup.number().required('Final Exam Score is required'),
}

const addCourseSchema = (maxFileSize: number) =>
  Yup.object().shape({
    ...yupValidation,
    syllabusFile: Yup.mixed()
      .notRequired()
      .test('fileSize', `The file is too large (max: ${(maxFileSize / OneMB).toFixed(2)} MB)`, (value) => {
        const file = value as File | null
        if (!file) return true
        return file && file.size <= maxFileSize
      }),
    abstractFile: Yup.mixed()
      .nullable()
      .test('fileSize', `The file is too large (max: ${(maxFileSize / OneMB).toFixed(2)} MB)`, (value) => {
        const file = value as File | null
        if (!file) return true
        return file && file.size <= maxFileSize
      }),
  })

const editCourseSchema = (maxFileSize: number) =>
  Yup.object().shape({
    ...yupValidation,
    syllabusFile: Yup.mixed()
      .notRequired()
      .test('fileSize', `The file is too large (max: ${(maxFileSize / OneMB).toFixed(2)} MB)`, (value) => {
        const file = value as File | null
        if (!file) return true
        return file && file.size <= maxFileSize
      }),
    abstractFile: Yup.mixed()
      .nullable()
      .test('fileSize', `The file is too large (max: ${(maxFileSize / OneMB).toFixed(2)} MB)`, (value) => {
        const file = value as File | null
        if (!file) return true
        return file && file.size <= maxFileSize
      }),
  })

type CourseFormGeneralProps = {
  course: Course | undefined
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<Course | undefined, unknown>>
}

const CourseFormGeneral: FC<CourseFormGeneralProps> = ({course, refetch}) => {
  const [isLoading] = useState(false)
  const [isFile, setFile] = useState<boolean>(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [isDownload, setDownload] = useState<boolean>(false)

  const [courseForEdit, setCourseForEdit] = useState<Course>({
    ...initialCourse,
  })

  const [showPreviewPdf, setShowPreviewPdf] = useState(false)
  const handleClosePreviewPdf = () => setShowPreviewPdf(false)
  const {pathname} = useLocation()
  const {currentUser, currentMenu} = useAuth()
  const [menuAccess, setMenuAccess] = useState<MenuModel | undefined>(undefined)
  useEffect(() => {
    setMenuAccess(getMenuAccess(currentMenu ?? [], pathname))
  }, [currentMenu])

  const formik = useFormik({
    initialValues: courseForEdit,
    enableReinitialize: true,
    validationSchema: isNotEmpty(course?.id)
      ? editCourseSchema(currentUser?.limitFileSize ?? OneMB)
      : addCourseSchema(currentUser?.limitFileSize ?? OneMB),
    onSubmit: async (values, {setSubmitting, setStatus}) => {
      setStatus(null)
      const models = {...values}

      const totalScore =
        models.courseGradings
          ?.map((m) => +(m.weight ?? '0'))
          .reduce((a, b) => (a ?? 0) + (b ?? 0)) ?? 0

      if (totalScore < 100) {
        showWarning('Total Score cannot be less than 100')
        return
      } else if (totalScore > 100) {
        showWarning('Total Score cannot be greater than 100')
        return
      }

      try {
        if (isNotEmpty(course?.id)) {
          await updateCourseForm(models)
        } else {
          await createCourseForm(models)
        }
        setSubmitting(true)
      } catch (ex: any) {
        console.error(ex)
        setStatus(ex.message)
      } finally {
        refetch()
      }
    },
  })

  useEffect(() => {
    if (course) {
      setCourseForEdit({
        id: course.id || initialCourse.id,
        semesterId: course.semesterId || initialCourse.semesterId,
        description: course.description || initialCourse.description,
        code: course.code || initialCourse.code,
        attendanceScore: course.attendanceScore ?? initialCourse.attendanceScore,
        quizScore: course.quizScore ?? initialCourse.quizScore,
        assignmentScore: course.assignmentScore ?? initialCourse.assignmentScore,
        finalExamScore: course.finalExamScore ?? initialCourse.finalExamScore,
        courseGradings: course.courseGradings ?? initialCourse.courseGradings,
      })
    }
  }, [course])

  // useEffect(() => {
  //   console.log(formik.values)
  // }, [formik.values])

  const inputFileRef = useRef<HTMLInputElement>(null)

  const modules = {
    toolbar: [
      // [{ header: "1" }, { header: "2" }],
      [{font: []}],
      [{size: ['small', false, 'large', 'huge']}], // custom dropdown
      ['bold', 'italic', 'underline', 'strike'],
      [{color: []}, {background: []}], // dropdown with defaults from theme
      [{align: []}],
      [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
      ['link', 'image'],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      handleStyles: {
        displaySize: true,
        backgroundColor: 'black',
        border: 'none',
        color: 'white',
      },
      modules: ['Resize'],
    },
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ]

  const handleView = async (unique: string) => {
    // try {
    //   setButtonLoading(true)
    //   await viewFile(`courses/download/${unique}`)
    // } catch (err: any) {
    //   showError(err?.message)
    // } finally {
    //   setButtonLoading(false)
    // }
    setShowPreviewPdf(true)
  }

  const handleDownload = async (unique: string, filename: string) => {
    try {
      setDownload(true)
      await downloadFile(`courses/download/${unique}`, filename)
    } catch (err: any) {
      showError(err?.message)
    } finally {
      setDownload(false)
    }
  }

  return (
    <div className='px-10 py-8'>
      <form
        id='kt_modal_add_course_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <fieldset disabled={!menuAccess?.canCreate}>
          {/* begin::Scroll */}
          <div className='d-flex flex-column me-n7 pe-7'>
            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              ''
            )}

            <div className='row'>
              <div className='col-12 col-md-12 col-lg-8 col-sm-12'>
                <label className='fw-bold fs-6 mb-2'>
                  {course?.id && course.rpsFileId !== null ? 'Change File' : 'Select File'}
                </label>
                <input
                  name='syllabusFile'
                  ref={inputFileRef}
                  accept='.doc, .docx, .pdf'
                  type='file'
                  className={clsx(
                    'form-control mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.syllabusFile && formik.errors.syllabusFile},
                    {
                      'is-valid': formik.touched.syllabusFile && !formik.errors.syllabusFile,
                    }
                  )}
                  onBlur={formik.handleBlur}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const schema = isNotEmpty(course?.id)
                      ? editCourseSchema(currentUser?.limitFileSize ?? OneMB)
                      : addCourseSchema(currentUser?.limitFileSize ?? OneMB)
                    if (event.currentTarget.files) {
                      schema.describe({value: {isUsingFile: true}})
                      formik.setFieldValue('syllabusFile', event.currentTarget.files[0])
                      setFile(true)
                    } else {
                      schema.describe({value: {isUsingFile: false}})
                      setFile(false)
                    }
                  }}
                />
                {formik.touched.syllabusFile && formik.errors.syllabusFile && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.syllabusFile}</span>
                    </div>
                  </div>
                )}
                {course?.rpsFileId && (
                  <div className='row mt-4'>
                    <div className='col-6 col-lg-6 col-xl-3'>
                      <span
                        role='button'
                        className='btn btn-shadow btn-light-primary btn-sm w-100'
                        onClick={async () => {
                          if (!buttonLoading) {
                            await handleView(course.uniqueId ?? '')
                          }
                        }}
                      >
                        {buttonLoading ? (
                          <span>Loading...</span>
                        ) : (
                          <>
                            <i className='fas fa-eye fs-5' /> View
                          </>
                        )}
                      </span>
                    </div>
                    <div className='col-6 col-lg-6 col-xl-3'>
                      <span
                        className='btn btn-shadow btn-light-primary btn-sm w-100'
                        onClick={async () => {
                          if (!isDownload) {
                            await handleDownload(course.uniqueId ?? '', course.fileName ?? '')
                          }
                        }}
                      >
                        {isDownload ? (
                          <span>Loading...</span>
                        ) : (
                          <>
                            <i className='fas fa-download fs-5' /> Download
                          </>
                        )}
                      </span>
                    </div>
                    <div
                      className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mt-4 mt-md-2 mt-lg-2 mt-xl-0 mt-sm-2 text-truncate pe-help'
                      style={{cursor: 'help'}}
                      data-toggle='tooltip'
                      data-placement='top'
                      title={course.fileName}
                    >
                      <span className='ms-4'>{course.fileName}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-12 mt-12'>
                <div className='fv-row'>
                  <label className={`${!isFile && 'required'} fw-bold fs-6 mb-2`}>
                    Description
                  </label>
                  <RichEditor
                    onChange={(val) => formik.setFieldValue('description', val)}
                    value={formik.values.description}
                    disabled={!menuAccess?.canCreate}
                  />
                  {/* end::Input */}
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-12 col-lg-8 mt-16'>
                <h5 className='mb-5'>Score Weight (%)</h5>

                <div className='row'>
                  <FormikProvider value={formik}>
                    <FieldArray name='courseGradings'>
                      {({push, remove, form}) => (
                        <>
                          {formik.values.courseGradings?.map((m, index) => (
                            <div className='col-6 col-lg-3' key={index}>
                              <label className='fw-bold fs-6 mb-2'>{m.typeName}</label>
                              <input
                                type='text'
                                className='form-control'
                                name={`courseGradings.${index}.weight`}
                                onChange={form.handleChange}
                                value={formik.values.courseGradings![index].weight}
                              />
                            </div>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </FormikProvider>
                </div>

              {/* <div className='table-responsive'>
                <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                  <thead>
                    <tr>
                      <th
                        style={{width: '25%', minWidth: 90}}
                        className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
                      >
                        Attendance
                      </th>
                      <th
                        style={{width: '25%', minWidth: 90}}
                        className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
                      >
                        Quiz
                      </th>
                      <th
                        style={{width: '25%', minWidth: 90}}
                        className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
                      >
                        Assignment
                      </th>
                      <th
                        style={{width: '25%', minWidth: 90}}
                        className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
                      >
                        Final Exam
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          placeholder='Attendance'
                          {...formik.getFieldProps('attendanceScore')}
                          type='number'
                          min={0}
                          max={100}
                          name='attendanceScore'
                          className={clsx(
                            'form-control mb-3 mb-lg-0',
                            {
                              'is-invalid':
                                formik.touched.attendanceScore && formik.errors.attendanceScore,
                            },
                            {
                              'is-valid':
                                formik.touched.attendanceScore && !formik.errors.attendanceScore,
                            }
                          )}
                          autoComplete='off'
                          disabled={formik.isSubmitting || isLoading}
                        />
                      </td>
                      <td>
                        <input
                          placeholder='Quiz'
                          {...formik.getFieldProps('quizScore')}
                          type='text'
                          name='quizScore'
                          min={0}
                          max={100}
                          className={clsx(
                            'form-control mb-3 mb-lg-0',
                            {'is-invalid': formik.touched.quizScore && formik.errors.quizScore},
                            {
                              'is-valid': formik.touched.quizScore && !formik.errors.quizScore,
                            }
                          )}
                          autoComplete='off'
                          disabled={formik.isSubmitting || isLoading}
                        />
                      </td>
                      <td>
                        <input
                          placeholder='Assignment'
                          {...formik.getFieldProps('assignmentScore')}
                          type='text'
                          name='assignmentScore'
                          min={0}
                          max={100}
                          className={clsx(
                            'form-control mb-3 mb-lg-0',
                            {
                              'is-invalid':
                                formik.touched.assignmentScore && formik.errors.assignmentScore,
                            },
                            {
                              'is-valid':
                                formik.touched.assignmentScore && !formik.errors.assignmentScore,
                            }
                          )}
                          autoComplete='off'
                          disabled={formik.isSubmitting || isLoading}
                        />
                      </td>
                      <td>
                        <input
                          placeholder='Final Exam'
                          {...formik.getFieldProps('finalExamScore')}
                          type='text'
                          name='finalExamScore'
                          min={0}
                          max={100}
                          className={clsx(
                            'form-control mb-3 mb-lg-0',
                            {
                              'is-invalid':
                                formik.touched.finalExamScore && formik.errors.finalExamScore,
                            },
                            {
                              'is-valid':
                                formik.touched.finalExamScore && !formik.errors.finalExamScore,
                            }
                          )}
                          autoComplete='off'
                          disabled={formik.isSubmitting || isLoading}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
              </div>
            </div>
          </div>

          {menuAccess?.canCreate && (
        <div className='text-end pt-15'>
          <LoadingButtonWrapper isLoading={formik.isSubmitting || isLoading}>
            <button
              type='submit'
              className='btn btn-warning'
              data-kt-courses-modal-action='submit'
              disabled={formik.isSubmitting || isLoading}
            >
              <span className='indicator-label'>Submit</span>
            </button>
          </LoadingButtonWrapper>
        </div>
          )}
          {/* end::Actions */}
        </fieldset>
      </form>
      {(formik.isSubmitting || isLoading) && <CourseListLoading />}

      <PreviewPdf
        name={course?.fileName ?? ''}
        url={`courses/download/${course?.uniqueId ?? ''}`}
        show={showPreviewPdf}
        handleClose={handleClosePreviewPdf}
      />

    </div>
  )
}

export {CourseFormGeneral}
