import {format, parseISO, formatISO} from 'date-fns'
import moment from 'moment'

const dateNow = () => {
  return new Date().toISOString()
}

const formatDate = (date: Date) => {
  if (!date) return ''
  return moment(date).format('DD/MM/YYYY')
  // return format(date, 'dd/MM/yyyy')
}

const formatDateTime = (date: any) => {
  if (!date) return ''
  return moment(date).format('DD/MM/YYYY HH:mm')
  // return format(parseISO(date), 'dd/MM/yyyy HH:mm')
}

const toISODateString = (date: any) => {
  return formatISO(date)
}

const formatTime = (date: any) => {
  if (!date) return ''
  return moment(date).format('HH:mm')
  // return format(parseISO(date), 'HH:mm')
}

const getDate = (date?: Date): string => {
  if (!date) return ''
  return moment(date).date().toString()
}

const getDateFormatted = (date?: Date | string, formatStr?: string): string => {
  if (!date) return ''
  return moment(date).format(formatStr)
}

const formatMonthAndYear = (date?: Date): string => {
  if (!date) return ''
  const year = moment(date).format('YY')
  const month = moment(date).format('MMM')

  return `${month} '${year}`
}

const startOfToday = (): Date => {
  return moment().startOf('days').utc().toDate()
}

const endOfToday = (): Date => {
  return moment().endOf('days').utc().toDate()
}

const startOfMonth = (): Date => {
  return moment().startOf('months').utc().toDate()
}

const endOfMonth = (): Date => {
  return moment().endOf('months').utc().toDate()
}

export {
  formatDateTime,
  formatTime,
  formatDate,
  dateNow,
  toISODateString,
  getDate,
  formatMonthAndYear,
  getDateFormatted,
  startOfToday,
  endOfToday,
  startOfMonth,
  endOfMonth,
}
