// CustomSelect.js
import React, {FC, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import Select from 'react-select'
import axios from 'axios'
import {getClassLecturerSelect, getLecturerSelect} from '../lecturer-list/core/_requests'
import clsx from 'clsx'
import {ID, stringifyRequestQuery, useDebounce} from '../../../../_metronic/helpers'

interface LecturerSelectProps {
  field: any
  form: any
  className?: string
  defaultOption?: any
  initialOptions?: any[]
  isClearable?: boolean
  portal?: any
  classId: ID
  isMulti?: boolean
  onChange?: (data) => void
}

const LecturerSelect: FC<LecturerSelectProps> = ({
  field,
  form,
  isClearable = false,
  className = '',
  defaultOption,
  initialOptions = [],
  portal,
  classId,
  isMulti = false,
  onChange,
  ...rest
}) => {
  const {setFieldValue} = form
  const [keyword, setKeyword] = useState<string | undefined>(undefined)
  const debouncedSearchTerm = useDebounce(keyword, 150)
  const [selectedValues, setSelectedValues] = useState<any[]>(initialOptions ?? [])

  const {isLoading, data, refetch} = useQuery(
    'lecturer-select',
    async () => {
      try {
        const response = await getClassLecturerSelect(
          stringifyRequestQuery({
            keyword: debouncedSearchTerm,
            filter: {
              classId,
            },
          })
        )
        return response.data?.records
      } catch (ex) {
        console.log(ex)
      }
    },
    {enabled: false}
  )

  const handleChange = (selectedOption) => {
    console.log(selectedOption)
    if (isMulti) {
      const data = selectedOption.map((q) => {
        return {
          value: q.value,
          name: q.label,
        }
      })
      setFieldValue(field.name, data)
      setSelectedValues(selectedOption)
    } else {
      setFieldValue(field.name, selectedOption.value)
    }

    if (onChange) {
      onChange(selectedOption)
    }
  }

  const options =
    keyword === undefined
      ? initialOptions
      : data?.some((item) => item.id !== null)
      ? data?.map((option) => ({
          value: option.id,
          label: option.name,
        }))
      : []

  const handleInputChange = (value) => {
    setKeyword(value)
  }

  useEffect(() => {
    if (debouncedSearchTerm === undefined) return
    refetch()
  }, [debouncedSearchTerm])

  console.log(initialOptions)
  console.log(selectedValues)

  return (
    <div className='select-container'>
      <Select
        {...rest}
        name={field.name}
        isLoading={isLoading}
        options={options}
        onChange={handleChange}
        value={isMulti ? selectedValues : options.find((option) => option.value === field.value)} // Set the initial selected value
        classNamePrefix='react-select'
        className={clsx(
          'react-select',
          'menu-outer-top',
          className,
          {'is-invalid': form.touched[field.name] && form.errors[field.name]},
          {'is-valid': form.touched[field.name] && !form.errors[field.name]}
        )}
        isClearable={isClearable}
        onInputChange={handleInputChange}
        onMenuOpen={() => handleInputChange('')}
        styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
        menuPortalTarget={document.body}
        menuShouldScrollIntoView={false}
        placeholder='Type to search...'
        isMulti={isMulti}
      />
    </div>
  )
}

export default LecturerSelect
