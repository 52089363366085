import moment from 'moment'
import {useState} from 'react'
import {initialQueryState} from '../../../../../../../_metronic/helpers'
import DatePickerFilter from '../../../../../../components/DatePickerFilter'
import {ISemester} from '../../../../../../models/ISemester'
import CourseSelect from '../../../../../courses/components/CourseSelect'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {StudentAttendanceSummaryListToolbar} from './StudentAttendanceSummaryListToolbar'
import GroupClassSelect from '../select/GroupClassSelect'

const StudentAttendanceSummaryListHeader = () => {
  const [selectedSemester, setSelectedSemester] = useState<ISemester>()
  const {refetch} = useQueryResponse()
  const {updateState} = useQueryRequest()
  const [studentName, setStudentName] = useState<string>('')
  const [courseId, setCourseId] = useState<number | undefined>()
  const [courseName, setCourseName] = useState<string | undefined>()
  const [startDate, setStartDate] = useState<Date | undefined>(moment().startOf('month').toDate())
  const [endDate, setEndDate] = useState<Date | undefined>(moment().endOf('month').toDate())
  const [groupId, setGroupId] = useState<number | undefined>()
  const [groupName, setGroupName] = useState<string | undefined>()

  // useEffect(() => {
  //   setSelectedCourseQuiz(undefined)
  // }, [selectedSemester])

  const filter = () => {
    if (courseId) {
      let request = {
        keyword: studentName,
        filter: {
          courseId: courseId,
          courseName: courseName,
          startDate: '',
          endDate: '',
          groupId: groupId,
          groupName: groupName,
        },
      }

      if (startDate && endDate) {
        // request.filter.startDate = moment(startDate).format('YYYY-MM-DD HH:mm')
        request.filter.startDate = moment(startDate).toISOString()
        request.filter.endDate = moment(endDate).toISOString()
      }

      // let query = stringifyRequestQuery(request)

      updateState({
        ...initialQueryState,
        ...request,
      })
    }
  }

  return (
    <div className='card-header border-0 pt-6'>
      <div className='d-flex align-items-center position-relative my-1 flex-column flex-xl-row w-100 w-xl-auto'>
        <CourseSelect
          isUseFormik={false}
          onChange={(e) => {
            if (e) {
              setCourseId(e.value)
              setCourseName(e.label)
            } else {
              setCourseId(undefined)
              setCourseName(undefined)
            }
          }}
          className='w-100 w-xl-500px'
          selectedValue={courseId}
          isClearable={true}
        />
        <div className='w-100 w-xl-150px ms-xl-2 ms-0 mt-2 mt-xl-0'>
          <DatePickerFilter
            selected={startDate}
            setSelected={setStartDate}
            dateFormat={'Y-m-d'}
            labelFormat={'d/m/Y'}
            enableTime={false}
          ></DatePickerFilter>
        </div>
        <div className='w-100 w-xl-150px ms-xl-2 ms-0 mt-2 mt-xl-0'>
          <DatePickerFilter
            selected={endDate}
            setSelected={setEndDate}
            dateFormat={'Y-m-d'}
            labelFormat={'d/m/Y'}
            enableTime={false}
          ></DatePickerFilter>
        </div>
        <GroupClassSelect
          selectedValue={groupId}
          className='w-100 w-xl-250px ms-xl-2 ms-0 mt-2 mt-xl-0'
          onChange={(e) => {
            if (e) {
              setGroupId(e.value)
              setGroupName(e.label)
            } else {
              setGroupId(undefined)
              setGroupName(undefined)
            }
          }}
          isClearable={true}
        />
        <button
          onClick={filter}
          disabled={!courseId}
          type='button'
          className='btn btn-primary w-100 w-xl-auto ms-xl-2 ms-0 mt-2 mt-xl-0'
          style={{height: '43px'}}
        >
          Go
        </button>
      </div>
      <div className='card-toolbar'>
        <StudentAttendanceSummaryListToolbar />
      </div>
    </div>
  )
}

export {StudentAttendanceSummaryListHeader}
