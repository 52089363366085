// CustomSelect.js
import React, {FC, useEffect, useState} from 'react'
import Select from 'react-select'
import clsx from 'clsx'
import {useDebounce} from '../../../../_metronic/helpers'
import {useAuth} from '../../auth'

interface SubjectSelectProps {
  field?: any
  form?: any
  className?: string
  defaultOption?: any
  initialOptions?: any[]
  disabled?: boolean
  onChange?(selected: any): void
  selectedValue?: number | null
  isUseFormik?: boolean
  showAllOption?: boolean
  isClearable?: boolean
}

const SubjectSelect: FC<SubjectSelectProps> = ({
  isUseFormik = true,
  field,
  form,
  className = '',
  defaultOption,
  initialOptions = [],
  disabled = false,
  onChange,
  selectedValue,
  showAllOption = false,
  isClearable = false,
  ...rest
}) => {
  const {currentUser} = useAuth()
  const [keyword, setKeyword] = useState<string | undefined>(undefined)
  const [showedOptions, setShowedOptions] = useState<any[]>([])
  const debouncedSearchTerm = useDebounce(keyword, 150)

  const handleChange = (selectedOption) => {
    if (isUseFormik) {
      form.setFieldValue(field.name, selectedOption.value)
    }

    if (onChange) {
      onChange(selectedOption ?? undefined)
    }
  }

  const options = [
    {
      value: 1,
      label: 'S1',
    },
    {
      value: 2,
      label: 'S2',
    },
    {
      value: 3,
      label: 'S3',
    },
  ]

  const handleInputChange = (value) => {
    setKeyword(value)
  }

  useEffect(() => {
    const valueIds = currentUser?.staffMajors?.map((m) => m.degreeId)
    const opt = options?.filter(
      (s) => !valueIds || valueIds?.length === 0 || valueIds?.includes(s.value)
    )

    setShowedOptions(() => [...opt])
    if (showAllOption) {
      const newOption = [
        {
          value: -1,
          label: 'All',
        },
      ]

      setShowedOptions(() => [...newOption, ...opt])
    }
  }, [currentUser])

  useEffect(() => {
    const valueIds = currentUser?.staffMajors?.map((m) => m.degreeId)
    console.log(currentUser)

    if (keyword === undefined) return

    let showed = options.filter(
      (q) =>
        (!valueIds || valueIds?.length === 0 || valueIds?.includes(q.value)) &&
        q.label.toLowerCase().includes(keyword.toLowerCase())
    )
    setShowedOptions(() => [...showed])
  }, [keyword, currentUser])

  return isUseFormik ? (
    <Select
      {...rest}
      name={field.name}
      options={showedOptions}
      onChange={handleChange}
      value={showedOptions.find((option) => option.value === field.value)} // Set the initial selected value
      classNamePrefix='react-select'
      className={clsx(
        'react-select',
        className,
        {'is-invalid': form.touched[field.name] && form.errors[field.name]},
        {'is-valid': form.touched[field.name] && !form.errors[field.name]}
      )}
      onInputChange={handleInputChange}
      onMenuOpen={() => handleInputChange('')}
      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      isDisabled={disabled}
      isClearable={isClearable}
      placeholder='Select Degree'
    />
  ) : (
    <Select
      {...rest}
      options={showedOptions}
      onChange={handleChange}
      value={selectedValue ? showedOptions.find((q) => q.value === selectedValue) : null}
      classNamePrefix='react-select'
      isMulti={false}
      className={clsx('react-select', className)}
      isSearchable={false}
      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      isClearable={isClearable}
      placeholder='Select Degree'
    />
  )
}

export default SubjectSelect
