// CustomSelect.js
import React, {FC, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import CreateableSelect from 'react-select/creatable'
import axios from 'axios'
import clsx from 'clsx'
import {ID, stringifyRequestQuery, useDebounce} from '../../../../../_metronic/helpers'
import {getGroupClassSelect} from '../core/_requests'
import {GroupClass} from '../core/_models'

interface Props {
  field?: any
  form?: any
  className?: string
  defaultOption?: any
  initialOptions?: any[]
  disabled?: boolean
  onChange?(selected: any): void
  selectedValue?: number | null
  isUseFormik?: boolean
  withCreate?: boolean
  onCreate?: (name: string) => Promise<GroupClass>
  degreeId: number
  isClearable?: boolean
}

const GroupClassSelect: FC<Props> = ({
  field,
  form,
  className = '',
  defaultOption,
  initialOptions = [],
  disabled = false,
  onChange,
  selectedValue,
  isUseFormik = true,
  withCreate = false,
  onCreate,
  degreeId,
  isClearable = false,
  ...rest
}) => {
  const [keyword, setKeyword] = useState<string | undefined>(undefined)
  const debouncedSearchTerm = useDebounce(keyword, 150)
  const [options, setOptions] = useState<any[]>([...initialOptions])

  const {isLoading, data, refetch} = useQuery(
    'group-class-select',
    async () => {
      try {
        const response = await getGroupClassSelect(
          stringifyRequestQuery({
            keyword: debouncedSearchTerm,
            filter: {
              degreeId
            }
          })
        )
        return response.data
      } catch (ex) {
        console.log(ex)
      }
    },
    {enabled: false}
  )

  useEffect(() => {
    handleChange(undefined)
    refetch()
  }, [degreeId])

  const handleChange = (selectedOption) => {
    if (isUseFormik) {
      form.setFieldValue(field.name, selectedOption?.value || undefined)
    } else if(onChange) {
      onChange(selectedOption ?? undefined)
    }
  }

  useEffect(() => {
    if (data?.records && data.records.some((item) => item.id !== null)) {
      setOptions([...data.records.map((option) => ({
        value: option.id,
        label: option.name,
      }))])
    } else {
      setOptions(initialOptions)
    }
  }, [data])

  // const options = data?.records?.some((item) => item.id !== null)
  //   ? data?.records?.map((option) => ({
  //       value: option.id,
  //       label: option.name,
  //     }))
  //   : initialOptions

  const handleInputChange = (value) => {
    setKeyword(value)
  }

  useEffect(() => {
    if (debouncedSearchTerm === undefined) return
    refetch()
  }, [debouncedSearchTerm])

  const handleCreate = (inputValue: string) => {
    if(onCreate) {
      onCreate(inputValue)
        .then((resp) => {
          const created = {value: resp.id, label: resp.name}
          setOptions((prev) => [...prev, created].sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          }))
          handleChange(created)
        })
    }
  }

  return (
    isUseFormik ?
      <CreateableSelect
        {...rest}
        name={field.name}
        isLoading={isLoading}
        options={options}
        onChange={handleChange}
        value={options.find((option) => option.value === field.value)} // Set the initial selected value
        classNamePrefix='react-select'
        className={clsx(
          'react-select',
          className,
          {'is-invalid': form.touched[field.name] && form.errors[field.name]},
          {'is-valid': form.touched[field.name] && !form.errors[field.name]}
        )}
        onInputChange={handleInputChange}
        onMenuOpen={() => handleInputChange('')}
        styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
        menuPortalTarget={document.body}
        menuShouldScrollIntoView={false}
        isDisabled={disabled}
        placeholder="Type to search..."
        onCreateOption={withCreate ? handleCreate : undefined}
        allowCreateWhileLoading={false}
        isClearable={isClearable}
      /> :
      <CreateableSelect
        {...rest}
        options={options}
        onChange={handleChange}
        value={selectedValue ? options?.find((q) => q.value === selectedValue) : null}
        classNamePrefix='react-select'
        isMulti={false}
        className={clsx(
          'react-select',
          className,
        )}
        onInputChange={handleInputChange}
        onMenuOpen={() => handleInputChange('')}
        styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
        menuPortalTarget={document.body}
        menuShouldScrollIntoView={false}
        placeholder="Type to search..."
        isDisabled={disabled}
        onCreateOption={withCreate ? handleCreate : undefined}
        allowCreateWhileLoading={false}
        isClearable={isClearable}
      />
  )
}

export default GroupClassSelect
