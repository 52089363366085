import {useEffect, useMemo, useState} from 'react'
import {KTIcon, stringifyRequestQuery} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {showError} from '../../../../../../utils/toast'
import {downloadFile} from '../../../../../../utils/file'
import LoadingButtonWrapper from '../../../../../../components/LoadingButtonWrapper'

const StudentAttendanceListToolbar = () => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const [loadingExcel, setLoadingExcel] = useState<boolean>(false)
  const [loadingPdf, setLoadingPdf] = useState<boolean>(false)
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  const url = 'reports/attendance'

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const excel = async () => {
    try {
      setLoadingExcel(true)
      await downloadFile(
        `${url}-excel?${query}`,
        `ReportStudentAttendance-${state.filter?.semesterName}.xlsx`
      )
    } catch (err: any) {
      showError(err.message)
    } finally {
      setLoadingExcel(false)
    }
  }

  const pdf = async () => {
    try {
      setLoadingPdf(true)
      await downloadFile(
        `${url}/pdf?${query}`,
        `ReportStudentAttendance-${state.filter?.semesterName}.pdf`
      )
    } catch (err: any) {
      showError(err.message)
    } finally {
      setLoadingPdf(false)
    }
  }

  return (
    <div className='d-flex justify-content-end' data-kt-student-table-toolbar='base'>
      <LoadingButtonWrapper isLoading={loadingExcel}>
        <button
          type='button'
          className='btn btn-light-success me-3'
          onClick={() => excel()}
          disabled={loadingExcel}
        >
          <KTIcon iconName='exit-up' className='fs-2' />
          Export
        </button>
      </LoadingButtonWrapper>

      <LoadingButtonWrapper isLoading={loadingPdf}>
        <button
          type='button'
          className='btn btn-light-danger me-3'
          onClick={() => pdf()}
          disabled={loadingPdf}
        >
          <KTIcon iconName='file' className='fs-2' />
          PDF
        </button>
      </LoadingButtonWrapper>
    </div>
  )
}

export {StudentAttendanceListToolbar}
