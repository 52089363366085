import {FC, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {getTodoList} from '../core/_request'
import Skeleton from 'react-loading-skeleton'
import {
  formatDate,
  formatDateTime,
  formatMonthAndYear,
  formatTime,
  getDate,
  getDateFormatted,
} from '../../../utils/date'
import {TodoListItem} from '../core/_model'
import clsx from 'clsx'
import {Link} from 'react-router-dom'

const TodoListCard: FC = () => {
  const [index, setIndex] = useState<number>(0)
  const [style] = useState<string>('nav-link text-active-primary me-6 cursor-pointer ')

  const {data, isError, isLoading, error} = useQuery(['get-todo-list'], () => getTodoList(), {
    select: (d) => d.data,
  })

  if (isError) {
    // @ts-ignore
    return <p>{error?.message}</p>
  }

  if (isLoading) {
    return (
      <>
        <div className='row'>
          <div className='col-12 col-lg-12 mb-4'>
            <Skeleton count={1} height={200} />
          </div>
          <div className='col-12 col-lg-12 mb-4'>
            <Skeleton count={1} height={200} />
          </div>
        </div>
      </>
    )
  }

  const active = (idx: number): string => {
    if (idx === index) {
      return style + 'text-active-danger active'
    }
    return style
  }

  const render = (idx: number): JSX.Element | JSX.Element[] | undefined => {
    if (idx === 0) {
      if (data?.upcoming.length === 0) {
        return <NoData />
      }

      return data?.upcoming.map((v, i) => {
        return <Item key={i} data={v} isLast={i === data?.upcoming.length - 1} />
      })
    } else if (idx === 1) {
      if (data?.outdated.length === 0) {
        return <NoData />
      }

      return data?.outdated.map((v, i) => {
        return <Item key={i} data={v} isLast={i === data?.outdated.length - 1} />
      })
    }

    return <></>
  }

  return (
    <>
      <div className='d-flex justify-content-center flex-column me-3 mb-3'>
        <p className='fs-5 fw-bolder text-gray-700 me-1 mb-2 mt-4 lh-1'>Quiz & Assignment</p>
      </div>
      <div className='card'>
        <div className='card-body todo-wrapper'>
          <div className='row'>
            <div className='container mb-6'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item' onClick={() => setIndex(0)}>
                  <span className={active(0)}>Upcoming</span>
                </li>
                <li className='nav-item' onClick={() => setIndex(1)}>
                  <span className={active(1)}>Outdated</span>
                </li>
              </ul>
            </div>
          </div>
          <div>{render(index)}</div>
        </div>
      </div>
    </>
  )
}

type ItemProps = {
  data: TodoListItem
  isLast: boolean
}

const Item: FC<ItemProps> = ({data, isLast}) => {
  const formatLink = (data: TodoListItem): string => {
    if (data.isQuiz) {
      return `/classes/${data.classId}#quizess`
    } else if (data.isAssignment) {
      return `/classes/${data.classId}#assignments`
    } else {
      return `/classes/${data.classId}`
    }
  }

  return (
    <Link to={formatLink(data)}>
      <div>
        <div className={clsx('card-upcoming d-flex', {'border-bottom': !isLast})}>
          <div className='p-2 pt-4 d-flex flex-column align-items-center mb-5' style={{width: 60}}>
            <div className='symbol symbol-65px me-2'>
              <i className='fas fa-calendar pt-2' style={{fontSize: 64, color: '#f1f1f1'}}></i>
              <div className='calendar-icon-text'>
                <div>{getDateFormatted(data.deadline, 'ddd')}</div>
                <div>
                  <h2>{data.isAssignment && getDate(data.deadline)}</h2>
                  <h2>{data.isQuiz && getDate(data.startDate)}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className={clsx('flex-grow-1 px-5 py-5')}>
            <h4>{data.subjectName}</h4>
            {data.deadline && data.isAssignment && (
              <div className='text-gray-500 fw-semibold d-flex fs-6 align-items-center'>
                <KTIcon iconName='time' iconType='solid' className='me-1 fs-4' /> Deadline:
                {formatDateTime(data.deadline)}
              </div>
            )}

            {data.deadline && data.isQuiz && data.isSameDay && (
              <div className='text-gray-500 fw-semibold d-flex fs-6'>
                {formatDateTime(data.startDate)} - {formatTime(data.deadline)}
              </div>
            )}

            {data.deadline && data.isQuiz && !data.isSameDay && (
              <div className='text-gray-500 fw-semibold d-flex fs-6'>
                {formatDateTime(data.startDate)} - {formatDateTime(data.deadline)}
              </div>
            )}

            <div className='text-gray-500 fw-semibold d-flex fs-6 mb-5 mt-5 align-items-center'>
              <span className='badge badge-primary fs-7 px-4 me-2'>{data.name}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
    // <div className='row'>
    //   <div className='col-md-2'>
    //     <div
    //       className='container h-100'
    //       style={{
    //         borderRight: '2px solid #cdcdcd',
    //       }}
    //     >
    //       <div className='row h-100 justify-content-center align-items-center'>
    //         <div>
    //           <div className='fs-2hx fw-bold lh-1 ls-n2 d-flex justify-content-center'>
    //             {getDate(data.deadline)}
    //           </div>
    //           <div className='d-flex justify-content-center'>
    //             {formatMonthAndYear(data.deadline)}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className='col-md-6'>
    //     <p className='opacity-80 fw-bold fs-1'>{data.subjectName}</p>
    //     <div className='d-flex flex-row'>
    //       <div className='p-2'>
    //         <p className='text-grey opacity-75 pt-1 fw-semibold fs-6 text-truncate'>
    //           <KTIcon iconName='book-square' iconType='solid' className='me-1 fs-2' />
    //           {data.assignmentName}: {data.description}
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    //   <div className='col-md-3 offset-md-1'>
    //     <div className='container d-flex flex-row-reverse align-items-center h-100'>
    //       <KTIcon iconName='right-square' iconType='solid' className='fs-1 text-bold' />
    //     </div>
    //   </div>
    // </div>
  )
}

const NoData: FC = () => {
  return (
    <div className='text-center'>
      <div
        className='card-body'
        style={{height: 'calc(50vh - 128px)', overflowY: 'auto', overflowX: 'hidden'}}
      >
        <h5 className='card-title'>
          <KTIcon iconName='pencil' iconType='solid' className='fs-4x' />
        </h5>
        <p className='card-text text-muted'>No Activities Due</p>
      </div>
    </div>
  )
}

export default TodoListCard
