import React, {Dispatch, SetStateAction} from 'react'
import Flatpickr from 'react-flatpickr'

interface DatePickerProps {
  selected: Date | undefined
  setSelected: Dispatch<SetStateAction<Date | undefined>>
  options?: any
  dateFormat?: string
  labelFormat?: string
  enableTime?: boolean
  dateOnly?: boolean
  isStatic?: boolean
  className?: string
}

const DatePickerFilter: React.FC<DatePickerProps> = ({
  selected,
  setSelected,
  options,
  className = '',
  dateFormat = 'Y-m-d',
  labelFormat = 'd/m/Y',
  enableTime = false,
  isStatic = true,
}) => {
  // const inputRef = useRef<HTMLInputElement>(null)
  // const [date, setDate] = useState(new Date())

  return (
    <>
      <Flatpickr
        value={selected}
        options={{
          altInput: true,
          altFormat: labelFormat,
          dateFormat: dateFormat,
          enableTime: enableTime,
          allowInput: true,
          ...options,
          time_24hr: true,
          static: isStatic,
        }}
        onClose={([selectedDate]) => {
          setSelected(selectedDate)
        }}
        onChange={([selectedDate]) => {
          setSelected(selectedDate)
        }}
      />
    </>
  )
}

export default DatePickerFilter
