// CustomSelect.js
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import Select from 'react-select'
import {GroupClass} from '../../../../../students/student-major-modal/core/_models'
import {getGroupClassSelect} from '../../../../../students/student-major-modal/core/_requests'
import {stringifyRequestQuery, useDebounce} from '../../../../../../../_metronic/helpers'

interface Props {
  field?: any
  form?: any
  className?: string
  defaultOption?: any
  initialOptions?: any[]
  disabled?: boolean
  onChange?(selected: any): void
  selectedValue?: number | null
  withCreate?: boolean
  onCreate?: (name: string) => Promise<GroupClass>
  degreeId?: number | null
  isClearable?: boolean
}

const GroupClassSelect: FC<Props> = ({
  field,
  form,
  className = '',
  defaultOption,
  initialOptions = [],
  disabled = false,
  onChange,
  selectedValue,
  withCreate = false,
  onCreate,
  degreeId,
  isClearable = false,
  ...rest
}) => {
  const [keyword, setKeyword] = useState<string | undefined>(undefined)
  const debouncedSearchTerm = useDebounce(keyword, 150)
  const [options, setOptions] = useState<any[]>([...initialOptions])

  const {isLoading, data, refetch} = useQuery(
    'group-class-select',
    async () => {
      try {
        const response = await getGroupClassSelect(
          stringifyRequestQuery({
            keyword: debouncedSearchTerm,
            filter: {
              degreeId,
            },
          })
        )
        return response.data
      } catch (ex) {
        console.log(ex)
      }
    },
    {enabled: false}
  )

  useEffect(() => {
    handleChange(undefined)
    refetch()
  }, [degreeId])

  const handleChange = (selectedOption) => {
    if (onChange) {
      onChange(selectedOption ?? undefined)
    }
  }

  useEffect(() => {
    if (data?.records && data.records.some((item) => item.id !== null)) {
      setOptions([
        ...data.records.map((option) => ({
          value: option.id,
          label: option.name,
        })),
      ])
    } else {
      setOptions(initialOptions)
    }
  }, [data])

  const handleInputChange = (value) => {
    setKeyword(value)
  }

  useEffect(() => {
    if (debouncedSearchTerm === undefined) return
    refetch()
  }, [debouncedSearchTerm])

  const handleCreate = (inputValue: string) => {
    if (onCreate) {
      onCreate(inputValue).then((resp) => {
        const created = {value: resp.id, label: resp.name}
        setOptions((prev) =>
          [...prev, created].sort((a, b) => {
            if (a.label < b.label) {
              return -1
            }
            if (a.label > b.label) {
              return 1
            }
            return 0
          })
        )
        handleChange(created)
      })
    }
  }

  return (
    <Select
      placeholder='Type to search...'
      {...rest}
      isClearable={isClearable}
      isLoading={isLoading}
      options={options}
      onChange={handleChange}
      value={selectedValue ? options.find((option) => option.value === selectedValue) : null}
      classNamePrefix='react-select'
      className={clsx('react-select', className)}
      onInputChange={handleInputChange}
      onMenuOpen={() => handleInputChange('')}
      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      isDisabled={disabled}
    />
  )
}

export default GroupClassSelect
