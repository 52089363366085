import {Modal} from 'react-bootstrap'
import {FC, useEffect, useState} from 'react'
import {ID} from '../../../../../_metronic/helpers'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {CourseDuplicate, initialCourseDuplicate} from '../core/_models'
import {duplicateCourse} from '../core/_requests'
import SemesterSelect from '../../../semester/components/SemesterSelect'
import {CourseListLoading} from '../components/loading/CourseListLoading'
import LoadingButtonWrapper from '../../../../components/LoadingButtonWrapper'

type Props = {
  courseId: ID
  degreeId: number
  show: boolean
  onClose(): void
  onSuccess(): void
}

let yupValidation = {
  semesterId: Yup.number().required(),
}

const schema = Yup.object().shape(yupValidation)

const CourseDuplicateModal: FC<Props> = ({courseId, degreeId, show, onClose, onSuccess}) => {
  const [dataForEdit, setDataForEdit] = useState<CourseDuplicate>({
    ...initialCourseDuplicate,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isCopyClass, setIsCopyClass] = useState(false)

  useEffect(() => {
    setDataForEdit({
      id: courseId,
    })
  }, [courseId])

  const handleClose = () => {
    onClose()
  }
  const handleConfirm = () => {}

  const onChangeCopyClass=() => {
    setIsCopyClass(!isCopyClass)
  }

  const formik = useFormik({
    initialValues: dataForEdit,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values, {setSubmitting, setStatus}) => {
      setStatus(null)
      const model = {...values, isCopyClass: isCopyClass}
      setSubmitting(true)
      setIsLoading(true)
      let result: any
      try {
        result = await duplicateCourse(model)

        if (result) {
          onSuccess()
          setSubmitting(true)
          setIsLoading(false)
        } else {
          setStatus('Error')
          setIsLoading(false)
        }
      } catch (e: any) {
        console.error(e)
        setStatus(e.message)
        setIsLoading(false)
      }
    },
  })

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='p-4'>
            <form className='form' onSubmit={formik.handleSubmit} noValidate>
              <div className='d-flex flex-column'>
                {formik.status && (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                )}

                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Semester</label>
                  <SemesterSelect
                    isClearable={false}
                    className='menu-outer-top'
                    field={formik.getFieldProps('semesterId')}
                    form={formik}
                    degreeId={degreeId}
                  />
                  {formik.touched.semesterId && formik.errors.semesterId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.semesterId}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      data-kt-check-target='#kt_table_student .form-check-input'
                      onChange={onChangeCopyClass}
                      style={{marginRight: '10px'}}
                    />
                    Copy Class/Student
                  </label>
                </div>
              </div>
              <div className='text-end pt-15'>
                <LoadingButtonWrapper isLoading={formik.isSubmitting || isLoading}>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || isLoading}
                  >
                    <span className='indicator-label'>Submit</span>
                  </button>
                </LoadingButtonWrapper>
              </div>
            </form>
          </div>
          {(formik.isSubmitting || isLoading) && <CourseListLoading />}
        </Modal.Body>
      </Modal>
    </>
  )
}

export {CourseDuplicateModal}
