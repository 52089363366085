import {Modal, Button} from 'react-bootstrap'
import {useEffect, useState} from 'react'
import {showError} from '../../../../../../utils/toast'
import {downloadFile} from '../../../../../../utils/file'
import {KTIcon, stringifyRequestQuery} from '../../../../../../../_metronic/helpers'
import DatePickerFilter from '../../../../../../components/DatePickerFilter'
import moment from 'moment'
import BlockLoading from '../../../../../../components/BlockLoading'

function SurveyResultFilterModal({show, handleClose, handleConfirm}) {
  const url = 'reports/survey-result-all'
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState<Date | undefined>(moment().startOf('month').toDate())
  const [endDate, setEndDate] = useState<Date | undefined>(moment().endOf('month').toDate())

  useEffect(() => {
    setStartDate(startDate)
  }, [startDate, setStartDate])

  useEffect(() => {
    setEndDate(endDate)
  }, [endDate, setEndDate])

  const excel = async () => {
    try {
      setIsLoading(true)
      let request = {
        filter: {
          startDate: '',
          endDate: '',
        },
      }

      if (startDate && endDate) {
        request.filter.startDate = moment(startDate).format('YYYY-MM-DD HH:mm')
        request.filter.endDate = moment(endDate).format('YYYY-MM-DD HH:mm')
      }

      let query = stringifyRequestQuery(request)

      await downloadFile(`${url}/excel?${query}`, 'SurveyResultAll.xlsx')
    } catch (err: any) {
      showError(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const clearDate = () => {
    setStartDate(undefined)
    setEndDate(undefined)
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Export All</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='p-4'>
          <div className='row'>
            <div className='col-12 col-lg-5'>
              <label className='fw-bold fs-6 mb-2'>Start Date</label>
              <DatePickerFilter
                selected={startDate}
                setSelected={setStartDate}
                dateFormat={'Y-m-d H:i'}
                labelFormat={'d/m/Y H:i'}
                enableTime={true}
              ></DatePickerFilter>
            </div>
            <div className='col-12 col-lg-5'>
              <label className='fw-bold fs-6 mb-2'>End Date</label>
              <DatePickerFilter
                selected={endDate}
                setSelected={setEndDate}
                dateFormat={'Y-m-d H:i'}
                labelFormat={'d/m/Y H:i'}
                enableTime={true}
              ></DatePickerFilter>
            </div>
            <div className='col-12 col-lg-2'>
              <Button variant='secondary' onClick={clearDate} className='mt-8'>
                Clear
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose} disabled={isLoading}>
          Close
        </Button>
        <Button variant='primary' onClick={excel} disabled={isLoading}>
          Export
        </Button>
      </Modal.Footer>
      {isLoading && <BlockLoading />}
    </Modal>
  )
}

export {SurveyResultFilterModal}
