import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Subject, SubjectQueryResponse} from './_models'
import {IGradingType} from '../../../../models/IGradingType'

const API_URL = process.env.REACT_APP_BASE_API_URL
const SUBJECT_URL = `${API_URL}/subjects`
const GET_SUBJECT_URL = `${API_URL}/subjects`

const getSubject = (query: string): Promise<SubjectQueryResponse> => {
  return axios
    .get(`${GET_SUBJECT_URL}?${query}`)
    .then((d: AxiosResponse<SubjectQueryResponse>) => d.data)
}

const getSubjectSelect = (query: string): Promise<SubjectQueryResponse> => {
  return axios
    .get(`${GET_SUBJECT_URL}/select?${query}`)
    .then((d: AxiosResponse<SubjectQueryResponse>) => d.data)
}

const getSubjectById = (id: ID): Promise<Subject | undefined> => {
  return axios
    .get(`${SUBJECT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Subject>>) => response.data)
    .then((response: Response<Subject>) => response.data)
}

const createSubject = (subject: Subject): Promise<Subject | undefined> => {
  return axios
    .post(SUBJECT_URL, subject)
    .then((response: AxiosResponse<Response<Subject>>) => response.data)
    .then((response: Response<Subject>) => response.data)
}

const updateSubject = (subject: Subject): Promise<Subject | undefined> => {
  return axios
    .put(`${SUBJECT_URL}/${subject.id}`, subject)
    .then((response: AxiosResponse<Response<Subject>>) => response.data)
    .then((response: Response<Subject>) => response.data)
}

const deleteSubject = (subjectId: ID): Promise<void> => {
  return axios.delete(`${SUBJECT_URL}/${subjectId}`).then(() => {})
}

const reactiveSubject = (subjectId: ID): Promise<void> => {
  return axios.put(`${SUBJECT_URL}/reactive/${subjectId}`).then(() => { })
}

const deleteSelectedSubject = (subjectIds: Array<ID>): Promise<void> => {
  const requests = subjectIds.map((id) => axios.delete(`${SUBJECT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getSubjectGrading = (query: string): Promise<IGradingType[] | undefined> => {
  return axios
    .get(`${SUBJECT_URL}/grading?${query}`)
    .then((response: AxiosResponse<Response<IGradingType[]>>) => response.data)
    .then((response: Response<IGradingType[]>) => response.data)
}

export {
  getSubject,
  deleteSubject,
  deleteSelectedSubject,
  getSubjectById,
  createSubject,
  updateSubject,
  getSubjectSelect,
  getSubjectGrading,
  reactiveSubject
}
