import { KTCard } from '../../../../../_metronic/helpers'
import { StudentAttendanceSummaryListHeader } from './components/header/StudentAttendanceSummaryListHeader'
import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { StudentAttendanceSummaryTable } from './table/StudentAttendanceSummaryTable'

const StudentAttendanceSummaryList = () => {
  return (
    <>
      <div className='d-flex flex-row justify-content-between mb-8'>
        <h1 className='page-heading text-dark fw-bold fs-3 my-0'>Report Student Attendance Summary</h1>
      </div>
      <KTCard>
        <StudentAttendanceSummaryListHeader />
        <StudentAttendanceSummaryTable />
      </KTCard>
    </>
  )
}

const StudentAttendanceSummaryListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <StudentAttendanceSummaryList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { StudentAttendanceSummaryListWrapper }

