import {KTCard} from '../../../../../_metronic/helpers'
import {QuizResultByGroupListHeader} from './components/header/QuizResultByGroupListHeader'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {QuizResultByGroupTable} from './table/QuizResultByGroupTable'

const QuizResultByGroupList = () => {
  return (
    <>
      <div className='d-flex flex-row justify-content-between mb-8'>
        <h1 className='page-heading text-dark fw-bold fs-3 my-0'>Report Quiz Result by Group</h1>
      </div>
      <KTCard>
        <QuizResultByGroupListHeader />
        <QuizResultByGroupTable />
      </KTCard>
    </>
  )
}

const QuizResultByGroupListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <QuizResultByGroupList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {QuizResultByGroupListWrapper}
