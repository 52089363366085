// @ts-nocheck
import {Column} from 'react-table'
import {MeetAttendanceCustomHeader} from './MeetAttendanceCustomHeader'
import {MeetAttendanceType} from '../../core/_model'

const meetAttendanceColumns: ReadonlyArray<Column<MeetAttendanceType>> = [
  {
    Header: (props) => (
      <MeetAttendanceCustomHeader
        tableProps={props}
        title='Semester'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'semesterName',
    id: 'semesterName',
  },
  {
    Header: (props) => (
      <MeetAttendanceCustomHeader
        tableProps={props}
        title='Total Meet'
        className='min-w-125px'
      />
    ),
    accessor: 'totalStudent',
    id: 'totalStudent',
  },
  {
    Header: (props) => (
      <MeetAttendanceCustomHeader
        tableProps={props}
        title='Total Attendances'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'totalAttendance',
    id: 'totalAttendance',
  },
  {
    Header: (props) => (
      <MeetAttendanceCustomHeader tableProps={props} title='Percentage' className='min-w-125px' />
    ),
    accessor: 'percentage',
  },
]

export {meetAttendanceColumns}
