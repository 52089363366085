import {useMemo} from 'react'
import {Row, useTable} from 'react-table'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {StudentAttendanceSummaryColumns} from './columns/_columns'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import { ReportLoading } from '../../../components/ReportLoading'

const StudentAttendanceSummaryTable = () => {
  const d = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => d, [d])
  const columns = useMemo(() => StudentAttendanceSummaryColumns, [])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: data ?? [],
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_attendance'
          className='table table-bordered table-rounded dataTable align-middle table-row-dashed no-footer gs-7 fs-6 gy-5'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th rowSpan={2} className='text-start align-middle w-150px'>
                NIM
              </th>
              <th rowSpan={2} className='text-start align-middle w-250px'>
                Name
              </th>
              {rows.length > 0 &&
                rows[0].original.days?.map((row, i) => {
                  return (
                    <th key={i} className='text-start align-middle'>
                      {row}
                    </th>
                  )
                })}
              <th rowSpan={2} className='align-middle'>
                Total Attend
              </th>
              <th rowSpan={2} className='align-middle'>
                % Attendance
              </th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                return (
                  <>
                    {row.original.studentMajorId > 0 ? (
                      <tr key={i}>
                        <td>{row.original.nim}</td>
                        <td>{row.original.studentName}</td>
                        {row.original.details?.map((detail, indexDetail) => {
                          return (
                            <td className='text-start' key={indexDetail}>
                              {detail.statusId == 10 && (
                                <i className='fa fa-circle-check' style={{color: 'green'}}></i>
                              )}
                              {detail.statusId == 20 && (
                                <i className='fa fa-times' style={{color: 'red'}}></i>
                              )}
                            </td>
                          )
                        })}
                        <td className='text-center'>{row.original.totalAttend}</td>
                        <td className='text-center'>{row.original.totalAttendPercentage}</td>
                      </tr>
                    ) : (
                      <tr key={i}>
                        <td colSpan={2} className='text-center'>
                          Total
                        </td>
                        {row.original.details?.map((detail, indexDetail) => {
                          return (
                            <td className='text-start' key={'footer' + indexDetail}>
                              {detail.count}
                            </td>
                          )
                        })}
                        <td className='text-center'>{row.original.totalAttend}</td>
                        <td className='text-center'>{row.original.totalAttendPercentage}</td>
                      </tr>
                    )}
                  </>
                )
              })
            ) : (
              <tr>
                <td colSpan={6}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {isLoading ? '' : ' No matching records found'}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isLoading && <ReportLoading />}
      </KTCardBody>
  )
}

export {StudentAttendanceSummaryTable}
