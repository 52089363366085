import React, {useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {ClassListWrapper} from '../../class-list/ClassList'

function CourseClassModal({id, show, handleClose, name, isLoading = false, error = null}) {
  return (
    <Modal show={show} onHide={handleClose} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>
          <span id='course-class-modal-title'></span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ClassListWrapper id={id} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose} disabled={isLoading}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export {CourseClassModal}
