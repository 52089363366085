import {useState} from 'react'
import TodayMeetsCard from './TodayMeetsCard'
import LecturerTodayMeetsCard from './LecturerTodayMeetsCard'

const TodayActivity = () => {
  const [typeId, setTypeId] = useState(1)

  return (
    <div>
      <div className='d-flex justify-content-center flex-column me-3 mb-3'>
        <div className='d-flex justify-content-between'>
          <p className='fs-5 fw-bolder text-gray-700 text-hover-primary me-1 mb-2 mt-4 lh-1'>
            Today Activities
          </p>
          <div className='d-flex align-items-center mt-3'>
            <label className='form-check form-check-inline form-check-solid me-5'>
              <input
                type='radio'
                name='typeId'
                className='form-check-input'
                value={1}
                checked={typeId == 1}
                onChange={() => setTypeId(1)}
              />
              <span className='fw-bold ps-2 fs-6'>By Class</span>
            </label>

            <label className='form-check form-check-inline form-check-solid me-5'>
              <input
                type='radio'
                name='typeId'
                className='form-check-input'
                value={2}
                checked={typeId == 2}
                onChange={() => setTypeId(2)}
              />
              <span className='fw-bold ps-2 fs-6'>By Lecturer</span>
            </label>
          </div>
        </div>
      </div>

      {typeId == 1 ? <TodayMeetsCard /> : <LecturerTodayMeetsCard isByLecturer={typeId == 2} />}
    </div>
  )
}

export default TodayActivity
