// @ts-nocheck
import {Column} from 'react-table'
import {QuizResultByGroup, StudentAttendanceType} from '../../core/_model'
import {QuizResultByGroupCustomHeader} from './QuizResultByGroupCustomHeader'

const quizResultByGroupColumns: ReadonlyArray<Column<QuizResultByGroup>> = [
  {
    Header: (props) => (
      <QuizResultByGroupCustomHeader
        tableProps={props}
        title='No'
        className='min-w-125px'
        enableSort={false}
      />
    ),
    accessor: 'no',
    id: 'no',
  },
  {
    Header: (props) => (
      <QuizResultByGroupCustomHeader
        tableProps={props}
        title='Name'
        className='min-w-125px'
        enableSort={false}
      />
    ),
    accessor: 'name',
    id: 'name',
  },
  {
    Header: (props) => (
      <QuizResultByGroupCustomHeader
        tableProps={props}
        title='Nim'
        className='min-w-125px'
        enableSort={false}
      />
    ),
    accessor: 'nim',
    id: 'nim',
  },
  {
    Header: (props) => (
      <QuizResultByGroupCustomHeader
        tableProps={props}
        title='Rank'
        className='min-w-125px'
        enableSort={false}
      />
    ),
    accessor: 'rank',
    id: 'rank',
  },
  {
    Header: (props) => (
      <QuizResultByGroupCustomHeader
        tableProps={props}
        title='Correct'
        className='min-w-125px'
        enableSort={false}
      />
    ),
    accessor: 'correct',
    id: 'correct',
  },
  {
    Header: (props) => (
      <QuizResultByGroupCustomHeader
        tableProps={props}
        title='Score'
        className='min-w-125px'
        enableSort={false}
      />
    ),
    accessor: 'score',
    id: 'score',
  },
  // {
  //   Header: (props) => (
  //     <QuizResultByGroupCustomHeader
  //       tableProps={props}
  //       title='Correct 2'
  //       className='min-w-125px'
  //       enableSort={false}
  //     />
  //   ),
  //   accessor: 'correct2',
  //   id: 'correct2',
  // },
  // {
  //   Header: (props) => (
  //     <QuizResultByGroupCustomHeader
  //       tableProps={props}
  //       title='Score 2'
  //       className='min-w-125px'
  //       enableSort={false}
  //     />
  //   ),
  //   accessor: 'score2',
  //   id: 'score2',
  // },
  // {
  //   Header: (props) => (
  //     <QuizResultByGroupCustomHeader
  //       tableProps={props}
  //       title='Correct 3'
  //       className='min-w-125px'
  //       enableSort={false}
  //     />
  //   ),
  //   accessor: 'correct3',
  //   id: 'correct3',
  // },
  // {
  //   Header: (props) => (
  //     <QuizResultByGroupCustomHeader
  //       tableProps={props}
  //       title='Score 3'
  //       className='min-w-125px'
  //       enableSort={false}
  //     />
  //   ),
  //   accessor: 'score3',
  //   id: 'score3',
  // },
]

export {quizResultByGroupColumns}
