import {FC} from 'react'
import clsx from 'clsx'
import Select from 'react-select'

type Props = {
  field?: any
  form?: any
  className?: string
  defaultOption?: any
  initialOptions?: any[]
  isUseFormik?: boolean
  allowClear?: boolean
  onChange?(selected: any): void
  selectedValue?: number | null
}

const ClassTypeSelect: FC<Props> = ({
  isUseFormik = true,
  className = '',
  initialOptions = [],
  allowClear = true,
  field,
  form,
  defaultOption,
  onChange,
  selectedValue,
  ...rest
}) => {
  const classTypes: {value: number | undefined; label: string}[] = [
    {
      label: 'All',
      value: -1,
    },
    {
      label: 'Default',
      value: 1,
    },
    {
      label: 'Exam',
      value: 2,
    },
  ]

  const handleChange = (selectedOption) => {
    if (isUseFormik) {
      form.setFieldValue(field.name, selectedOption?.value)
    }

    if (onChange) {
      onChange(selectedOption ?? undefined)
    }
  }

  return isUseFormik ? (
    <>
      <Select
        {...rest}
        name={field.name}
        options={classTypes.filter((x) => (x.value ?? 0) > 0)}
        onChange={handleChange}
        value={classTypes.find((x) => x.value == field.value)}
        classNamePrefix='react-select'
        isMulti={false}
        className={clsx(
          'react-select',
          className,
          {
            'is-invalid': !isUseFormik
              ? false
              : form.touched[field.name] && form.errors[field.name],
          },
          {'is-valid': !isUseFormik ? false : form.touched[field.name] && !form.errors[field.name]}
        )}
        isClearable={allowClear}
        isSearchable={false}
        styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
        menuPortalTarget={document.body}
        menuShouldScrollIntoView={false}
      />
    </>
  ) : (
    <>
      <Select
        {...rest}
        options={classTypes}
        onChange={handleChange}
        value={selectedValue ? classTypes.find((q) => q.value === selectedValue) : null}
        classNamePrefix='react-select'
        isMulti={false}
        className={clsx('react-select', className)}
        isClearable={allowClear}
        isSearchable={false}
        styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
        menuPortalTarget={document.body}
        menuShouldScrollIntoView={false}
      />
    </>
  )
}

export {ClassTypeSelect}
