// const BlockLoading = () => {
//   const styles = {
//     borderRadius: '0.475rem',
//     boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
//     backgroundColor: '#fff',
//     color: '#7e8299',
//     fontWeight: '500',
//     margin: '0',
//     width: 'auto',
//     padding: '1rem 2rem',
//     top: 'calc(50% - 2rem)',
//     left: 'calc(50% - 4rem)',
//     zIndex: 999
//   }

//   return <div style={{...styles, position: 'absolute', textAlign: 'center'}}>Processing...</div>
// }

const BlockLoading = ({fullBlocking = false}) => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
    zIndex: 999,
  }

  return (
    <>
      {fullBlocking ? (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: 999,
            backgroundColor: 'rgba(255,255,255,0.25)'
          }}
        >
          <div style={{...styles}}>Processing...</div>
        </div>
      ) : (
        <div style={{...styles, position: 'absolute', textAlign: 'center'}}>Processing...</div>
      )}
    </>
  )
}

export default BlockLoading
