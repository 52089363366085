import axios from 'axios'
import {IBaseResponse} from '../../../../auth'
import {MeetAttendanceType} from './_model'

const API_URL = process.env.REACT_APP_BASE_API_URL
const URL = `${API_URL}/reports/meet-attendance`

const getMeetAttendance = async (query: string): Promise<IBaseResponse<MeetAttendanceType[]>> => {
  const response = await axios.get(`${URL}?${query}`)
  return response.data
}

export {getMeetAttendance}
