import AppPagination from '../../../../../components/Pagination'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponseLoading, useQueryResponsePagination } from '../../core/QueryResponseProvider'

const ClassListPagination = ({ totalPages, currentPage, onPageChange }) => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const { updateState } = useQueryRequest()
  const updatePage = (page: number | undefined | null) => {
    if (!page || isLoading || pagination.pageNumber === page) {
      return
    }
    const newState = { pageNumber: page, page, pageSize: pagination.pageSize || 10 }
    updateState(newState)
  }


  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_class_paginate'>
          <AppPagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={updatePage}
          />
        </div>
      </div>
    </div>
  )
}

export { ClassListPagination }
