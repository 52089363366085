import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {DownloadRequest, DownloadRequestQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_BASE_API_URL + '/downloadRequests'

const getDownloadRequest = (query: string): Promise<DownloadRequestQueryResponse> => {
  return axios
    .get(`${API_URL}?${query}`)
    .then((d: AxiosResponse<DownloadRequestQueryResponse>) => d.data)
}

const getDownloadRequestById = (id: ID): Promise<DownloadRequest | undefined> => {
  return axios
    .get(`${API_URL}/${id}`)
    .then((response: AxiosResponse<Response<DownloadRequest>>) => response.data)
    .then((response: Response<DownloadRequest>) => response.data)
}

const createDownloadRequest = (data: DownloadRequest): Promise<DownloadRequest | undefined> => {
  return axios
    .post(API_URL, data)
    .then((response: AxiosResponse<Response<DownloadRequest>>) => response.data)
    .then((response: Response<DownloadRequest>) => response.data)
}

const updateDownloadRequest = (data: DownloadRequest): Promise<DownloadRequest | undefined> => {
  return axios
    .put(`${API_URL}/${data.id}`, data)
    .then((response: AxiosResponse<Response<DownloadRequest>>) => response.data)
    .then((response: Response<DownloadRequest>) => response.data)
}

const deleteDownloadRequest = (id: ID): Promise<void> => {
  return axios.delete(`${API_URL}/${id}`).then(() => {})
}

const approveDownloadRequest = (id: ID, data: any): Promise<void> => {
  return axios.put(`${API_URL}/approve/${id}`, data).then(() => {})
}

const rejectDownloadRequest = (id: ID, data: any): Promise<void> => {
  return axios.put(`${API_URL}/reject/${id}`, data).then(() => {})
}

export {
  getDownloadRequest,
  deleteDownloadRequest,
  getDownloadRequestById,
  createDownloadRequest,
  updateDownloadRequest,
  approveDownloadRequest,
  rejectDownloadRequest,
}
