import {KTCard} from '../../../../../_metronic/helpers'
import {LecturerNotAttendHeader} from './components/header/LecturerNotAttendHeader'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {LecturerNotAttendTable} from './table/LecturerNotAttendTable'

const LecturerNotAttend = () => {
  return (
    <>
      <div className='d-flex flex-row justify-content-between mb-8'>
        <h1 className='page-heading text-dark fw-bold fs-3 my-0'>Report Lecturer Not Attend</h1>
      </div>
      <KTCard>
        <LecturerNotAttendHeader />
        <LecturerNotAttendTable />
      </KTCard>
    </>
  )
}

const LecturerNotAttendWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <LecturerNotAttend />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {LecturerNotAttendWrapper}
