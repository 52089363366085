import {toast, Bounce} from 'react-toastify'

const showError = (message: string, duration?: number) => {
  toast.error(message, {
    position: 'top-center',
    autoClose: duration ?? 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: 'dark',
    transition: Bounce,
  })
}

const showInfo = (message: string) => {
  toast.info(message, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: 'dark',
    transition: Bounce,
  })
}

const showWarning = (message: string) => {
  toast.warning(message, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: 'dark',
    transition: Bounce,
  })
}

const showSuccess = (message: string, duration?: number) => {
  if (!duration) {
    duration = 5000
  }

  toast.success(message, {
    position: 'top-center',
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: 'dark',
    transition: Bounce,
  })
}

export {showError, showSuccess, showWarning, showInfo}
