import {IAttendanceChart, IAttendanceDetail} from './../../../models/IAttendanceChart'
import axios from 'axios'
import {IBaseResponse} from '../../auth'
import {ActiveStudent, Classes, TodayLecturerMeet, TodoList, UpcomingClass} from './_model'
import {IAnnouncement} from '../../../models/IAnnouncement'
import {MyCourseModel} from '../../profile/core/_model'
import {IAverageGPAChart} from '../../../models/IAverageGPAChart'
import {IClassMeet} from '../../../models/IClassMeet'
import {ISurveySummaryChart, ISurveySummaryDetail} from '../../../models/ISurveySummaryChart'
import {IAverageScoreChart, IAverageScoreDetail} from '../../../models/IAverageScoreChart'
const API_URL = process.env.REACT_APP_BASE_API_URL

export const GET_DASHBOARD = `${API_URL}/dashboards`
export const GET_ANNOUNCEMENT = `${API_URL}/announcements`
export const GET_CLASS_MEETS = `${API_URL}/class-meets`

const getActiveStudentCount = async (): Promise<IBaseResponse<ActiveStudent[]>> => {
  const url = GET_DASHBOARD + '/active-student'
  const response = await axios.get<IBaseResponse<ActiveStudent[]>>(url)
  return response.data
}

const getTodoList = async (): Promise<IBaseResponse<TodoList>> => {
  const url = GET_DASHBOARD + '/todo-list'
  const response = await axios.get<IBaseResponse<TodoList>>(url)
  return response.data
}

const getUpcomingClass = async (): Promise<IBaseResponse<UpcomingClass[]>> => {
  const url = GET_DASHBOARD + '/upcoming-class'
  const response = await axios.get<IBaseResponse<UpcomingClass[]>>(url)
  return response.data
}

const getTodayMeets = async (query: string): Promise<IBaseResponse<UpcomingClass[]>> => {
  const url = GET_DASHBOARD + '/today-meets' + `?${query}`
  const response = await axios.get<IBaseResponse<UpcomingClass[]>>(url)
  return response.data
}

const getLecturerTodayMeets = async (
  query: string
): Promise<IBaseResponse<TodayLecturerMeet[]>> => {
  const url = GET_DASHBOARD + '/lecturer-today-meets' + `?${query}`
  const response = await axios.get<IBaseResponse<TodayLecturerMeet[]>>(url)
  return response.data
}

const getClasses = async (): Promise<IBaseResponse<MyCourseModel[]>> => {
  const url = GET_DASHBOARD + '/my-course'
  const response = await axios.get<IBaseResponse<MyCourseModel[]>>(url)
  return response.data
}

const getLatestAnnouncement = async (): Promise<IBaseResponse<IAnnouncement[]>> => {
  const response = await axios.get(`${GET_ANNOUNCEMENT}/latest`)
  return response.data
}
const getActiveAnnouncement = async (): Promise<IBaseResponse<IAnnouncement[]>> => {
  const response = await axios.get(`${GET_ANNOUNCEMENT}/active`)
  return response.data
}
const getAttendances = async (param: string): Promise<IBaseResponse<IAttendanceChart[]>> => {
  const response = await axios.get(`${GET_DASHBOARD}/attendances-chart?${param}`)
  return response.data
}
const getLecturerAttendances = async (param: string): Promise<IBaseResponse<IAttendanceChart[]>> => {
  const response = await axios.get(`${GET_DASHBOARD}/lecturer-attendances-chart?${param}`)
  return response.data
}
const getAverageGPA = async (param: string): Promise<IBaseResponse<IAverageGPAChart[]>> => {
  const response = await axios.get(`${GET_DASHBOARD}/average-gpa-chart?${param}`)
  return response.data
}

const attendByQr = async (
  classMeetId: number,
  token: string,
  meetId?: number
): Promise<IBaseResponse<any>> => {
  const response = await axios.post(`${GET_CLASS_MEETS}/attend-qr/${classMeetId}`, {
    token,
    classMeetId: meetId,
  })
  return response.data
}

const startMeeting = async (classMeetId: number): Promise<IBaseResponse<any>> => {
  const response = await axios.put(`${GET_CLASS_MEETS}/start/${classMeetId}`)
  return response.data
}

const endMeeting = async (classMeetId: number): Promise<IBaseResponse<any>> => {
  const response = await axios.put(`${GET_CLASS_MEETS}/end/${classMeetId}`)
  return response.data
}

const getTodayClassMeets = async (
  id: number,
  query: string
): Promise<IBaseResponse<IClassMeet[]>> => {
  const url = GET_CLASS_MEETS + '/today-meet' + `/${id}?${query}`
  const response = await axios.get<IBaseResponse<any[]>>(url)
  return response.data
}

const getSurveySummary = async (param: string): Promise<IBaseResponse<ISurveySummaryChart[]>> => {
  const response = await axios.get(`${GET_DASHBOARD}/survey-summary-chart?${param}`)
  return response.data
}

const getCoursesScore = async (param: string): Promise<IBaseResponse<IAverageScoreChart[]>> => {
  const response = await axios.get(`${GET_DASHBOARD}/courses-score-chart?${param}`)
  return response.data
}

const getStudentAttendanceDetail = async (
  id: number,
  param: string
): Promise<IBaseResponse<IAttendanceDetail[]>> => {
  const response = await axios.get(`${GET_DASHBOARD}/attendances-detail/${id}?${param}`)
  return response.data
}

const getLecturerAttendanceDetail = async (
  id: number,
  param: string
): Promise<IBaseResponse<IAttendanceDetail[]>> => {
  const response = await axios.get(`${GET_DASHBOARD}/lecturer-attendances-detail/${id}?${param}`)
  return response.data
}

const geCourseScoreDetail = async (id: number): Promise<IBaseResponse<IAverageScoreDetail[]>> => {
  const response = await axios.get(`${GET_DASHBOARD}/courses-score-detail/${id}`)
  return response.data
}

const geSurveySummaryDetail = async (
  id: number,
  param: string
): Promise<IBaseResponse<ISurveySummaryDetail[]>> => {
  const response = await axios.get(`${GET_DASHBOARD}/survey-summary-detail/${id}?${param}`)
  return response.data
}


export {
  getActiveStudentCount,
  getTodoList,
  getUpcomingClass,
  getClasses,
  getLatestAnnouncement,
  getActiveAnnouncement,
  getTodayMeets,
  getLecturerTodayMeets,
  getAttendances,
  getAverageGPA,
  attendByQr,
  startMeeting,
  endMeeting,
  getTodayClassMeets,
  getSurveySummary,
  getCoursesScore,
  getStudentAttendanceDetail,
  geCourseScoreDetail,
  geSurveySummaryDetail,
  getLecturerAttendances,
  getLecturerAttendanceDetail,
}
