import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {GroupClass, GroupClassQueryResponse, StudentMajor, StudentMajorQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_BASE_API_URL
const STUDENT_URL = `${API_URL}/student-majors`
const GET_STUDENT_URL = `${API_URL}/student-majors`
const GET_GROUP_CLASS_URL = `${API_URL}/group-classes`

const getStudentMajor = (query: string): Promise<StudentMajorQueryResponse> => {
  return axios
    .get(`${GET_STUDENT_URL}?${query}`)
    .then((d: AxiosResponse<StudentMajorQueryResponse>) => d.data)
}

const getStudentMajorById = (id: ID): Promise<StudentMajor | undefined> => {
  return axios
    .get(`${STUDENT_URL}/${id}`)
    .then((response: AxiosResponse<Response<StudentMajor>>) => response.data)
    .then((response: Response<StudentMajor>) => response.data)
}

const createStudentMajor = (student: StudentMajor): Promise<StudentMajor | undefined> => {
  return axios
    .post(STUDENT_URL, student)
    .then((response: AxiosResponse<Response<StudentMajor>>) => response.data)
    .then((response: Response<StudentMajor>) => response.data)
}

const updateStudentMajor = (student: StudentMajor): Promise<StudentMajor | undefined> => {
  return axios
    .put(`${STUDENT_URL}/${student.id}`, student)
    .then((response: AxiosResponse<Response<StudentMajor>>) => response.data)
    .then((response: Response<StudentMajor>) => response.data)
}

const deleteStudentMajor = (studentId: ID): Promise<void> => {
  return axios.delete(`${STUDENT_URL}/${studentId}`).then(() => {})
}

const deleteSelectedStudentMajor = (studentIds: Array<ID>): Promise<void> => {
  const requests = studentIds.map((id) => axios.delete(`${STUDENT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const activateStudentMajor = (id: ID): Promise<StudentMajor | undefined> => {
  return axios
    .put(`${STUDENT_URL}/activate/${id}`)
    .then((response: AxiosResponse<Response<StudentMajor>>) => response.data)
    .then((response: Response<StudentMajor>) => response.data)
}

const inactivateStudentMajor = (id: ID): Promise<StudentMajor | undefined> => {
  return axios
    .put(`${STUDENT_URL}/inactivate/${id}`)
    .then((response: AxiosResponse<Response<StudentMajor>>) => response.data)
    .then((response: Response<StudentMajor>) => response.data)
}
const getStudentMajorSelect = (query: string, excludeIds: any): Promise<StudentMajorQueryResponse> => {
  return axios
    .post(`${GET_STUDENT_URL}/select?${query}`, excludeIds)
    .then((d: AxiosResponse<StudentMajorQueryResponse>) => d.data)
}
const getGroupClassSelect = (query: string): Promise<GroupClassQueryResponse> => {
  return axios
    .get(`${GET_GROUP_CLASS_URL}/select?${query}`)
    .then((d: AxiosResponse<GroupClassQueryResponse>) => d.data)
}
const createGroupClass = (model: GroupClass): Promise<GroupClass|undefined> => {
  return axios
    .post(GET_GROUP_CLASS_URL, model)
    .then((d: AxiosResponse<Response<GroupClass>>) => d.data)
    .then((d: Response<GroupClass>) => d.data)
}
const getStudentMajorSemester = (query: string): Promise<StudentMajorQueryResponse> => {
  return axios
    .get(`${GET_STUDENT_URL}/semester/select?${query}`)
    .then((d: AxiosResponse<StudentMajorQueryResponse>) => d.data)
}
export {
  getStudentMajor,
  deleteStudentMajor,
  deleteSelectedStudentMajor,
  getStudentMajorById,
  createStudentMajor,
  updateStudentMajor,
  activateStudentMajor,
  inactivateStudentMajor,
  getStudentMajorSelect,
  getGroupClassSelect,
  createGroupClass,
  getStudentMajorSemester
}
