import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import OtherReportTab from './components/OtherReportTab'
import {ClassmeetSemesterListWrapper} from './classmeet-semester-list/ClassmeetSemesterList'
import {MeetAttendanceListWrapper} from './meet-attendance-list/MeetAttendanceList'

const OtherReportPage = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <OtherReportTab />
            <Outlet />
          </>
        }
      >
        <Route index element={<Navigate to='/reports/others/meet-attendance' />} />
        <Route path='meet-attendance' element={<MeetAttendanceListWrapper />} />
        <Route path='classmeet-semester' element={<ClassmeetSemesterListWrapper />} />
      </Route>
    </Routes>
  )
}

export default OtherReportPage
