import {Response} from '../../../../../_metronic/helpers'
import {Paging} from '../../../../models/Response'

export type DownloadRequest = {
  id?: number
  typeId?: number
  fileId?: number
  requestedBy?: number
  requestedAt?: string
  statusId?: number
  statusNote?: string
  statusBy?: number
  link?: string
  expiredTime?: Date
  downloadCount?: number
  statusAt?: Date
  requestedByName?: string
  statusName?: string
  fileName?: string
  downloadLink?: string
  referenceName?: string
  allowDownload?: boolean
  allowRequest?: boolean
  fileStatusId?: number
  fileStatusName?: string
}

export enum DownloadRequestStatus {
  requested = 10,
  rejected = 20,
  approved = 30,
}

export enum DownloadRequestType {
  bibliography = 1,
}

export type DownloadRequestQueryResponse = Response<Paging<DownloadRequest>>

export const initialDownloadRequest: DownloadRequest = {
  statusId: DownloadRequestStatus.requested,
  typeId: DownloadRequestType.bibliography,
  statusNote: '',
  downloadCount: 0,
}
