import {useEffect, useMemo, useState} from 'react'
import {KTIcon, stringifyRequestQuery} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {showError} from '../../../../../../utils/toast'
import {downloadFile} from '../../../../../../utils/file'
import {SurveyResultFilterModal} from '../survey-result-filter-modal/SurveyResultFilterModal'
import LoadingButtonWrapper from '../../../../../../components/LoadingButtonWrapper'

const SurveyResultToolbar = () => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const [loadingExcel, setLoadingExcel] = useState<boolean>(false)
  const [loadingPdf, setLoadingPdf] = useState<boolean>(false)
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const [showExportAll, setShowExportAll] = useState<boolean>(false)

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const url = 'reports/survey-result'

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const excel = async () => {
    try {
      setLoadingExcel(true)
      await downloadFile(`${url}/excel?${query}`, 'SurveyResult.xlsx')
    } catch (err: any) {
      showError(err.message)
    } finally {
      setLoadingExcel(false)
    }
  }

  const pdf = async () => {
    try {
      setLoadingPdf(true)
      await downloadFile(`${url}/pdf?${query}`, 'SurveyResult.pdf')
    } catch (err: any) {
      showError(err.message)
    } finally {
      setLoadingPdf(false)
    }
  }

  const handleCloseFilterExportAll = () => setShowExportAll(false)
  const handleConfirmFilterExportAll = async () => {
    // Perform your action here after confirmation
    setShowExportAll(false)
  }

  return (
    <>
      <div className='d-flex justify-content-end' data-kt-student-table-toolbar='base'>
        <LoadingButtonWrapper isLoading={loadingExcel}>
          <button
            type='button'
            className='btn btn-light-success me-3'
            onClick={() => excel()}
            disabled={loadingExcel}
          >
            <KTIcon iconName='exit-up' className='fs-2' />
            Export
          </button>
        </LoadingButtonWrapper>

        <button
          type='button'
          className='btn btn-light-success me-3'
          onClick={() => setShowExportAll(true)}
        >
          <KTIcon iconName='exit-up' className='fs-2' />
          Export All
        </button>

        <LoadingButtonWrapper isLoading={loadingPdf}>
          <button
            type='button'
            className='btn btn-light-danger me-3'
            onClick={() => pdf()}
            disabled={loadingPdf}
          >
            <KTIcon iconName='file' className='fs-2' />
            PDF
          </button>
        </LoadingButtonWrapper>
      </div>
      <SurveyResultFilterModal
        show={showExportAll}
        handleClose={handleCloseFilterExportAll}
        handleConfirm={handleConfirmFilterExportAll}
      />
    </>
  )
}

export {SurveyResultToolbar}
