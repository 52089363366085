import clsx from 'clsx'
import {useFormik} from 'formik'
import moment from 'moment'
import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import DatePicker from '../../../../components/DatePicker'
import {IClassMeet, initialClassMeet} from '../../../../models/IClassMeet'
import {MenuModel, getMenuAccess, useAuth} from '../../../auth'
import LecturerSelect from '../../../lecturers/components/LecturerSelect'
import RoomSelect from '../../../rooms/components/RoomSelect'
import {ClassListLoading} from '../../class-list/components/loading/ClassListLoading'
import {createClassMeet, updateClassMeet} from '../../class-list/core/_requests'
import {MeetConfirmModal} from './ClassFormMeetConfirm/MeetConfirmModal'
import ClassLecturerSelect from '../../../lecturers/components/ClassLecturerSelect'
import PreventAutoComplete from '../../../../components/PreventAutoComplete'
import LoadingButtonWrapper from '../../../../components/LoadingButtonWrapper'
import './../../../../components/DatePicker.css'

type Props = {
  id: number
  show: boolean
  data: IClassMeet | null
  onClose(data: any): void
  onlyOnsite?: boolean
  showLecturer?: boolean
}

let yupValidation = {
  typeId: Yup.number().required('Type is required'),
  startDate: Yup.string().required('Start date is required'),
  description: Yup.string()
    .required('Description is required')
    .max(2000, 'Max description is 2000 char'),
  duration: Yup.number().required('Duration is required').min(10, 'Min duration is 10 minutes'),
  classLecturers: Yup.array().min(1, 'Lecturer is Required').required('Lecturer is Required'),
}

const ClassFormMeetModal: FC<Props> = ({
  id,
  show,
  data,
  onClose,
  onlyOnsite = false,
  showLecturer = false,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [classMeet, setClassMeetForEdit] = useState<IClassMeet>({...initialClassMeet})
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [confirmShow, setConfirmShow] = useState(false)
  const [backDateWarning, setBackDateWarning] = useState(false)
  const [endSoonWarning, setEndSoonWarning] = useState(false)
  const [isInputLink, setIsInputLink] = useState(false)
  const [isNeedSurvey, setIsNeedSurvey] = useState(true)
  const [isBlastEmail, setIsBlastEmail] = useState(false)
  const {pathname} = useLocation()
  const {currentMenu} = useAuth()
  const [menuAccess, setMenuAccess] = useState<MenuModel | undefined>(undefined)
  const [actionReason, setActionReason] = useState<string>()
  useEffect(() => {
    setMenuAccess(getMenuAccess(currentMenu ?? [], pathname))
  }, [currentMenu])

  const addClassMeetSchema = Yup.object().shape({
    ...yupValidation,
    // lecturerId: Yup.number().when('typeId', {
    //   is: (value) => {
    //     if (!showLecturer) {
    //       return false
    //     }
    //     return value == 2
    //   },
    //   then: () => Yup.number().min(1, 'Lecturer is required'),
    // }),
    link: Yup.string().when('isInputLink', {
      is: () => {
        return isInputLink
      },
      then: () => Yup.string().required('Link is required'),
    }),
    zoomId: Yup.string().when('isInputLink', {
      is: () => {
        return isInputLink
      },
      then: () => Yup.string().required('Meet Id is required'),
    }),
  })
  const editClassMeetSchema = Yup.object().shape({...yupValidation})

  useEffect(() => {
    formik.resetForm()
    setClassMeetForEdit({
      id: data?.id || initialClassMeet.id,
      classId: data?.classId || initialClassMeet.classId,
      description: data?.description || initialClassMeet.description,
      duration: data?.duration || initialClassMeet.duration,
      roomId: data?.roomId || initialClassMeet.roomId,
      startDate: data?.startDate || initialClassMeet.startDate,
      typeId: data?.typeId || initialClassMeet.typeId,
      typeName: data?.typeName || initialClassMeet.typeName,
      zoomId: data?.zoomId || initialClassMeet.zoomId,
      passCode: data?.passCode || initialClassMeet.passCode,
      isSyncAttendance: data?.isSyncAttendance ?? initialClassMeet.isSyncAttendance,
      lecturerId: data?.lecturerId || initialClassMeet.lecturerId,
      categoryId: data?.categoryId || initialClassMeet.categoryId,
      isInputLink: data?.isInputLink ?? initialClassMeet.isInputLink,
      link: data?.link || initialClassMeet.link,
      isNeedSurvey: data?.isNeedSurvey ?? initialClassMeet.isNeedSurvey,
      isBlastEmail: data?.isBlastEmail ?? initialClassMeet.isBlastEmail,
      classLecturers:
        data?.classLecturers?.map((m) => ({
          value: m.classLecturerId,
          label: m.lecturerName,
        })) || [],
      lecturerName: data?.lecturerName || initialClassMeet.lecturerName,
    })
    setIsInputLink(data?.isInputLink ?? false)
    setIsNeedSurvey(data?.isNeedSurvey ?? true)
    setIsBlastEmail(data?.isBlastEmail ?? false)
  }, [show])

  const formik = useFormik({
    initialValues: classMeet,
    enableReinitialize: true,
    validationSchema: isNotEmpty(classMeet?.id) ? editClassMeetSchema : addClassMeetSchema,
    onSubmit: async (values, formikHelpers) => {
      checkBackDate()
    },
  })

  const submit = async () => {
    setConfirmShow(false)
    setBackDateWarning(false)
    setEndSoonWarning(false)
    formik.setStatus(null)
    let models = {...formik.values, classId: id}
    if (models?.typeId == 2) {
      models.roomId = null
    }
    models.isInputLink = isInputLink
    models.isNeedSurvey = isNeedSurvey
    models.isBlastEmail = isBlastEmail
    models.classLecturers = models.classLecturers?.map((m) => ({
      classLecturerId: m.value,
    }))
    formik.setSubmitting(true)

    let result: any
    try {
      if (isNotEmpty(classMeet?.id)) {
        models.actionReason = actionReason
        setActionReason(undefined)
        result = await updateClassMeet(models)
      } else {
        result = await createClassMeet(models)
      }
      if (result) {
        formik.resetForm()
        onClose(result)
        formik.setSubmitting(true)
      } else {
        formik.setStatus('Error')
      }
    } catch (ex: any) {
      console.error(ex)
      formik.setStatus(ex.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    onClose(null)
  }

  const cancel = (withRefresh?: boolean) => {
    onClose(null)
  }

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  const checkBackDate = () => {
    setIsLoading(true)

    //check is back date
    const startDate = moment(formik.values.startDate)
    const now = moment()
    const nowAdd15 = moment().add(15, 'minutes')
    const finishDate = moment(formik.values.startDate).add(formik.values.duration, 'minutes')
    let isBackDate = false
    let willEndSoon = false

    if (startDate.isBefore(now)) {
      isBackDate = true
      setBackDateWarning(true)

      if (isNotEmpty(classMeet?.id) && data?.startDate == formik.values.startDate) {
        isBackDate = false
        setBackDateWarning(false)
      }
    }

    if (finishDate.isBefore(nowAdd15) && finishDate.isAfter(now)) {
      willEndSoon = true
      setEndSoonWarning(true)

      if (isNotEmpty(classMeet?.id) && data?.duration == formik.values.duration) {
        willEndSoon = false
        setEndSoonWarning(false)
      }
    }

    if (isBackDate || willEndSoon || id) {
      setConfirmShow(true)
    } else {
      submit()
    }
  }

  const handleCloseConfirm = () => {
    setIsLoading(false)
    setConfirmShow(false)
    setBackDateWarning(false)
    setEndSoonWarning(false)
  }

  const toggleIsInputLink = () => {
    const inputLink = !isInputLink
    setIsInputLink(inputLink)
    if (!inputLink && !formik.values.id) {
      formik.setFieldValue('zoomId', '')
      formik.setFieldValue('link', '')
    }
  }

  const toggleIsNeedSurvey = () => {
    setIsNeedSurvey((value) => !value)
  }

  const toggleIsBlastEmail = () => {
    setIsBlastEmail((value) => !value)
  }

  const disableStartDate = (): boolean => {
    return formik.values.id !== undefined && isBlastEmail
  }

  return (
    <>
      <Modal show={show && !confirmShow} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Add Meet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='p-4'>
            <form className='form' onSubmit={formik.handleSubmit} autoComplete='off' noValidate>
              <fieldset disabled={!menuAccess?.canCreate}>
                <PreventAutoComplete />
                <div className='d-flex flex-column scroll-y me-n7 pe-7' style={{maxHeight: 450}}>
                  {formik.status ? (
                    <div className='mb-lg-15 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className='row mb-7'>
                    <div className='col-12 col-lg-12 mb-7'>
                      <label className='required fw-bold fs-6 mb-4'>Category</label>
                      <div>
                        <div className='d-inline-flex form-check me-4'>
                          <input
                            {...formik.getFieldProps('categoryId')}
                            type='radio'
                            name='categoryId'
                            className='form-check-input'
                            value={1}
                            checked={formik.values.categoryId == 1}
                            disabled={
                              formik.isSubmitting || isLoading
                            }
                            id='learning'
                          />
                          <label className='form-check-label ms-2' htmlFor='learning'>
                            Learning
                          </label>
                        </div>
                        {!onlyOnsite && (
                          <>
                            <div className='d-inline-flex form-check me-4'>
                              <input
                                {...formik.getFieldProps('categoryId')}
                                type='radio'
                                name='categoryId'
                                className='form-check-input'
                                value={2}
                                checked={formik.values.categoryId == 2}
                                disabled={formik.isSubmitting || isLoading}
                                id='midexam'
                              />
                              <label className='form-check-label ms-2' htmlFor='midexam'>
                                Mid Exam
                              </label>
                            </div>
                            <div className='d-inline-flex form-check me-4'>
                              <input
                                {...formik.getFieldProps('categoryId')}
                                type='radio'
                                name='categoryId'
                                className='form-check-input'
                                value={3}
                                checked={formik.values.categoryId == 3}
                                disabled={formik.isSubmitting || isLoading}
                                id='finalexam'
                              />
                              <label className='form-check-label ms-2' htmlFor='finalexam'>
                                Final Exam
                              </label>
                            </div>
                            <div className='d-inline-flex form-check me-4'>
                              <input
                                {...formik.getFieldProps('categoryId')}
                                type='radio'
                                name='categoryId'
                                className='form-check-input'
                                value={4}
                                checked={formik.values.categoryId == 4}
                                disabled={formik.isSubmitting || isLoading}
                                id='interview'
                              />
                              <label className='form-check-label ms-2' htmlFor='interview'>
                                Interview
                              </label>
                            </div>
                          </>
                        )}
                        {formik.touched.categoryId && formik.errors.categoryId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.categoryId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-lg-12 mb-7'>
                      <label className='required fw-bold fs-6 mb-4'>Type</label>
                      <div>
                        <div className='d-inline-flex form-check me-4'>
                          <input
                            {...formik.getFieldProps('typeId')}
                            type='radio'
                            name='typeId'
                            className='form-check-input'
                            value={1}
                            checked={formik.values.typeId == 1}
                            disabled={
                              formik.isSubmitting || isLoading || formik.values.id !== undefined
                            }
                            id='onsite'
                          />
                          <label className='form-check-label ms-2' htmlFor='onsite'>
                            Offline
                          </label>
                        </div>
                        {!onlyOnsite && (
                          <>
                            <div className='d-inline-flex form-check me-4'>
                              <input
                                {...formik.getFieldProps('typeId')}
                                type='radio'
                                name='typeId'
                                className='form-check-input'
                                value={2}
                                checked={formik.values.typeId == 2}
                                disabled={
                                  formik.isSubmitting || isLoading || formik.values.id !== undefined
                                }
                                id='online'
                              />
                              <label className='form-check-label ms-2' htmlFor='online'>
                                Online
                              </label>
                            </div>

                            <div className='d-inline-flex form-check me-4'>
                              <input
                                type='checkbox'
                                name='isInputLink'
                                className='form-check-input'
                                onChange={toggleIsInputLink}
                                checked={isInputLink}
                                disabled={
                                  formik.isSubmitting || isLoading || formik.values.id !== undefined
                                }
                                id='isInputLink'
                              />
                              <label className='form-check-label ms-2' htmlFor='isInputLink'>
                                Input Link
                              </label>
                            </div>
                          </>
                        )}
                        {formik.touched.typeId && formik.errors.typeId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.typeId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-6 mb-4'>
                      <label className='required fw-bold fs-6 mb-4'>Survey</label>
                      <div>
                        <div className='d-inline-flex form-check me-4'>
                          <input
                            type='checkbox'
                            name='isNeedSurvey'
                            className='form-check-input'
                            onChange={toggleIsNeedSurvey}
                            checked={isNeedSurvey}
                            disabled={
                              formik.isSubmitting || isLoading || formik.values.id !== undefined
                            }
                            id='isNeedSurvey'
                          />
                          <label className='form-check-label ms-2' htmlFor='isNeedSurvey'>
                            Need Survey?
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='col-6 mb-4'>
                      <label className='fw-bold fs-6 mb-4'>Blast Email</label>
                      <div>
                        <div className='d-inline-flex form-check me-4'>
                          <input
                            type='checkbox'
                            name='isBlastEmail'
                            className='form-check-input'
                            onChange={toggleIsBlastEmail}
                            checked={isBlastEmail}
                            disabled={
                              formik.isSubmitting || isLoading || formik.values.id !== undefined
                            }
                            id='isBlastEmail'
                          />
                          <label className='form-check-label ms-2' htmlFor='isBlastEmail'>
                            Send Email?
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className={clsx('col-12', {
                        'col-lg-6 col-xl-6': formik.values.typeId == 1,
                        'col-lg-12 col-xl-12': formik.values.typeId != 1,
                      })}
                    >
                      <label className='fw-bold fs-6 mb-4 mt-2 required'>Lecturer</label>
                      <ClassLecturerSelect
                        field={formik.getFieldProps('classLecturers')}
                        form={formik}
                        initialOptions={data?.classLecturers?.map((m) => ({
                          value: m.classLecturerId,
                          label: m.lecturerName,
                        }))}
                        classId={id}
                        isMulti={true}
                      />
                      {formik.touched.classLecturers && formik.errors.classLecturers && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.classLecturers}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {formik.values.typeId == 1 && (
                      <div className='col-12 col-lg-6 col-xl-6'>
                        <label className='fw-bold fs-6 mb-4 mt-2'>Room</label>
                        <RoomSelect
                          initialOptions={
                            data && data.roomId ? [{value: data.roomId, label: data.roomName}] : []
                          }
                          className='menu-outer-top'
                          field={formik.getFieldProps('roomId')}
                          form={formik}
                          disabled={!menuAccess?.canCreate}
                        />
                        {formik.touched.roomId && formik.errors.roomId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.roomId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div className='row mb-12'>
                    <div className='col-12 col-lg-6'>
                      <label className='required fw-bold fs-6 mb-4 mt-2' style={{width: '100%'}}>
                        Start Date
                      </label>
                      <DatePicker
                        label='Select Date'
                        name='startDate'
                        form={formik}
                        field={formik.getFieldProps('startDate')}
                        dateFormat={onlyOnsite ? 'H:i' : 'Y-m-d H:i'}
                        labelFormat={onlyOnsite ? 'H:i' : 'd/m/Y H:i'}
                        enableTime={true}
                        options={{
                          noCalendar: onlyOnsite,
                        }}
                        value={formik.getFieldProps('startDate').value}
                        disabled={
                          formik.isSubmitting ||
                          isLoading ||
                          disableStartDate() ||
                          data?.isCreatedBySystem
                        }
                      />
                      {formik.touched.startDate && formik.errors.startDate && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.startDate}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-12 col-lg-6'>
                      <label className='required fw-bold fs-6 mb-4 mt-2'>Duration (minutes)</label>
                      <input
                        placeholder='Duration'
                        {...formik.getFieldProps('duration')}
                        type='number'
                        name='duration'
                        className={clsx(
                          'form-control mb-3 mb-lg-0',
                          {'is-invalid': formik.touched.duration && formik.errors.duration},
                          {
                            'is-valid': formik.touched.duration && !formik.errors.duration,
                          }
                        )}
                        autoComplete='off'
                        disabled={formik.isSubmitting || isLoading || data?.isCreatedBySystem}
                      />
                      {formik.touched.duration && formik.errors.duration && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.duration}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {isInputLink && (
                    <div className='row mb-12'>
                      <div className='col-12'>
                        <label className='fw-bold fs-6 mb-4 required'>Link</label>
                        <input
                          {...formik.getFieldProps('link')}
                          className='form-control'
                          type='text'
                          disabled={
                            formik.isSubmitting || isLoading || formik.values.id !== undefined
                          }
                        />
                        {formik.touched.link && formik.errors.link && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.link}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {(formik.values.typeId == 2 || isInputLink) && (
                    <>
                      <div className='row mb-12'>
                        <div className='col-12 col-lg-6'>
                          <label className='fw-bold fs-6 mb-4'>Pass Code</label>
                          <div className='position-relative'>
                            <input
                              {...formik.getFieldProps('passCode')}
                              className='form-control'
                              type={isPasswordVisible ? 'text' : 'password'}
                              disabled={
                                formik.isSubmitting || isLoading || formik.values.id !== undefined
                              }
                            />

                            <span
                              onClick={togglePasswordVisibility}
                              style={{
                                cursor: 'pointer',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                width: 30,
                              }}
                            >
                              <i
                                className={
                                  isPasswordVisible ? 'bi bi-eye-fill' : 'bi bi-eye-slash-fill'
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                        <div className='col-12 col-lg-6'>
                          <label
                            className={clsx('fw-bold fs-6 mb-4', {
                              required: isInputLink,
                            })}
                          >
                            Meet Id
                          </label>
                          <input
                            type='number'
                            {...formik.getFieldProps('zoomId')}
                            className='form-control'
                            disabled={
                              formik.isSubmitting ||
                              isLoading ||
                              formik.values.id !== undefined ||
                              !isInputLink
                            }
                          ></input>
                          {formik.touched.zoomId && formik.errors.zoomId && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.zoomId}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {/*{!isNotEmpty(classMeet?.id) && showLecturer && formik.values.typeId == 2 && (*/}
                      {/*  <div className='row mb-12'>*/}
                      {/*    <div className='col-12'>*/}
                      {/*      <label className='fw-bold fs-6 mb-4 required'>Lecturer</label>*/}
                      {/*      <LecturerSelect*/}
                      {/*        field={formik.getFieldProps('lecturerId')}*/}
                      {/*        form={formik}*/}
                      {/*        initialOptions={[]}*/}
                      {/*        classId={id}*/}
                      {/*      />*/}
                      {/*      {formik.touched.lecturerId && formik.errors.lecturerId && (*/}
                      {/*        <div className='fv-plugins-message-container'>*/}
                      {/*          <div className='fv-help-block'>*/}
                      {/*            <span role='alert'>{formik.errors.lecturerId}</span>*/}
                      {/*          </div>*/}
                      {/*        </div>*/}
                      {/*      )}*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*)}*/}
                    </>
                  )}
                  <div className='row mb-12'>
                    <div className='col-12 col-lg-12'>
                      <label className='fw-bold fs-6 mb-4 required'>Description</label>
                      <textarea
                        placeholder='Description'
                        {...formik.getFieldProps('description')}
                        rows={5}
                        name='description'
                        className={clsx(
                          'form-control mb-3 mb-lg-0',
                          {'is-invalid': formik.touched.description && formik.errors.description},
                          {
                            'is-valid': formik.touched.description && !formik.errors.description,
                          }
                        )}
                        autoComplete='off'
                        disabled={formik.isSubmitting || isLoading}
                      />
                      {formik.touched.description && formik.errors.description && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.description}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='text-end pt-15'>
                  {menuAccess?.canCreate && (
                    <>
                      <button
                        type='reset'
                        onClick={() => cancel()}
                        className='btn btn-light me-3'
                        data-kt-students-modal-action='cancel'
                        disabled={formik.isSubmitting || isLoading}
                      >
                        Discard
                      </button>

                      <LoadingButtonWrapper isLoading={formik.isSubmitting || isLoading}>
                        <button
                          type='submit'
                          className='btn btn-warning'
                          disabled={formik.isSubmitting || isLoading}
                        >
                          <span className='indicator-label'>Submit</span>
                        </button>
                      </LoadingButtonWrapper>
                    </>
                  )}
                </div>
                {/* end::Actions */}
              </fieldset>
            </form>
            {(formik.isSubmitting || isLoading) && <ClassListLoading />}
          </div>
        </Modal.Body>
      </Modal>

      <MeetConfirmModal
        show={confirmShow}
        handleClose={handleCloseConfirm}
        handleConfirm={submit}
        duration={formik.values.duration}
        startDate={formik.values.startDate}
        backDate={backDateWarning}
        endSoon={endSoonWarning}
        actionReason={actionReason}
        setActionReason={setActionReason}
        disableConfirm={(!actionReason || actionReason.length < 5)}
        id={data?.id}
      ></MeetConfirmModal>

      {/*<EndSoonConfirm*/}
      {/*  show={endSoonConfirmShow}*/}
      {/*  handleClose={handleCloseConfirm}*/}
      {/*  handleConfirm={submit}*/}
      {/*  duration={formik.values.duration}*/}
      {/*  startDate={formik.values.startDate}*/}
      {/*></EndSoonConfirm>*/}
    </>
  )
}

export {ClassFormMeetModal}
