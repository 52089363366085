import React, {FC, useEffect, useState} from 'react'
import {IClass} from '../../../../models/IClass'
import {Class, initialClass} from '../../class-list/core/_models'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import {createClass, updateClass} from '../../class-list/core/_requests'
import {Modal} from 'react-bootstrap'
import clsx from 'clsx'
import {ClassListLoading} from '../../class-list/components/loading/ClassListLoading'
import LoadingButtonWrapper from '../../../../components/LoadingButtonWrapper'

type Props = {
  show: boolean
  data?: Class
  onClose(reload: boolean): void
}

let yupValidation = {
  code: Yup.string()
    .min(1, 'Minimum 1 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Code is required'),
}

const schema = Yup.object().shape({
  ...yupValidation,
})

const ClassFormCodeModal: FC<Props> = ({show, data, onClose}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [model, setModel] = useState<Class>({...initialClass})

  const formik = useFormik({
    initialValues: model,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values, {setSubmitting, setStatus}) => {
      setStatus(null)
      const models = {...values}
      setSubmitting(true)
      setIsLoading(true)
      try {
        await updateClass(models)
        setSubmitting(true)
        onClose(true)
      } catch (ex: any) {
        console.error(ex)
        setStatus(ex.message)
      } finally {
        setIsLoading(false)
      }
    },
  })

  useEffect(() => {
    setModel({
      id: data?.id || initialClass.id,
      code: data?.code || initialClass.code,
      degreeId: data?.degreeId || initialClass.degreeId,
      majorId: data?.majorId || initialClass.majorId,
      courseId: data?.courseId || initialClass.courseId,
      name: data?.name ?? data?.courseName ?? '',
      isHideFromDashboard: data?.isHideFromDashboard || initialClass.isHideFromDashboard,
      hidePeopleTab: data?.hidePeopleTab || initialClass.hidePeopleTab
    })
  }, [data])

  const handleClose = () => {
    onClose(false)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Name & Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='px-10 py-8'>
            <form
              id='kt_modal_add_course_form'
              className='form'
              onSubmit={formik.handleSubmit}
              noValidate
            >
              {/* begin::Scroll */}
              <div className="d-flex flex-column me-n7 pe-7">
                {formik.status ? (
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                  </div>
                ) : (
                  ''
                )}

                <div className="col-12">
                  <div className="fv-row mb-7">
                    <label className="fw-bold fs-6 mb-2">Name</label>
                    <input
                      placeholder="Name"
                      {...formik.getFieldProps('name')}
                      type="text"
                      name="name"
                      className={clsx(
                        'form-control mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        },
                      )}
                      autoComplete="off"
                      disabled={formik.isSubmitting || isLoading}
                    />
                    {formik.touched.code && formik.errors.name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="fv-row mb-7">
                      <label className="required fw-bold fs-6 mb-2">Code</label>
                      <input
                        placeholder="Code"
                        {...formik.getFieldProps('code')}
                        type="text"
                        name="code"
                        className={clsx(
                          'form-control mb-3 mb-lg-0',
                          {'is-invalid': formik.touched.code && formik.errors.code},
                          {
                            'is-valid': formik.touched.code && !formik.errors.code,
                          },
                        )}
                        autoComplete="off"
                        disabled={formik.isSubmitting || isLoading}
                      />
                      {formik.touched.code && formik.errors.code && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.code}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="form-check form-check-custom form-check-solid mb-4">
                      <input
                        id="isHideFromDashboard"
                        className="form-check-input"
                        type="checkbox"
                        {...formik.getFieldProps('isHideFromDashboard')}
                        checked={formik.values.isHideFromDashboard}
                      />
                      <label htmlFor="isHideFromDashboard" className="ms-2 cursor-pointer fw-bold fs-6">
                        Hide from Dashboard
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="form-check form-check-custom form-check-solid mb-4">
                      <input
                        id="hidePeopleTab"
                        className="form-check-input"
                        type="checkbox"
                        {...formik.getFieldProps('hidePeopleTab')}
                        checked={formik.values.hidePeopleTab}
                      />
                      <label htmlFor="hidePeopleTab" className="ms-2 cursor-pointer fw-bold fs-6">
                        Hide People Tab
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-end pt-15">
                <LoadingButtonWrapper isLoading={formik.isSubmitting || isLoading}>
                  <button
                    type="submit"
                    className="btn btn-warning"
                    data-kt-courses-modal-action="submit"
                    disabled={formik.isSubmitting || isLoading}
                  >
                    <span className="indicator-label">Submit</span>
                  </button>
                </LoadingButtonWrapper>
              </div>
              {/* end::Actions */}
            </form>
            {(formik.isSubmitting || isLoading) && <ClassListLoading />}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ClassFormCodeModal
