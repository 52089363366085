import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import {initialClass, Class} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {ClassListLoading} from '../components/loading/ClassListLoading'
import {createClass, updateClass} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useNavigate, useParams} from 'react-router-dom'
import LecturerSelect from '../../../lecturers/components/LecturerSelect'
import LoadingButtonWrapper from '../../../../components/LoadingButtonWrapper'
import {getCourseByIdHeader} from '../../course-list/core/_requests'
import {showError} from '../../../../utils/toast'
import {getWordsWithBrackets, isContainsBrackets} from '../../../../utils/string'
import {ClassTypeSelect} from '../components/header/ClassTypeSelect'

type Props = {
  isLoading: boolean
  _class: Class
}

let yupValidation = {
  code: Yup.string()
    .min(1, 'Minimum 1 symbol')
    .max(50, 'Maximum 50 symbols')
    .required('Code is required'),
  primaryLecturerId: Yup.number(),
  name: Yup.string()
    .min(1, 'Minimum 1 symbol')
    .max(100, 'Maximum 100 symbols')
    .required('Name is required'),
}

const addClassSchema = Yup.object().shape({
  ...yupValidation,
})
const editClassSchema = Yup.object().shape(yupValidation)

function ClassEditModalForm({_class: data, isLoading, name}) {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  // const {id} = useParams()
  const id = +window.location.hash.replace('#', '')
  const navigate = useNavigate()
  const codeFormat = '[DEGREE][ANGKATAN].[SMSTR].KUL.[SUBJECT].[GROUPCLASS]'

  const [classForEdit] = useState<Class>({
    ...data,
    id: data.id || initialClass.id,
    code: data.code || initialClass.code,
    majorId: data.majorId || initialClass.majorId,
    primaryLecturerId: data.primaryLecturerId || initialClass.primaryLecturerId,
    secondaryLecturerId: data.secondaryLecturerId || initialClass.secondaryLecturerId,
    courseId: id,
    name: name,
    isHideFromDashboard: data.isHideFromDashboard || initialClass.isHideFromDashboard,
    hidePeopleTab: data.hidePeopleTab || initialClass.hidePeopleTab,
    typeId: data.typeId || initialClass.typeId,
  })

  const formik = useFormik({
    initialValues: classForEdit,
    validationSchema: isNotEmpty(data.id) ? editClassSchema : addClassSchema,
    onSubmit: async (values, {setSubmitting, setStatus}) => {
      if (isContainsBrackets(values.code)) {
        setStatus(
          `Invalid input: Please remove the default placeholder "${getWordsWithBrackets(
            values.code
          )}" and enter a valid class code.`
        )
        return
      }

      setStatus(null)

      const models = {...values}
      setSubmitting(true)
      try {
        if (isNotEmpty(data.id)) {
          await updateClass(models)
        } else {
          const res = await createClass(models)
          navigate('/courses/class/entry/' + res?.id)
        }
        setSubmitting(true)
        cancel(true)
      } catch (ex: any) {
        console.error(ex)
        setStatus(ex.message)
      } finally {
      }
    },
  })

  useEffect(() => {
    if (name) {
      formik.setFieldValue('name', name)
    }
  }, [])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  // useEffect(() => {
  //   if (id) {
  //     getCourseByIdHeader(id).then((resp) => {
  //       if (!formik.values.code) {
  //         let formattedCode = codeFormat
  //         formattedCode = formattedCode.replace('[DEGREE]', resp?.degreeName ?? '')
  //         formattedCode = formattedCode.replace('[SUBJECT]', resp?.subjectCode ?? '')

  //         formik.setFieldValue('code', formattedCode)
  //       }
  //     })
  //   }
  // }, [id])

  return (
    <>
      <form id='kt_modal_add_class_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div className='d-flex flex-column scroll-y me-n7 pe-7' style={{maxHeight: 450}}>
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            ''
          )}

          <div className='row'>
            <div className='col-12 col-lg-6'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Code</label>
                <input
                  placeholder='Code'
                  {...formik.getFieldProps('code')}
                  type='text'
                  name='code'
                  className={clsx(
                    'form-control mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.code && formik.errors.code},
                    {
                      'is-valid': formik.touched.code && !formik.errors.code,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isLoading}
                />
                <small>
                  Suggestion format :<br />
                  {codeFormat}
                </small>
                {formik.touched.code && formik.errors.code && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.code}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-12 col-lg-6'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Name</label>
                <input
                  placeholder='Name'
                  {...formik.getFieldProps('name')}
                  type='text'
                  name='name'
                  className={clsx(
                    'form-control mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isLoading}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <div className='col-12 col-lg-6'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Major</label>
                <MajorSelect
                  className='menu-outer-top'
                  field={formik.getFieldProps('majorId')}
                  form={formik}
                />
                {formik.touched.majorId && formik.errors.majorId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.majorId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div> */}
            <div className='col-12 col-lg-6'>
              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2'>Lecturer</label>
                <LecturerSelect
                  className='menu-outer-top'
                  initialOptions={
                    data && data.primaryLecturerId
                      ? [{value: data.primaryLecturerId, label: `${data.primaryLecturerName}`}]
                      : []
                  }
                  field={formik.getFieldProps('primaryLecturerId')}
                  courseId={id}
                  form={formik}
                />
              </div>
            </div>
            <div className='col-12 col-lg-6'>
              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2'>Type</label>
                <ClassTypeSelect
                  className='menu-outer-top'
                  allowClear={false}
                  initialOptions={
                    data && data.typeId ? [{value: data.typeId, label: `${data.typeName}`}] : []
                  }
                  field={formik.getFieldProps('typeId')}
                  form={formik}
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 col-lg-6'>
              <div className='form-check form-check-custom form-check-solid mb-4'>
                <input
                  id='isHideFromDashboard'
                  className='form-check-input'
                  type='checkbox'
                  {...formik.getFieldProps('isHideFromDashboard')}
                  checked={formik.values.isHideFromDashboard}
                />
                <label htmlFor='isHideFromDashboard' className='ms-2 cursor-pointer fw-bold fs-6'>
                  Hide from Dashboard
                </label>
              </div>
            </div>
            <div className='col-12 col-lg-6'>
              <div className='form-check form-check-custom form-check-solid mb-4'>
                <input
                  id='hidePeopleTab'
                  className='form-check-input'
                  type='checkbox'
                  {...formik.getFieldProps('hidePeopleTab')}
                  checked={formik.values.hidePeopleTab}
                />
                <label htmlFor='hidePeopleTab' className='ms-2 cursor-pointer fw-bold fs-6'>
                  Hide People Tab
                </label>
              </div>
            </div>
          </div>
          {/*<div className='row'>*/}
          {/*  <div className='col-12 col-lg-6'>*/}
          {/*    <div className='fv-row mb-7'>*/}
          {/*      <label className='fw-bold fs-6 mb-2'>Secondary Lecture</label>*/}
          {/*      <LecturerSelect*/}
          {/*        className='menu-outer-top'*/}
          {/*        initialOptions={*/}
          {/*          data && data.primaryLecturerId*/}
          {/*            ? [*/}
          {/*                {*/}
          {/*                  value: data.secondaryLecturerId,*/}
          {/*                  label: `${data.secondaryLecturerName}`,*/}
          {/*                },*/}
          {/*              ]*/}
          {/*            : []*/}
          {/*        }*/}
          {/*        field={formik.getFieldProps('secondaryLecturerId')}*/}
          {/*        courseId={id}*/}
          {/*        form={formik}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>

        <div className='text-end pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-classes-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Discard
          </button>

          <LoadingButtonWrapper isLoading={formik.isSubmitting || isLoading}>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-classes-modal-action='submit'
              disabled={formik.isSubmitting || isLoading}
            >
              <span className='indicator-label'>Submit</span>
            </button>
          </LoadingButtonWrapper>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <ClassListLoading />}
    </>
  )
}

export {ClassEditModalForm}
