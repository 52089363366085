import {IBaseResponse} from '../../../../auth'
import axios, {AxiosResponse} from 'axios'
import {SurveyResult} from './_model'
import {Response} from '../../../../../../_metronic/helpers'
import {Paging} from '../../../../../models/Response'
import {ILecturer} from '../../../../../models/ILecturer'
import {IClass} from '../../../../../models/IClass'
import {IClassMeet} from '../../../../../models/IClassMeet'

const API_URL = process.env.REACT_APP_BASE_API_URL
const URL = `${API_URL}/reports/survey-result`
const GET_LECTURER_URL = `${API_URL}/lecturers`
const GET_CLASS_URL = `${API_URL}/classes/lov`
const GET_CLASS_MEET_URL = `${API_URL}/class-meets/lov`

const getSurveyResult = async (query: string): Promise<IBaseResponse<SurveyResult[]>> => {
  const response = await axios.get(`${URL}?${query}`)
  return response.data
}

const getLecturerSelect = (query: string): Promise<Response<Paging<ILecturer>>> => {
  return axios
    .get(`${GET_LECTURER_URL}/select?${query}`)
    .then((d: AxiosResponse<Response<Paging<ILecturer>>>) => d.data)
}

const getClassesSelect = (query: string): Promise<Response<Paging<IClass>>> => {
  return axios
    .get(`${GET_CLASS_URL}?${query}`)
    .then((d: AxiosResponse<Response<Paging<IClass>>>) => d.data)
}

const getClassMeetsSelect = (query: string): Promise<Response<Paging<IClassMeet>>> => {
  return axios
    .get(`${GET_CLASS_MEET_URL}?${query}`)
    .then((d: AxiosResponse<Response<Paging<IClassMeet>>>) => d.data)
}

export {getSurveyResult, getLecturerSelect, getClassesSelect, getClassMeetsSelect}
