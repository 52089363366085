import {Modal} from 'react-bootstrap'
import {FC, useState} from 'react'
import clsx from 'clsx'
import GroupClassSelect from '../../../students/student-major-modal/components/GroupClassSelect'
import {ID} from '../../../../../_metronic/helpers'
import MajorSelect from '../../../majors/components/MajorSelect'

type Props = {
  show: boolean
  semesterId: number
  majorId?: number
  majorName?: string
  degreeId: number
  // onClose(from?: 'group' | 'cohort' | 'major', groupId?: number, cohort?: number): void
  onClose(submit: boolean, groupId?: number, cohort?: number, majorId?: number): void
}

const StudentAddFromModal: FC<Props> = ({show, semesterId, majorId, majorName, degreeId, onClose}) => {
  // const [from, setFrom] = useState<'group' | 'cohort' | 'major'>('group')
  const [selectedMajorId, setSelectedMajorId] = useState<number>()
  const [groupId, setGroupId] = useState<number>()
  const [cohort, setCohort] = useState<number>()

  const handleAdd = () => {
    onClose(
      // from,
      true,
      groupId,
      cohort,
      majorId || selectedMajorId,
    )
    setGroupId(undefined)
    setCohort(undefined)
    setSelectedMajorId(undefined)
  }

  return (
    <>
      <Modal show={show} style={{marginTop: 24}} onHide={() => onClose(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Student From...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row g-5">
              {/*<div className="col-12">*/}
              {/*  <div className="btn-group w-100">*/}
              {/*    <button className={clsx('btn',*/}
              {/*      {'btn-warning': from === 'group'},*/}
              {/*      {'btn-light': from !== 'group'},*/}
              {/*    )} onClick={() => setFrom('group')}>*/}
              {/*      Group*/}
              {/*    </button>*/}
              {/*    <button className={clsx('btn',*/}
              {/*      {'btn-warning': from === 'cohort'},*/}
              {/*      {'btn-light': from !== 'cohort'},*/}
              {/*    )} onClick={() => setFrom('cohort')}>*/}
              {/*      Angkatan*/}
              {/*    </button>*/}
              {/*    <button className={clsx('btn',*/}
              {/*      {'btn-warning': from === 'major'},*/}
              {/*      {'btn-light': from !== 'major'},*/}
              {/*    )} onClick={() => setFrom('major')}>*/}
              {/*      Major*/}
              {/*    </button>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="col-12">
                <div className="form-group">
                  <label className="fw-bold fs-6 mb-2">Major</label>
                  {majorId && majorName ?
                    <input
                      placeholder="Major"
                      type="text"
                      name="major"
                      value={majorName}
                      className="form-control cursor-default w-100"
                      autoComplete="off"
                      readOnly={true}
                    /> :
                    <MajorSelect
                      isUseFormik={false}
                      selectedValue={majorId}
                      onChange={(selected) => setSelectedMajorId(selected?.value)}
                      className="w-100"
                      isClearable={true}
                      placeHolder="Major"
                      degreeId={degreeId}
                    />
                  }
                </div>
              </div>

              <div className="col-12">
                <div className="form-group">
                  <label className="fw-bold fs-6 mb-2">Angkatan</label>
                  <input
                    placeholder="Angkatan"
                    type="number"
                    name="cohort"
                    value={cohort || ''}
                    onChange={(e) => e.target.value ? setCohort(+e.target.value) : setCohort(0)}
                    className="form-control w-100"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="col-12">
                {/*{from === 'group' &&*/}
                  <div className="form-group">
                    <label className="fw-bold fs-6 mb-2">Class Group</label>
                    <GroupClassSelect
                      isUseFormik={false}
                      selectedValue={groupId}
                      className="w-100"
                      onChange={(q) => setGroupId(q?.value)}
                      degreeId={degreeId}
                      isClearable={true}
                    />
                  </div>
                {/*}*/}

                {/*{from === 'cohort' &&*/}
                {/*  <div className="form-group">*/}
                {/*    <label className="fw-bold fs-6 mb-2">Angkatan</label>*/}
                {/*    <input*/}
                {/*      placeholder="Angkatan"*/}
                {/*      type="number"*/}
                {/*      name="cohort"*/}
                {/*      value={cohort || ''}*/}
                {/*      onChange={(e) => e.target.value ? setCohort(+e.target.value) : setCohort(0)}*/}
                {/*      className='form-control mb-3 mb-lg-0'*/}
                {/*      autoComplete="off"*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*}*/}

                {/*{from === 'major' &&*/}
                {/*  <div className="form-group">*/}
                {/*    <label className="fw-bold fs-6 mb-2">Major</label>*/}
                {/*    <input*/}
                {/*      placeholder="Angkatan"*/}
                {/*      type="text"*/}
                {/*      name="major"*/}
                {/*      value={majorName}*/}
                {/*      className="form-control mb-3 mb-lg-0 cursor-default"*/}
                {/*      autoComplete="off"*/}
                {/*      readOnly={true}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*}*/}
              </div>

            </div>

            <div className="text-end pt-15">
              <button
                type="reset"
                onClick={() => onClose(false)}
                className="btn btn-light me-3">
                Discard
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleAdd}
              >
                Add
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default StudentAddFromModal
