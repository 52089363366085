/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {Link, useLocation} from 'react-router-dom'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '../../../../../../_metronic/helpers'
import {MenuModel, getMenuAccess, useAuth} from '../../../../auth'
import {ClassDeleteConfirm} from '../../class-delete-confirm/ClassDeleteConfirm'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteClass, reactiveClass} from '../../core/_requests'
import {Confirm} from '../../../../../components/Confirm'

type Props = {
  id: ID
  name: string
  statusId: ID
}

const ClassActionsCell: FC<Props> = ({id, name, statusId}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showReactiveConfirmation, setShowReactiveConfirmation] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const {pathname} = useLocation()
  const {currentMenu} = useAuth()
  const [menuAccess, setMenuAccess] = useState<MenuModel | undefined>(undefined)
  useEffect(() => {
    setMenuAccess(getMenuAccess(currentMenu ?? [], pathname))
  }, [currentMenu])

  const handleCloseConfirmation = () => setShowConfirmation(false)

  const handleConfirmAction = async () => {
    // Perform your action here after confirmation
    setIsLoading(true)
    try {
      await deleteItem.mutateAsync()
    } catch (err: any) {
      setError(err.message)
    }
  }

  const handleCloseReactiveConfirmation = () => setShowReactiveConfirmation(false)

  const handleConfirmReactive = async () => {
    // Perform your action here after confirmation
    setIsLoading(true)
    try {
      await reactiveItem.mutateAsync()
    } catch (err: any) {
      setError(err.message)
    }
  }

  const handleOpenConfirmation = () => {
    setError(null)
    setShowConfirmation(true)
  }

  const handleOpenReactiveConfirmation = () => {
    setError(null)
    setShowReactiveConfirmation(true)
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    // setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteClass(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      setIsLoading(false)
      handleCloseConfirmation()
      queryClient.invalidateQueries([`${QUERIES.COURSE_CLASS_LIST}-${query}`])
    },
    onError: (err: any) => {
      setError(err.message)
      setIsLoading(false)
    },
  })

  const reactiveItem = useMutation(() => reactiveClass(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      setIsLoading(false)
      handleCloseReactiveConfirmation()
      queryClient.invalidateQueries([`${QUERIES.COURSE_CLASS_LIST}-${query}`])
    },
    onError: (err: any) => {
      setError(err.message)
      setIsLoading(false)
    },
  })

  return (
    <>
      {/* <a
        href='#'
        className='btn btn-outline btn-outline-primary btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <Link to={`../class/entry/${id}`} className='menu-link px-3'>
            {menuAccess?.canCreate ? 'Edit' : 'View'}
          </Link>
        </div>

        {menuAccess?.canDelete && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-class-table-filter='delete_row'
              onClick={handleOpenConfirmation}
            >
              Delete
            </a>
          </div>
        )}
      </div> */}
      <Link
        to={`../class/entry/${id}`}
        className='btn btn-outline btn-outline-primary btn-active-light-primary btn-sm me-3'
      >
        {menuAccess?.canCreate ? 'Edit' : 'View'}
      </Link>
      {statusId === 1 && <button
        type="button"
        onClick={handleOpenConfirmation}
        className="btn btn-outline btn-outline-danger btn-active-light-primary btn-sm px-7"
      >
        Delete
      </button>}
      {statusId === 4 && <button
        type="button"
        onClick={handleOpenReactiveConfirmation}
        className="btn btn-outline btn-outline-danger btn-active-light-primary btn-sm"
      >
        Reactive
      </button>}
      <ClassDeleteConfirm
        show={showConfirmation}
        handleClose={handleCloseConfirmation}
        handleConfirm={handleConfirmAction}
        name={name}
        isLoading={isLoading}
        error={error}
      />
      <Confirm
        show={showReactiveConfirmation}
        handleClose={handleCloseReactiveConfirmation}
        handleConfirm={handleConfirmReactive}
        name="Confirm Reactive"
        body="Are you sure you want to reactive this data ?"
      />
    </>
  )
}

export {ClassActionsCell}
