import {Response} from '../../../../../_metronic/helpers'
import {IClassMeet} from '../../../../models/IClassMeet'
import {Paging} from '../../../../models/Response'
import {IClassSchedule} from '../../../../models/IClassSchedule'
import moment from 'moment/moment'

export type Class = {
  id: number | undefined
  name?: string
  code: string
  degreeId?: number
  courseId?: number
  majorId?: number
  semesterId?: number
  degreeName?: string
  majorName?: string
  courseName?: string
  semesterName?: string
  classSessions?: ClassSession[]
  classStudents?: ClassStudent[]
  classLecturers?: ClassLecturer[]
  classAssignments?: ClassAssignment[]
  classQuizzes?: ClassQuiz[]
  primaryLecturerId?: number
  primaryLecturerName?: string
  secondaryLecturerId?: number
  secondaryLecturerName?: string
  classMeets?: IClassMeet[]
  classSchedules?: IClassSchedule[]
  isHideFromDashboard?: boolean
  hidePeopleTab?: boolean
  typeId?: number
}

export type ClassQueryResponse = Response<Array<Class>>

export const initialClass: Class = {
  code: '',
  degreeId: undefined,
  courseId: undefined,
  majorId: undefined,
  semesterId: undefined,
  id: undefined,
  primaryLecturerId: undefined,
  secondaryLecturerId: undefined,
  isHideFromDashboard: false,
  hidePeopleTab: false,
  typeId: 1
}

export type ClassSession = {
  id?: number | undefined
  classId?: number | undefined
  courseSessionId?: number | undefined
  startDate?: string | undefined
  endDate?: string | undefined
  name?: string | undefined
}

export const initialClassSession: ClassSession = {
  classId: undefined,
  courseSessionId: undefined,
  startDate: '',
  endDate: '',
}

export type ClassStudent = {
  id?: number | undefined
  classId?: number
  studentMajorId?: number
  name?: string
  nim?: string
  email?: string
  state?: number
  allowDelete?: boolean
  isNew?: boolean
  guid?: string
  dirty?: boolean
}

export const initialClassStudent: ClassStudent = {
  classId: undefined,
  studentMajorId: undefined,
  name: '',
  nim: '',
  email: '',
  state: undefined,
  isNew: true,
  allowDelete: true,
}

export type ClassLecturer = {
  id?: number | undefined
  classId?: number
  lecturerId?: number
  name?: string
  nip?: string
  email?: string
  state?: number
  allowDelete?: boolean
  isNew?: boolean
  guid?: string
}

export const initialClassLecturer: ClassLecturer = {
  classId: undefined,
  lecturerId: undefined,
  name: '',
  nip: '',
  email: '',
  state: undefined,
  isNew: true,
  allowDelete: true,
}

export type ClassAssignment = {
  id?: number | undefined
  classId?: number | undefined
  courseAssignmentId?: number | undefined
  startDate?: string | undefined
  deadline?: string | undefined
  name?: string | undefined
}

export const initialClassAssignment: ClassAssignment = {
  classId: undefined,
  courseAssignmentId: undefined,
  startDate: '',
  deadline: '',
}

export type ClassQuiz = {
  id?: number | undefined
  classId?: number | undefined
  courseAssignmentId?: number | undefined
  startDate?: string | undefined
  endDate?: string | undefined
  deadline?: string | undefined
  name?: string | undefined
  autoCreateMeeting?: boolean | undefined
}

export const initialClassQuiz: ClassQuiz = {
  classId: undefined,
  courseAssignmentId: undefined,
  startDate: '',
  deadline: '',
}

// export type ClassMeet = {
//   id?: number | undefined
//   classId?: number | undefined
//   startDate?: string | undefined | Date
//   duration?: number | undefined
//   typeId?: number | undefined
//   typeName?: string | undefined
//   zoomId?: number | undefined
//   roomId?: number | null
//   roomName?: string | undefined
//   description?: string | undefined
// }

// export const initialClassMeet: ClassMeet = {
//   id: undefined,
//   classId: undefined,
//   startDate: '',
//   typeId: 1,
//   zoomId: undefined,
//   roomId: undefined,
//   description: '',
//   duration: 0,
//   roomName: '',
// }

export type ClassMeetQueryResponse = Response<Paging<IClassMeet>>
export type ClassScheduleQueryResponse = Response<Paging<IClassSchedule>>
