import {SpecialZoomLevel, Viewer, Worker} from '@react-pdf-viewer/core'
import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {getAuth} from '../modules/auth'
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css'
import {pageNavigationPlugin, RenderCurrentPageLabelProps} from '@react-pdf-viewer/page-navigation'
import {zoomPlugin} from '@react-pdf-viewer/zoom'
import {fullScreenPlugin} from '@react-pdf-viewer/full-screen'

type Prop = {
  show: boolean
  handleClose: any
  url: string
  name: string | null
  error?: any
}

const API_URL = process.env.REACT_APP_BASE_API_URL

const PreviewPdf: FC<Prop> = ({show, handleClose, name, url, error = null}) => {
  const auth = getAuth()

  // Create new plugin instance
  const zoomPluginInstance = zoomPlugin()
  const {ZoomInButton, ZoomOutButton, ZoomPopover} = zoomPluginInstance
  const pageNavigationPluginInstance = pageNavigationPlugin()
  const {
    CurrentPageInput,
    GoToFirstPageButton,
    GoToLastPageButton,
    GoToNextPageButton,
    GoToPreviousPage,
    CurrentPageLabel,
  } = pageNavigationPluginInstance
  const fullScreenPluginInstance = fullScreenPlugin()
  const {EnterFullScreenButton} = fullScreenPluginInstance

  return (
    <Modal show={show} onHide={handleClose} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>{name ?? 'Preview PDF'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className='rpv-core__viewer'
          style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              backgroundColor: '#eeeeee',
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              display: 'flex',
              justifyContent: 'center',
              padding: '4px',
            }}
          >
            <div style={{padding: '0px 2px'}}>
              <EnterFullScreenButton />
            </div>
            <div style={{padding: '0px 2px'}}>
              <ZoomOutButton />
            </div>
            <div style={{padding: '0px 2px'}}>
              <ZoomPopover />
            </div>
            <div style={{padding: '0px 2px'}}>
              <ZoomInButton />
            </div>
            <div style={{padding: '0px 2px'}}>
              <GoToFirstPageButton />
            </div>
            <div style={{padding: '0px 2px'}}>
              <GoToPreviousPage />
            </div>
            <div style={{padding: '0px 2px', width: 75}}>
              <CurrentPageInput />
            </div>
            <div style={{padding: '0px 2px'}}>
              <CurrentPageLabel>
                {(props: RenderCurrentPageLabelProps) => <span>{`of ${props.numberOfPages}`}</span>}
              </CurrentPageLabel>
            </div>
            <div style={{padding: '0px 2px'}}>
              <GoToNextPageButton />
            </div>
            <div style={{padding: '0px 2px'}}>
              <GoToLastPageButton />
            </div>
          </div>
          <div
            style={{
              flex: 1,
              overflow: 'hidden',
            }}
          >
            <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js'>
              <div style={{height: '750px'}}>
                <Viewer
                  fileUrl={`${API_URL}/${url}`}
                  plugins={[
                    zoomPluginInstance,
                    pageNavigationPluginInstance,
                    fullScreenPluginInstance,
                  ]}
                  httpHeaders={{Authorization: `Bearer ${auth?.api_token}`}}
                  defaultScale={SpecialZoomLevel.PageFit}
                />
              </div>
            </Worker>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export {PreviewPdf}
