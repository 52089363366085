import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Class, ClassMeetQueryResponse, ClassQueryResponse, ClassScheduleQueryResponse} from './_models'
import { IClassMeet } from '../../../../models/IClassMeet'
import { ClassStudentListResponse } from '../../../../models/IClassStudent'
import {IClassSchedule} from '../../../../models/IClassSchedule'
import {StudentMajor} from '../../../students/student-major-modal/core/_models'

const API_URL = process.env.REACT_APP_BASE_API_URL
const CLASS_URL = `${API_URL}/classes`
const GROUP_CLASS_URL = `${API_URL}/group-classes`
const STUDENT_URL = `${API_URL}/students`
const GET_COURSE_URL = `${API_URL}/classes/get`
const CLASS_MEET_URL = `${API_URL}/class-meets`
const CLASS_SCHEDULE_URL = `${API_URL}/class-schedules`
const CLASS_STUDENT_URL = `${API_URL}/classes/learning/students`

const getClass = (id: ID, query: string): Promise<ClassQueryResponse> => {
  return axios
    .get(`${GET_COURSE_URL}/${id}?${query}`)
    .then((d: AxiosResponse<ClassQueryResponse>) => d.data)
}

const getClassById = (id: ID): Promise<Class | undefined> => {
  return axios
    .get(`${CLASS_URL}/${id}`)
    .then((response: AxiosResponse<Response<Class>>) => response.data)
    .then((response: Response<Class>) => response.data)
}

const createClass = (_class: Class): Promise<Class | undefined> => {
  return axios
    .post(CLASS_URL, _class)
    .then((response: AxiosResponse<Response<Class>>) => response.data)
    .then((response: Response<Class>) => response.data)
}

const updateClass = (_class: Class): Promise<Class | undefined> => {
  return axios
    .put(`${CLASS_URL}/${_class.id}`, _class)
    .then((response: AxiosResponse<Response<Class>>) => response.data)
    .then((response: Response<Class>) => response.data)
}

const deleteClass = (classId: ID): Promise<void> => {
  return axios.delete(`${CLASS_URL}/${classId}`).then(() => {})
}

const reactiveClass = (classId: ID): Promise<void> => {
  return axios.put(`${CLASS_URL}/reactive/${classId}`).then(() => {})
}

const deleteSelectedClass = (classIds: Array<ID>): Promise<void> => {
  const requests = classIds.map((id) => axios.delete(`${CLASS_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getClassMeet = (query: string): Promise<ClassMeetQueryResponse> => {
  return axios
    .get(`${CLASS_MEET_URL}?${query}`)
    .then((d: AxiosResponse<ClassMeetQueryResponse>) => d.data)
}

const getClassMeetLov = (query: string): Promise<ClassMeetQueryResponse> => {
  return axios
    .get(`${CLASS_MEET_URL}/lov?${query}`)
    .then((d: AxiosResponse<ClassMeetQueryResponse>) => d.data)
}
const createClassMeet = (activity: IClassMeet): Promise<IClassMeet | undefined> => {
  return axios
    .post(CLASS_MEET_URL, activity)
    .then((response: AxiosResponse<Response<IClassMeet>>) => response.data)
    .then((response: Response<IClassMeet>) => response.data)
}

const updateClassMeet = (activity: IClassMeet): Promise<IClassMeet | undefined> => {
  return axios
    .put(`${CLASS_MEET_URL}/${activity.id}`, activity)
    .then((response: AxiosResponse<Response<IClassMeet>>) => response.data)
    .then((response: Response<IClassMeet>) => response.data)
}

const deleteClassMeet = (classId: ID, actionReason?: string): Promise<void> => {
  return axios.post(`${CLASS_MEET_URL}/delete/${classId}`, {actionReason}).then(() => {})
}

const deleteSelectedClassMeet = (classIds: Array<ID>): Promise<void> => {
  const requests = classIds.map((id) => axios.delete(`${CLASS_MEET_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getClassStudent = (classId: number): Promise<ClassStudentListResponse> => {
  return axios
    .get(`${CLASS_STUDENT_URL}/${classId}`)
    .then((d: AxiosResponse<ClassStudentListResponse>) => d.data)
}

const getClassSchedule = (query: string): Promise<ClassScheduleQueryResponse> => {
  return axios
    .get(`${CLASS_SCHEDULE_URL}?${query}`)
    .then((d: AxiosResponse<ClassScheduleQueryResponse>) => d.data)
}

const saveClassSchedule = (data: IClassSchedule[]): Promise<Response<number|undefined>> => {
  return axios
    .post(`${CLASS_SCHEDULE_URL}/save`, data)
    .then((response: AxiosResponse<Response<number>>) => response.data)
}

const getStudentsFromGroup = (groupId, semesterId): Promise<Response<StudentMajor[]>> => {
  return axios
    .get(`${GROUP_CLASS_URL}/${groupId}/students?semesterId=${semesterId}`)
    .then((response: AxiosResponse<Response<StudentMajor[]>>) => response.data)
}

const getStudentsFromCohort = (cohort, semesterId): Promise<Response<StudentMajor[]>> => {
  return axios
    .get(`${STUDENT_URL}/by-cohort/${cohort}?semesterId=${semesterId}`)
    .then((response: AxiosResponse<Response<StudentMajor[]>>) => response.data)
}

const getStudentsFromMajor = (majorId, semesterId): Promise<Response<StudentMajor[]>> => {
  return axios
    .get(`${STUDENT_URL}/by-major/${majorId}?semesterId=${semesterId}`)
    .then((response: AxiosResponse<Response<StudentMajor[]>>) => response.data)
}

const getStudentsFromFilter = (query: string): Promise<Response<StudentMajor[]>> => {
  return axios
    .get(`${STUDENT_URL}/by-filter?${query}`)
    .then((response: AxiosResponse<Response<StudentMajor[]>>) => response.data)
}

const getByQuizId = (id: ID): Promise<ClassQueryResponse[]> => {
  return axios
    .get(`${CLASS_URL}/byquiz/${id}`)
    .then((d: AxiosResponse<ClassQueryResponse[]>) => d.data)
}

export {
  getClass,
  deleteClass,
  deleteSelectedClass,
  getClassById,
  createClass,
  updateClass,
  createClassMeet,
  getClassMeet,
  updateClassMeet,
  deleteClassMeet,
  deleteSelectedClassMeet,
  getClassStudent,
  getClassSchedule,
  saveClassSchedule,
  getClassMeetLov,
  getStudentsFromGroup,
  getStudentsFromCohort,
  getStudentsFromMajor,
  getStudentsFromFilter,
  getByQuizId,
  reactiveClass,
}
