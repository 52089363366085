import {KTIcon} from '../../../../../_metronic/helpers'
import {useAnnouncement} from './AnnouncementProvider'
import {IAnnouncement} from '../../../../models/IAnnouncement'
import {FC} from 'react'

interface Prop {
  data?: IAnnouncement
}
const AnnouncementModalHeader: FC<Prop> = ({data}) => {
  const {setShowed} = useAnnouncement()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{data?.title}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-subjects-modal-action='close'
        onClick={() => setShowed(false)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {AnnouncementModalHeader}
