import {useEffect, useRef, useState} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTIcon} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {Dropdown} from 'react-bootstrap'
import {DataStatusSelect} from '../../../../data-status/components/DataStatusSelect'
import {ClassTypeSelect} from './ClassTypeSelect'

const ClassListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [status, setStatus] = useState<number>(1)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [totalFilter, setTotalFilter] = useState(1)
  const [typeId, setTypeId] = useState<number>(-1)

  useEffect(() => {
    MenuComponent.reinitialization()
    resetData()
  }, [])

  const resetData = () => {
    setStatus(1)
    setTypeId(-1)
    updateState({
      filter: {
        statusId: 1,
        typeId: -1,
      },
      ...initialQueryState,
    })
    setTotalFilter(1)
    setDropdownOpen(false)
  }

  const filterData = () => {
    updateState({
      filter: {
        statusId: status === -1 ? null : status || null,
        typeId: typeId === -1 ? null : typeId || null,
      },
      ...initialQueryState,
    })
    setDropdownOpen(false)
    setTotalFilter(status === -1 ? 0 : 1)
    if (typeId !== -1) {
      if (status !== -1) {
        setTotalFilter(2)
      } else {
        setTotalFilter(1)
      }
    }
  }

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const dropdownRef = useRef<any>(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Dropdown
      className='me-3'
      show={dropdownOpen}
      onToggle={toggleDropdown}
      autoClose={false}
      ref={dropdownRef}
    >
      <Dropdown.Toggle id='dropdown-custom-components' className='w-md-auto w-100' variant='light'>
        <KTIcon iconName='filter' className='fs-2' />
        <span className='pe-3'>Filter</span>
        {totalFilter > 0 && (
          <span className='badge badge-square badge-dark text-bg-dark' style={{lineHeight: '1'}}>
            {totalFilter}
          </span>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className='p-8' style={{width: 300}} align='end'>
        <div className='mb-10'>
          <label className='form-label fs-6 fw-bold mb-2'>Type:</label>
          <ClassTypeSelect
            selectedValue={typeId}
            allowClear={false}
            isUseFormik={false}
            onChange={(selected) => setTypeId(selected?.value)}
          />
        </div>
        <div className='mb-10'>
          <label className='form-label fs-6 fw-bold mb-2'>Status:</label>
          <DataStatusSelect
            selectedValue={status}
            allowClear={false}
            isUseFormik={false}
            onChange={(selected) => setStatus(selected?.value)}
          />
        </div>
        <div className='d-flex justify-content-end'>
          <button
            type='button'
            disabled={isLoading}
            onClick={resetData}
            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
            data-kt-menu-dismiss='true'
            data-kt-student-table-filter='reset'
          >
            Reset
          </button>
          <button className='btn btn-warning' type='button' onClick={filterData}>
            Apply
          </button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export {ClassListFilter}
