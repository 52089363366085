import {FC, useEffect, useRef, useState} from 'react'
import {KTIcon, stringifyRequestQuery} from '../../../../_metronic/helpers'
import {getCoursesScore} from '../core/_request'
import clsx from 'clsx'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {ApexOptions} from 'apexcharts'
import ReactApexChart from 'react-apexcharts'
import MajorSelect from '../../majors/components/MajorSelect'
import BlockLoading from '../../../components/BlockLoading'
import SemesterSelect from '../../classes/components/SemesterSelect'
import {ISemester} from '../../../models/ISemester'
import Flatpickr from 'react-flatpickr'
import {endOfMonth, startOfMonth} from '../../../utils/date'
import {IAverageScoreChart} from '../../../models/IAverageScoreChart'
import {splitString} from '../../../utils/string'
import CourseScoreDetailModal from './CourseScoreDetailModal'
import {downloadFile} from '../../../utils/file'
import {showError} from '../../../utils/toast'
import {Dropdown} from 'react-bootstrap'
import LoadingButtonWrapper from '../../../components/LoadingButtonWrapper'
import { useAuth } from '../../auth'

const noDataOpt: any = {
  text: 'Please Choose Major First',
  align: 'center',
  verticalAlign: 'middle',
  offsetX: 0,
  offsetY: 0,
  style: {
    color: '#99a1b7',
    fontSize: '14px',
    fontFamily: 'Helvetica',
  },
}

const CourseScoreChartCard = () => {
  const [chartOptions, setChartOptions] = useState<ApexOptions>(getChartOptions(300))
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [series, setSeries] = useState<any[]>([])
  const [majorId, setMajorId] = useState<number | null>(null)
  const [semester, setSemester] = useState<ISemester | undefined>(undefined)
  const [chartWidth, setChartWidth] = useState<number | undefined>(undefined)
  const [requestType, setRequestType] = useState(1)
  const [dateRange, setDateRange] = useState([startOfMonth(), endOfMonth()])
  const seriesRef = useRef<any>([])
  const depthRef = useRef(1)
  const [selectedItem, setSelectedItem] = useState('')
  const barSize = 200
  const [showModal, setshowModal] = useState(false)
  const [selectedId, setSelectedId] = useState<number | null>(null)
  const [studentName, setStudentName] = useState<string | null>(null)
  const parentRef = useRef(0)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const url = 'dashboards/courses-score-excel'
  const [loadingExport, setLoadingExport] = useState(false)
  const [loadingExportRaw, setLoadingExportRaw] = useState(false)
  const {currentUser} = useAuth()
  const [initialOptions, setInitialOptions] = useState<any[]>([])

  const setNoDataLabel = (text) => {
    checkChartWidth(1)
    setChartOptions({
      ...chartOptions,
      noData: {...noDataOpt, text},
      xaxis: {
        labels: {
          show: false,
        },
      },
    })
  }

  const setSeriesData = (items: IAverageScoreChart[]) => {
    const data = items?.map((m) => m.average)
    const categories = items?.map((m) => m.name)

    checkChartWidth(categories?.length ?? 1)
    setSeries([
      {
        name: 'Score',
        data: data.length > 0 ? data : [0],
      },
    ])

    const opt = {
      ...chartOptions,
      xaxis: {
        ...chartOptions.xaxis,
        categories: categories.length > 0 ? categories : ['-'],
        labels: {
          show: categories.length > 0,
        },
      },
    }

    setChartOptions(opt)
    if (categories.length === 0) {
      setNoDataLabel('No Data Displayed')
    }
  }

  const toParent = () => {
    depthRef.current = 1
    setSelectedItem('')
    setSeriesData(seriesRef.current)
  }

  const getData = async () => {
    try {
      let filter: any = {
        majorId,
        requestType,
      }

      if (requestType === 1) {
        filter.semesterId = semester ? semester.id : null
      } else {
        filter.startDate = dateRange[0].toISOString()
        filter.endDate = dateRange[1].toISOString()
      }

      const params = stringifyRequestQuery({
        filter: filter,
      })

      setIsLoading(true)

      const result = await getCoursesScore(params)
      if (result.code === '0000') {
        seriesRef.current = result.data
        setSeriesData(result.data)
        setIsLoading(false)
      } else {
        setNoDataLabel('Error: ' + result.message)
        // showError(result.message)
        setIsLoading(false)
      }
    } catch (ex: any) {
      // showError(ex.message)
      setNoDataLabel('Error: ' + ex.message)
      setIsLoading(false)
    }
  }

  const closeModalHandler = () => {
    setshowModal(false)
    setSelectedId(null)
  }

  useEffect(() => {
    if (majorId) {
      getData()
    }
  }, [majorId, semester, requestType, dateRange])

  const Header: FC = () => {
    return (
      <div className='d-flex justify-content-center flex-column me-3 mb-3'>
        <p className='fs-5 fw-bolder text-gray-700 text-hover-primary me-1 mb-2 mt-4 lh-1'>
          Average Course Score
        </p>
      </div>
    )
  }

  const majorChangeHandler = (e) => {
    setMajorId(e.value)
    setSemester(undefined)
    depthRef.current = 1
  }

  const semesterChangeHandler = (e) => {
    setSemester(e)
    depthRef.current = 1
  }

  const checkChartWidth = (length?: number) => {
    const maxCategory = window.innerWidth <= 720 ? 2 : 5
    const categoriesCount = length ?? chartOptions.xaxis?.categories.length ?? 1
    if (categoriesCount > maxCategory) {
      setChartWidth(categoriesCount * barSize)
    } else {
      setChartWidth(undefined)
    }
  }

  useEffect(() => {
    setChartOptions((prev) => {
      let newItem = {...prev}
      if (newItem.chart != null) {
        newItem.chart.events = {
          dataPointSelection: (event, chartContext, config) => {
            if (depthRef.current === 1) {
              const selectedDataPointIndex = config.dataPointIndex
              setSelectedItem(seriesRef.current[selectedDataPointIndex].name)
              setSeriesData(seriesRef.current[selectedDataPointIndex].detail)
              parentRef.current = selectedDataPointIndex
              depthRef.current = 2
            } else if (depthRef.current === 2) {
              const child = seriesRef.current[parentRef.current]?.detail[config.dataPointIndex]
              setSelectedId(child.id)
              setStudentName(child.name)
              setshowModal(true)
            }
          },
        }
      }

      return newItem
    })

    checkChartWidth()

    function handleResize() {
      checkChartWidth()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const dropdownRef = useRef<any>(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const excel = async () => {
    try {
      setIsLoading(true)
      setLoadingExport(true)

      let filter: any = {
        majorId,
        requestType,
      }

      if (requestType === 1) {
        filter.semesterId = semester ? semester.id : null
      } else {
        filter.startDate = dateRange[0].toISOString()
        filter.endDate = dateRange[1].toISOString()
      }

      const params = stringifyRequestQuery({
        filter: filter,
      })

      await downloadFile(`${url}/summary?${params}`, 'CourseScore.xlsx')
    } catch (err: any) {
      showError(err.message)
    } finally {
      setIsLoading(false)
      setLoadingExport(false)
    }
  }

  const excelRaw = async () => {
    try {
      setIsLoading(true)
      setLoadingExportRaw(true)

      let filter: any = {
        majorId,
        requestType,
      }

      if (requestType === 1) {
        filter.semesterId = semester ? semester.id : null
      } else {
        filter.startDate = dateRange[0].toISOString()
        filter.endDate = dateRange[1].toISOString()
      }

      const params = stringifyRequestQuery({
        filter: filter,
      })

      await downloadFile(`${url}/raw?${params}`, 'CourseScoreRaw.xlsx')
    } catch (err: any) {
      showError(err.message)
    } finally {
      setIsLoading(false)
      setLoadingExportRaw(false)
    }
  }
  
  useEffect(() => {
    if(currentUser){
      if(currentUser.staffMajors){
        const firstMajor = currentUser.staffMajors[0];
        setMajorId(firstMajor.majorId)
        setInitialOptions([{
          value: firstMajor.majorId,
          label: firstMajor.majorName,
          degreeId:  firstMajor.degreeId,
        }])
      }
    }
  }, [currentUser])

  return (
    <div>
      <Header />
      <div className='card'>
        <div className={clsx('card-body pe-4')}>
          <div className='dashboard-filter-container d-flex flex-column flex-lg-row justify-content-between w-100'>
            <MajorSelect
              isUseFormik={false}
              className='w-100 w-lg-250px pe-lg-4 mb-3 mb-lg-0'
              onChange={majorChangeHandler}
              selectedValue={majorId}
              disabled={isLoading}
              initialOptions={initialOptions}
            />
            <div className='d-flex flex-column flex-md-row'>
              <div className='d-flex flex-column flex-md-row'>
                <LoadingButtonWrapper isLoading={loadingExport}>
                  <button
                    type='button'
                    className='btn btn-light-success d-none d-md-flex mb-3 mb-md-0 me-0 me-md-3'
                    onClick={() => excel()}
                    disabled={loadingExport}
                  >
                    <KTIcon iconName='exit-down' className='fs-2' />
                    Export
                  </button>
                </LoadingButtonWrapper>
                <LoadingButtonWrapper isLoading={loadingExportRaw}>
                  <button
                    type='button'
                    className='btn btn-light-success d-none d-md-flex mb-3 mb-md-0'
                    onClick={() => excelRaw()}
                    disabled={loadingExportRaw}
                  >
                    <KTIcon iconName='exit-down' className='fs-2' />
                    Export Raw
                  </button>
                </LoadingButtonWrapper>
                <Dropdown
                  className='me-3 d-md-none d-sm-block w-100'
                  show={dropdownOpen}
                  onToggle={toggleDropdown}
                  autoClose={false}
                  ref={dropdownRef}
                >
                  <Dropdown.Toggle
                    id='dropdown-custom-components'
                    className='w-100'
                    variant='success'
                  >
                    <KTIcon iconName='exit-down' className='fs-2' />
                    <span className='pe-3'>Export</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='p-8' style={{width: 300}} align='end'>
                    <Dropdown.Item onClick={() => excel()}>Export</Dropdown.Item>
                    <Dropdown.Item onClick={() => excelRaw()}>Export Raw</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          {isLoading && <BlockLoading fullBlocking={true} />}
          <div className='mt-6' style={{height: '20px'}}>
            {depthRef.current > 1 && (
              <div className='cursor-pointer' onClick={() => toParent()}>
                <i className='fas fa-chevron-left'></i>
                <span className='fs-5 text-muted fw-bold ms-2 lh-1'>{selectedItem}</span>
              </div>
            )}
          </div>
          <div style={{overflow: 'auto'}}>
            <ReactApexChart
              options={chartOptions}
              series={series}
              type='bar'
              height={300}
              width={chartWidth}
            />
          </div>
        </div>
      </div>

      <CourseScoreDetailModal
        id={selectedId}
        show={showModal}
        courseName={selectedItem}
        studentName={studentName}
        onClose={closeModalHandler}
      />
    </div>
  )
}

export default CourseScoreChartCard

function getChartOptions(height: number): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')

  return {
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: 200,
      toolbar: {
        show: true,
        tools: {
          zoom: true,
          download: false,
        },
      },
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4,
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1,
          },
        },
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: false,
        borderRadius: 5,
        dataLabels: {
          position: 'top',
        },
      },
    },

    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#4b5675'],
      },
      offsetY: -20,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    noData: noDataOpt,
    xaxis: {
      categories: [],
      labels: {
        show: false,
        formatter: (val) => {
          return splitString(val, 25)
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        formatter: function (val, opt) {
          const ticks = [0, 20, 40, 60, 80, 100]
          if (ticks.includes(val)) {
            return val + ''
          }
          return '.'
        },
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      max: 110,
      min: 0,
      tickAmount: 11,
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ''
        },
      },
    },
    colors: [
      '#1f77b4', // blue
      '#ff7f0e', // orange
      '#2ca02c', // green
      '#d62728', // red
      '#9467bd', // purple
      '#8c564b', // brown
      '#e377c2', // pink
      '#7f7f7f', // gray
      '#bcbd22', // yellow
      '#17becf', // cyan
    ],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          height: 800,
          plotOptions: {
            bar: {
              // horizontal: true,
            },
          },
        },
      },
    ],
  }
}
