import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {CourseListWrapper} from './course-list/CourseList'
import CourseForm from './course-form/CourseForm'
import {ClassListWrapper} from './class-list/ClassList'
import ClassForm from './class-form/ClassForm'

const courseBreadcrumbs: Array<PageLink> = [
  {
    title: 'Course Management',
    path: '/courses',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CoursePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=''
          element={
            <>
              <CourseListWrapper />
            </>
          }
        />
        <Route
          path=':id'
          element={
            <>
              <CourseForm />
            </>
          }
        />
        {/* <Route
          path='class/list/:id'
          element={
            <>
              <ClassListWrapper />
            </>
          }
        /> */}
        <Route
          path='class/entry/:id'
          element={
            <>
              <ClassForm />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default CoursePage
