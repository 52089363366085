// @ts-nocheck
import {Column} from 'react-table'
import {GraduatedStudentCustomHeader} from './GraduatedStudentCustomHeader'
import {GraduatedStudent} from '../../core/_model'

const graduatedStudentColumns: ReadonlyArray<Column<GraduatedStudent>> = [
  {
    Header: (props) => (
      <GraduatedStudentCustomHeader
        tableProps={props}
        title='Name'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'studentName',
    id: 'studentName',
  },
  {
    Header: (props) => (
      <GraduatedStudentCustomHeader
        tableProps={props}
        title='Major'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'majorName',
    id: 'majorName',
  },
  {
    Header: (props) => (
      <GraduatedStudentCustomHeader
        tableProps={props}
        title='Degree'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'degreeName',
    id: 'degreeName',
  },
  {
    Header: (props) => (
      <GraduatedStudentCustomHeader
        tableProps={props}
        title='Year'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'graduationYear',
    id: 'graduationYear',
  },
]

export {graduatedStudentColumns}
