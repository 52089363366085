import {FC, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import {useFormik, Field} from 'formik'
import {KTIcon, isNotEmpty, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import {Modal} from 'react-bootstrap'
import {CourseAssignment, initialCourseAssignment} from '../../course-list/core/_models'
import {CourseListLoading} from '../../course-list/components/loading/CourseListLoading'
import {createCourseAssignment, updateCourseAssignment} from '../../course-list/core/_requests'
import RichEditor from '../../../../components/RichEditor'
import {useLocation} from 'react-router-dom'
import {useAuth, MenuModel, getMenuAccess} from '../../../auth'
import LoadingButtonWrapper from '../../../../components/LoadingButtonWrapper'

type Props = {
  show: boolean
  assignment: CourseAssignment | null
  id: number
  onClose(): void
}

let yupValidation = {
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(250, 'Maximum 250 symbols')
    .required('Name is required'),
  description: Yup.mixed().required('Description is required'),
}

const addcourseAssignmentSchema = Yup.object().shape({
  ...yupValidation,
  assignmentFile: Yup.mixed().required('File is required'),
})
const editcourseAssignmentSchema = Yup.object().shape(yupValidation)

const CourseFormAssignmentModal: FC<Props> = ({id, show, assignment, onClose}) => {
  const [isLoading, setIsLoading] = useState(false)

  const [courseAssignmentForEdit, setCourseAssignmentForEdit] = useState<CourseAssignment>({
    ...initialCourseAssignment,
  })

  const inputFileRef = useRef<HTMLInputElement>(null)

  const {pathname} = useLocation()
  const {currentMenu} = useAuth()
  const [menuAccess, setMenuAccess] = useState<MenuModel | undefined>(undefined)
  useEffect(() => {
    setMenuAccess(getMenuAccess(currentMenu ?? [], pathname))
  }, [currentMenu])

  useEffect(() => {
    if (show) {
      formik.resetForm()
      setCourseAssignmentForEdit({
        id: assignment?.id || initialCourseAssignment.id,
        name: assignment?.name || initialCourseAssignment.name,
        description: assignment?.description || initialCourseAssignment.description,
        assignmentFile: undefined,
      })
    }
  }, [show])

  const formik = useFormik({
    initialValues: courseAssignmentForEdit,
    enableReinitialize: true,
    validationSchema: isNotEmpty(assignment?.id)
      ? editcourseAssignmentSchema
      : addcourseAssignmentSchema,
    onSubmit: async (values, {setSubmitting, setStatus}) => {
      setStatus(null)
      const models = {...values, courseId: id}
      console.log(models)
      setSubmitting(true)
      let result: any
      try {
        if (isNotEmpty(assignment?.id)) {
          result = await updateCourseAssignment(models)
        } else {
          result = await createCourseAssignment(models)
        }
        if (result) {
          onClose()
          setSubmitting(true)
        } else {
          setStatus('Error')
        }
      } catch (ex: any) {
        console.error(ex)
        setStatus(ex.message)
      } finally {
      }
    },
  })

  const handleClose = () => {
    onClose()
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Assignment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='p-4'>
          <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <fieldset disabled={!menuAccess?.canCreate}>
              <div className='d-flex flex-column'>
                {formik.status ? (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                ) : (
                  ''
                )}

                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Name</label>
                  <input
                    placeholder='Name'
                    {...formik.getFieldProps('name')}
                    type='text'
                    name='name'
                    className={clsx(
                      'form-control mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.name && formik.errors.name},
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isLoading}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Description</label>
                      <RichEditor
                        onChange={(val) => formik.setFieldValue('description', val)}
                        value={formik.values.description}
                        disabled={!menuAccess?.canCreate}
                      />
                      {formik.touched.description && formik.errors.description && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.description}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className={`${!assignment?.id && 'required'} fw-bold fs-6 mb-2`}>
                    {' '}
                    {assignment?.id ? 'Change File' : 'File'}
                  </label>
                  <input
                    name='assignmentFile'
                    ref={inputFileRef}
                    accept='.doc, .docx, .pdf, .ppt, .pptx'
                    type='file'
                    className={clsx(
                      'form-control mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.assignmentFile && formik.errors.assignmentFile},
                      {
                        'is-valid': formik.touched.assignmentFile && !formik.errors.assignmentFile,
                      }
                    )}
                    onBlur={formik.handleBlur}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.currentTarget.files) {
                        formik.setFieldValue('fileCode', new Date().getTime().toString())
                        formik.setFieldValue('assignmentFile', event.currentTarget.files[0])
                      }
                    }}
                  />

                  {/* <button type='button' onClick={()=> inputFileRef.current?.click()} className='btn btn-primary'>Upload File</button>

                                <div className='card flex-row justify-content-between align-items-center px-5'>
                                    <div>
                                        {assignment?.fileName}
                                    </div>
                                    <div>
                                        <button type="button" className="btn btn-active-icon-primary btn-active-text-primary">
                                            <KTIcon iconName="file-down" className="fs-2" />
                                        </button>
                                    </div>
                                </div> */}
                  {formik.touched.assignmentFile && formik.errors.assignmentFile && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.assignmentFile}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

            {menuAccess?.canCreate && (
            <div className='text-end pt-15'>
              <LoadingButtonWrapper isLoading={formik.isSubmitting || isLoading}>
                <button
                  type='submit'
                  className='btn btn-warning'
                  disabled={formik.isSubmitting || isLoading}
                >
                  <span className='indicator-label'>Submit</span>
                </button>
              </LoadingButtonWrapper>
            </div>
            )}
            {/* end::Actions */}
          </fieldset>
          </form>
          {(formik.isSubmitting || isLoading) && <CourseListLoading />}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export {CourseFormAssignmentModal}
