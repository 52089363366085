import axios from 'axios'
import {IBaseResponse} from '../../../../auth'
import {LecturerSkillType} from './_model'

const API_URL = process.env.REACT_APP_BASE_API_URL
const URL = `${API_URL}/reports/lecturer-skill`

const getLecturerSkill = async (query: string): Promise<IBaseResponse<LecturerSkillType[]>> => {
  const response = await axios.get(`${URL}?${query}`)
  return response.data
}

export {getLecturerSkill}
