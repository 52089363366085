import {KTCard} from '../../../../../_metronic/helpers'
import {StudentAttendanceListHeader} from './components/header/StudentAttendanceListHeader'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {StudentAttendanceTable} from './table/StudentAttendanceTable'

const StudentAttendanceList = () => {
  return (
    <>
      <div className='d-flex flex-row justify-content-between mb-8'>
        <h1 className='page-heading text-dark fw-bold fs-3 my-0'>Report Student Attendance</h1>
      </div>
      <KTCard>
        <StudentAttendanceListHeader />
        <StudentAttendanceTable />
      </KTCard>
    </>
  )
}

const StudentAttendanceListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <StudentAttendanceList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {StudentAttendanceListWrapper}
