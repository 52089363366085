import {useMemo, Fragment, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {LecturerAttendanceType} from '../core/_model'
import {ReportLoading} from '../../../components/ReportLoading'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {lecturerAttendanceColumns} from './columns/_columns'
import {LecturerAttendanceListPagination} from '../components/pagination/LecturerAttendancePagination'
import clsx from 'clsx'
import {formatDateTime} from '../../../../../utils/date'
import LecturerAttendanceDetailModal from '../components/LecturerAttendanceDetailModal'

const LecturerAttendanceTable = () => {
  const d = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => d, [d])
  const columns = useMemo(() => lecturerAttendanceColumns, [])
  const [showModal, setShowModal] = useState(false)
  const [selectedClassId, setSelectedClassId] = useState<number | null>(null)
  const [courseName, setCourseName] = useState<string>('')
  const {state} = useQueryRequest()

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: data?.records ?? [],
  })

  const showModalHandler = (row: any) => {
    setSelectedClassId(row.original?.classId)
    setShowModal(true)

    if (row.original.classCode) {
      setCourseName(`${row.original.courseName} (${row.original.classCode})`)
    } else {
      setCourseName(row.original.courseName)
    }
  }

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_attendance'
          className='table table-striped table-rounded dataTable align-middle table-row-dashed no-footer gs-7 fs-6 gy-5'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th rowSpan={2} className='text-start align-middle'>
                Name
              </th>
              <th rowSpan={2} className='text-start align-middle'>
                Class
              </th>
              <th rowSpan={2} className='align-middle'>
                Lecturer
              </th>
              <th rowSpan={2} className='align-middle'>
                Total Meeting
              </th>
              <th rowSpan={2} className='align-middle'>
                Percentage
              </th>
              <th rowSpan={2} className='align-middle'>
                Action
              </th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                return (
                  <tr>
                    <td>{row.original.courseName}</td>
                    <td>{row.original.classCode}</td>
                    <td className='text-center'>{row.original.totalLecturer}</td>
                    <td className='text-center'>{row.original.totalClassMeet}</td>
                    <td className='text-center'>{row.original.percentage}%</td>
                    <td className='text-center'>
                      <button
                        type='button'
                        onClick={() => showModalHandler(row)}
                        className='btn btn-outline btn-outline-primary btn-active-light-primary btn-sm'
                      >
                        Detail
                      </button>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={6}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {isLoading ? '' : ' No matching records found'}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* <LecturerAttendanceListPagination
        totalPages={data.totalPages}
        currentPage={data?.pageNumber ?? 1}
        onPageChange={() => {}}
      /> */}

      {showModal && (
        <LecturerAttendanceDetailModal
          classId={selectedClassId ?? 0}
          semesterId={state.filter?.semesterId}
          show={showModal}
          onClose={() => setShowModal(false)}
          courseName={courseName}
        />
      )}
      {isLoading && <ReportLoading />}
    </KTCardBody>
  )
}

export {LecturerAttendanceTable}
