// @ts-nocheck
import {Column} from 'react-table'
import {SurveyResult} from '../../core/_model'

const surveyResultColumns: ReadonlyArray<Column<SurveyResult>> = [
  {
    accessor: 'question',
    id: 'question',
  },
  {
    accessor: 'attendedStudents',
    id: 'attendedStudents',
    className: 'text-center',
  },
  {
    accessor: 'answerCount',
    id: 'answerCount',
    className: 'text-center',
  },
  {
    accessor: 'rate5',
    id: 'rate5',
    className: 'text-center',
  },
  {
    accessor: 'rate4',
    id: 'rate4',
    className: 'text-center',
  },
  {
    accessor: 'rate3',
    id: 'rate3',
    className: 'text-center',
  },
  {
    accessor: 'rate2',
    id: 'rate2',
    className: 'text-center',
  },
  {
    accessor: 'rate1',
    id: 'rate1',
    className: 'text-center',
  },
  {
    accessor: 'average',
    id: 'average',
    className: 'text-center',
  },
]

export {surveyResultColumns}
