/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '../../../../../../_metronic/helpers'
import {Confirm} from '../../../../../components/Confirm'
import {viewFile} from '../../../../../utils/file'
import {ReferenceListLoading} from '../../components/ReferenceListLoading'
import {showError, showSuccess} from '../../../../../utils/toast'
import {
  DownloadRequestStatus,
  DownloadRequestType,
} from '../../../../download-request/download-request-list/core/_models'
import {downloadExternalLink} from '../../core/_request'
import {createDownloadRequest} from '../../../../download-request/download-request-list/core/_requests'
import {useAuth} from '../../../../auth'
import { PreviewPdf } from '../../../../../components/PreviewPdf'

type Props = {
  id: ID
  abstractUnique: string
  allowRequest: boolean
  allowDownload: boolean
  statusId: number
  fileName: string
  downloadLink: string
  fileId: number,
  title: string
}

const ReferenceActionsCell: FC<Props> = ({
  id,
  abstractUnique,
  allowRequest,
  allowDownload,
  statusId,
  fileName,
  downloadLink,
  fileId,
  title,
}) => {
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const {currentUser} = useAuth()
  const queryClient = useQueryClient()
  const [showPreviewPdf, setShowPreviewPdf] = useState(false)
  const handleClosePreviewPdf = () => setShowPreviewPdf(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const handleView = async () => {
    // try {
    //   setLoading(true)
    //   await viewFile(`bibliographies/download/${abstractUnique}`)
    // } catch (error: any) {
    //   showError(error.message)
    // } finally {
    //   setLoading(false)
    // }
    setShowPreviewPdf(true)
  }

  const handleDownload = async () => {
    try {
      setLoading(true)
      await downloadExternalLink(downloadLink ?? '', fileName)
    } catch (error: any) {
      showError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const createRequest = useMutation(
    () => {
      setLoading(true)
      return createDownloadRequest({
        typeId: DownloadRequestType.bibliography,
        statusId: DownloadRequestStatus.requested,
        requestedBy: currentUser?.id,
        fileId: fileId,
      })
    },
    {
      onSuccess: () => {
        setLoading(false)
        setShowModal(false)
        showSuccess('File successfully requested!')
        queryClient.invalidateQueries([`${QUERIES.REFERENCE_LIST}`])
      },
      onError: (err: any) => {
        setLoading(false)
      },
    }
  )

  return (
    <>
      <a
        href='#'
        className='btn btn-outline btn-outline-primary btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={handleView}>
            Preview
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {allowRequest && (
          <div className='menu-item px-3 text-start'>
            <a className='menu-link px-3' onClick={() => setShowModal(true)}>
              Download Full Document
            </a>
          </div>
        )}
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {allowDownload && statusId === DownloadRequestStatus.approved && (
          <div className='menu-item px-3 text-start'>
            <a className='menu-link px-3' onClick={handleDownload}>
              Download Full Document
            </a>
          </div>
        )}
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
      {loading && <ReferenceListLoading />}
      <Confirm
        name={'Confirmation'}
        body={'Would you like to request to download full document?'}
        handleClose={() => setShowModal(false)}
        handleConfirm={() => createRequest.mutateAsync()}
        show={showModal}
        isLoading={loading}
      />
       <PreviewPdf 
        name={title}
        url={`bibliographies/download/${abstractUnique}`}
        show={showPreviewPdf}
        handleClose={handleClosePreviewPdf}
      />
    </>
  )
}

export {ReferenceActionsCell}
