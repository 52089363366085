import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, MutableRefObject, useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import LoadingButtonWrapper from '../../../../components/LoadingButtonWrapper'
import RichEditor from '../../../../components/RichEditor'
import {getMenuAccess, MenuModel, useAuth} from '../../../auth'
import {CourseListLoading} from '../../course-list/components/loading/CourseListLoading'
import {CourseQuizQuestion, initialCourseQuizQuestion} from '../../course-list/core/_models'
import {createCourseQuizQuestion, updateCourseQuizQuestion} from '../../course-list/core/_requests'

type Props = {
  show: boolean
  question: CourseQuizQuestion | null
  quizId: number
  totalRecord: number
  onClose(): void
}

let yupValidation = {
  typeId: Yup.number(),
  questionText: Yup.string().required('Question is required'),
  correctScore: Yup.number().required('Score is required'),
  // correctAnswerEssay: Yup.string().when('typeId', {
  //   is: (val) => val == 2,
  //   then: () => Yup.string().required('Correct Answer is required'),
  // }),
  correctAnswerNumber: Yup.number().when('typeId', {
    is: (val) => val == 3,
    then: () => Yup.number().required('Correct Answer is required'),
  }),
  correctAnswer: Yup.number().when('typeId', {
    is: (val) => val == 1,
    then: () => Yup.number().required('Correct Answer is required'),
  }),
  option1: Yup.number().when('typeId', {
    is: (val) => val == 1,
    then: () => Yup.string().required('Option 1 is required'),
  }),
  option2: Yup.number().when('typeId', {
    is: (val) => val == 1,
    then: () => Yup.string().required('Option 2 is required'),
  }),
}

const addcourseQuizQuestionSchema = Yup.object().shape({
  ...yupValidation,
})
const editcourseQuizQuestionSchema = Yup.object().shape(yupValidation)

const CourseFormQuizQuestionModal: FC<Props> = ({quizId, show, question, totalRecord, onClose}) => {
  const [isLoading, setIsLoading] = useState(false)

  const [courseQuizQuestionForEdit, setCourseQuizQuestionForEdit] = useState<CourseQuizQuestion>({
    ...initialCourseQuizQuestion,
  })
  const [isSubmitNew, setSubmitNew] = useState(false)
  const {pathname} = useLocation()
  const {currentMenu} = useAuth()
  const [menuAccess, setMenuAccess] = useState<MenuModel | undefined>(undefined)
  const divScrollRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const scoreRef: MutableRefObject<HTMLInputElement | null> = useRef(null)
  const [totalQuestion, setTotalQuestion] = useState(0)

  useEffect(() => {
    setTotalQuestion(totalRecord)
  }, [totalRecord])

  useEffect(() => {
    setMenuAccess(getMenuAccess(currentMenu ?? [], pathname))
  }, [currentMenu])

  useEffect(() => {
    if (show) {
      formik.resetForm()
      setCourseQuizQuestionForEdit({
        id: question?.id || initialCourseQuizQuestion.id,
        typeId: question?.typeId || initialCourseQuizQuestion.typeId,
        questionText: question?.questionText || initialCourseQuizQuestion.questionText,
        option1: question?.option1 || initialCourseQuizQuestion.option1,
        option2: question?.option2 || initialCourseQuizQuestion.option2,
        option3: question?.option3 || initialCourseQuizQuestion.option3,
        option4: question?.option4 || initialCourseQuizQuestion.option4,
        option5: question?.option5 || initialCourseQuizQuestion.option5,
        option6: question?.option6 || initialCourseQuizQuestion.option6,
        correctAnswer: question?.correctAnswer || initialCourseQuizQuestion.correctAnswer,
        correctAnswerEssay:
          question?.correctAnswerEssay || initialCourseQuizQuestion.correctAnswerEssay,
        correctAnswerNumber:
          question?.correctAnswerNumber || initialCourseQuizQuestion.correctAnswerNumber,
        correctScore: question?.correctScore || initialCourseQuizQuestion.correctScore,
      })
      scoreRef.current?.focus()
    } else {
      setCourseQuizQuestionForEdit({...initialCourseQuizQuestion})
    }
  }, [show])

  const formik = useFormik({
    initialValues: courseQuizQuestionForEdit,
    enableReinitialize: true,
    validationSchema: isNotEmpty(question?.id)
      ? editcourseQuizQuestionSchema
      : addcourseQuizQuestionSchema,
    onSubmit: async (values, {setSubmitting, setStatus}) => {
      setStatus(null)
      setIsLoading(true)
      let models = {...values, quizId: quizId}
      switch (models.typeId) {
        case 1 || '1':
          models.correctAnswerEssay = undefined
          models.correctAnswerNumber = undefined
          break
        case 2 || '2':
          models.correctAnswerEssay = undefined
          models.correctAnswer = undefined
          break
        case 3 || '3':
          models.correctAnswer = undefined
          models.correctAnswerNumber = undefined
          break
      }
      setSubmitting(true)
      let result: any
      try {
        if (isNotEmpty(question?.id)) {
          result = await updateCourseQuizQuestion(models)
        } else {
          result = await createCourseQuizQuestion(models)
        }
        if (result) {
          if (!isSubmitNew) {
            setIsLoading(false)
            onClose()
          } else {
            formik.resetForm()
            setTotalQuestion(result?.totalQuestion)
            setIsLoading(false)
            setSubmitNew(false)
            goToTop()
          }
          setSubmitting(true)
        } else {
          setStatus('Error')
        }
      } catch (ex: any) {
        console.error(ex)
        setStatus(ex.message)
      } finally {
      }
    },
  })

  const handleClose = () => {
    onClose()
  }

  const goToTop = () => {
    divScrollRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    setTimeout(() => {
      scoreRef.current?.focus()
    }, 0)
  }

  const modules = {
    toolbar: [
      // [{ header: "1" }, { header: "2" }],
      [{font: []}],
      [{size: ['small', false, 'large', 'huge']}], // custom dropdown
      ['bold', 'italic', 'underline', 'strike'],
      [{color: []}, {background: []}], // dropdown with defaults from theme
      [{align: []}],
      [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
      ['link', 'image'],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      handleStyles: {
        displaySize: true,
        backgroundColor: 'black',
        border: 'none',
        color: 'white',
      },
      modules: ['Resize'],
    },
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ]

  const handleSubmitNew = () => {
    setSubmitNew(true)
    formik.handleSubmit()
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>
            {question == null ? 'Add Question' : 'Edit Question'} {totalQuestion + 1}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-question p-4'>
            <form className='form' onSubmit={formik.handleSubmit} noValidate>
              <fieldset disabled={!menuAccess?.canCreate}>
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-7'
                  style={{maxHeight: 480}}
                  ref={divScrollRef}
                >
                  <div className='d-flex flex-column'>
                    {formik.status ? (
                      <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className='row mb-7'>
                      <div className='col-12 col-lg-8'>
                        <label className='required fw-bold fs-6 mb-2'>Type</label>
                        <div>
                          <div className='d-inline-flex form-check me-4'>
                            <input
                              {...formik.getFieldProps('typeId')}
                              type='radio'
                              name='typeId'
                              className='form-check-input'
                              value={1}
                              checked={formik.values.typeId == 1}
                              disabled={formik.isSubmitting || isLoading}
                              id='multipleChoice'
                            />

                            <label className='form-check-label ms-2' htmlFor='multipleChoice'>
                              Multiple Choice
                            </label>
                          </div>
                          <div className='d-inline-flex form-check me-4'>
                            <input
                              {...formik.getFieldProps('typeId')}
                              type='radio'
                              name='typeId'
                              id='essayText'
                              className='form-check-input'
                              value={2}
                              checked={formik.values.typeId == 2}
                              disabled={formik.isSubmitting || isLoading}
                            />
                            <label className='form-check-label ms-2' htmlFor='essayText'>
                              Essay Text
                            </label>
                          </div>
                          <div className='d-inline-flex form-check me-4'>
                            <input
                              {...formik.getFieldProps('typeId')}
                              type='radio'
                              name='typeId'
                              id='essayNumber'
                              className='form-check-input'
                              value={3}
                              checked={formik.values.typeId == 3}
                              disabled={formik.isSubmitting || isLoading}
                            />
                            <label className='form-check-label ms-2' htmlFor='essayNumber'>
                              Essay Number
                            </label>
                          </div>
                        </div>
                        {formik.touched.typeId && formik.errors.typeId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.typeId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className='col-12 col-lg-4'>
                        <label className='required fw-bold fs-6 mb-2'>Score</label>
                        <input
                          placeholder='Score'
                          {...formik.getFieldProps('correctScore')}
                          type='number'
                          min={0}
                          name='correctScore'
                          className={clsx(
                            'form-control mb-3 mb-lg-0',
                            {
                              'is-invalid':
                                formik.touched.correctScore && formik.errors.correctScore,
                            },
                            {
                              'is-valid':
                                formik.touched.correctScore && !formik.errors.correctScore,
                            }
                          )}
                          autoComplete='off'
                          disabled={formik.isSubmitting || isLoading}
                          ref={scoreRef}
                        />
                        {formik.touched.correctScore && formik.errors.correctScore && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.correctScore}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-12'>
                      <div className='col-12'>
                        <div className='fv-row'>
                          <label className='required fw-bold fs-6 mb-2'>Question</label>
                          <div>
                            <RichEditor
                              onChange={(val) => formik.setFieldValue('questionText', val)}
                              value={formik.values.questionText}
                              disabled={!menuAccess?.canCreate}
                            />
                          </div>
                          {formik.touched.questionText && formik.errors.questionText && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.questionText}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {formik.values.typeId == 2 && (
                      <div className='fv-row mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Answer</label>
                        <div>
                          <RichEditor
                            onChange={(val) => formik.setFieldValue('correctAnswerEssay', val)}
                            value={formik.values.correctAnswerEssay}
                            disabled={!menuAccess?.canCreate}
                          />
                        </div>
                        {formik.touched.correctAnswerEssay && formik.errors.correctAnswerEssay && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.correctAnswerEssay}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {formik.values.typeId == 3 && (
                      <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>Answer</label>
                        <input
                          placeholder='Answer'
                          {...formik.getFieldProps('correctAnswerNumber')}
                          type='number'
                          name='correctAnswerNumber'
                          className={clsx(
                            'form-control mb-3 mb-lg-0',
                            {
                              'is-invalid':
                                formik.touched.correctAnswerNumber &&
                                formik.errors.correctAnswerNumber,
                            },
                            {
                              'is-valid':
                                formik.touched.correctAnswerNumber &&
                                !formik.errors.correctAnswerNumber,
                            }
                          )}
                          autoComplete='off'
                          disabled={formik.isSubmitting || isLoading}
                        />
                        {formik.touched.correctAnswerNumber && formik.errors.correctAnswerNumber && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.correctAnswerNumber}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {formik.values.typeId == 1 && (
                      <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>Options</label>
                        {formik.touched.correctAnswer && formik.errors.correctAnswer && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.correctAnswer}</span>
                            </div>
                          </div>
                        )}
                        <div className=''>
                          <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                            <thead>
                              <tr>
                                <th
                                  style={{minWidth: 50}}
                                  className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
                                >
                                  No
                                </th>
                                <th className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                  Label
                                </th>
                                <th
                                  style={{minWidth: 80}}
                                  className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
                                >
                                  Correct
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  className='text-gray-600 fw-bold text-center'
                                  style={{width: 30}}
                                >
                                  1.
                                </td>
                                <td>
                                  <div>
                                    <RichEditor
                                      onChange={(val) => formik.setFieldValue('option1', val)}
                                      value={formik.values.option1}
                                      disabled={!menuAccess?.canCreate}
                                    />
                                  </div>
                                  {formik.touched.option1 && formik.errors.option1 && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.option1}</span>
                                      </div>
                                    </div>
                                  )}
                                </td>
                                <td className='text-center' style={{width: 80}}>
                                  <input
                                    {...formik.getFieldProps('correctAnswer')}
                                    type='radio'
                                    name='correctAnswer'
                                    className='form-check-input'
                                    value={1}
                                    checked={formik.values.correctAnswer == 1}
                                    disabled={formik.isSubmitting || isLoading}
                                    id='correctAnswer'
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  className='text-gray-600 fw-bold text-center'
                                  style={{width: 30}}
                                >
                                  2.
                                </td>
                                <td>
                                  <div>
                                    <RichEditor
                                      onChange={(val) => formik.setFieldValue('option2', val)}
                                      value={formik.values.option2}
                                      disabled={!menuAccess?.canCreate}
                                    />
                                  </div>
                                  {formik.touched.option2 && formik.errors.option2 && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.option2}</span>
                                      </div>
                                    </div>
                                  )}
                                </td>
                                <td className='text-center' style={{width: 80}}>
                                  <input
                                    {...formik.getFieldProps('correctAnswer')}
                                    type='radio'
                                    name='correctAnswer'
                                    className='form-check-input'
                                    value={2}
                                    checked={formik.values.correctAnswer == 2}
                                    disabled={formik.isSubmitting || isLoading}
                                    id='correctAnswer'
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td
                                  className='text-gray-600 fw-bold text-center'
                                  style={{width: 30}}
                                >
                                  3.
                                </td>
                                <td>
                                  <div>
                                    <RichEditor
                                      onChange={(val) => formik.setFieldValue('option3', val)}
                                      value={formik.values.option3}
                                      disabled={!menuAccess?.canCreate}
                                    />
                                  </div>
                                </td>
                                <td className='text-center' style={{width: 80}}>
                                  <input
                                    {...formik.getFieldProps('correctAnswer')}
                                    type='radio'
                                    name='correctAnswer'
                                    className='form-check-input'
                                    value={3}
                                    checked={formik.values.correctAnswer == 3}
                                    disabled={formik.isSubmitting || isLoading}
                                    id='correctAnswer'
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td
                                  className='text-gray-600 fw-bold text-center'
                                  style={{width: 30}}
                                >
                                  4.
                                </td>
                                <td>
                                  <div>
                                    <RichEditor
                                      onChange={(val) => formik.setFieldValue('option4', val)}
                                      value={formik.values.option4}
                                      disabled={!menuAccess?.canCreate}
                                    />
                                  </div>
                                </td>
                                <td className='text-center' style={{width: 80}}>
                                  <input
                                    {...formik.getFieldProps('correctAnswer')}
                                    type='radio'
                                    name='correctAnswer'
                                    className='form-check-input'
                                    value={4}
                                    checked={formik.values.correctAnswer == 4}
                                    disabled={formik.isSubmitting || isLoading}
                                    id='correctAnswer'
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td
                                  className='text-gray-600 fw-bold text-center'
                                  style={{width: 30}}
                                >
                                  5.
                                </td>
                                <td>
                                  <div>
                                    <RichEditor
                                      onChange={(val) => formik.setFieldValue('option5', val)}
                                      value={formik.values.option5}
                                      disabled={!menuAccess?.canCreate}
                                    />
                                  </div>
                                </td>
                                <td className='text-center' style={{width: 80}}>
                                  <input
                                    {...formik.getFieldProps('correctAnswer')}
                                    type='radio'
                                    name='correctAnswer'
                                    className='form-check-input'
                                    value={5}
                                    checked={formik.values.correctAnswer == 5}
                                    disabled={formik.isSubmitting || isLoading}
                                    id='correctAnswer'
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td
                                  className='text-gray-600 fw-bold text-center'
                                  style={{width: 30}}
                                >
                                  6.
                                </td>
                                <td>
                                  <div>
                                    <RichEditor
                                      onChange={(val) => formik.setFieldValue('option6', val)}
                                      value={formik.values.option6}
                                      disabled={!menuAccess?.canCreate}
                                    />
                                  </div>
                                </td>
                                <td className='text-center' style={{width: 80}}>
                                  <input
                                    {...formik.getFieldProps('correctAnswer')}
                                    type='radio'
                                    name='correctAnswer'
                                    className='form-check-input'
                                    value={6}
                                    checked={formik.values.correctAnswer == 6}
                                    disabled={formik.isSubmitting || isLoading}
                                    id='correctAnswer'
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {menuAccess?.canCreate && (
                  <div className='text-end pt-5'>
                    <LoadingButtonWrapper isLoading={formik.isSubmitting || isLoading}>
                      <button
                        type='submit'
                        className='btn btn-warning'
                        disabled={formik.isSubmitting || isLoading}
                      >
                        <span className='indicator-label'>Submit</span>
                      </button>
                    </LoadingButtonWrapper>
                    {question == null && (
                      <LoadingButtonWrapper isLoading={formik.isSubmitting || isLoading}>
                        <button
                          type='button'
                          className='btn btn-warning ms-2'
                          onClick={handleSubmitNew}
                          disabled={formik.isSubmitting || isLoading}
                        >
                          <span className='indicator-label'>Submit and New</span>
                        </button>
                      </LoadingButtonWrapper>
                    )}
                  </div>
                )}
                {/* end::Actions */}
              </fieldset>
            </form>
            {(formik.isSubmitting || isLoading) && <CourseListLoading />}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export {CourseFormQuizQuestionModal}
