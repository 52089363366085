import {KTCard} from '../../../../../_metronic/helpers'
import {SurveyStudentInfoHeader} from './components/header/SurveyStudentInfoHeader'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {SurveyStudentInfoTable} from './table/SurveyStudentInfoTable'

const SurveyStudentInfo = () => {
  return (
    <>
      <div className='d-flex flex-row justify-content-between mb-8'>
        <h1 className='page-heading text-dark fw-bold fs-3 my-0'>Survey Student Info</h1>
      </div>
      <KTCard>
        <SurveyStudentInfoHeader />
        <SurveyStudentInfoTable />
      </KTCard>
    </>
  )
}

const SurveyStudentInfoWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <SurveyStudentInfo />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {SurveyStudentInfoWrapper}
