import {LecturerSubjectListSearch} from './LecturerSubjectListSearch'
import {LecturerSubjectListToolbar} from './LecturerSubjectListToolbar'

const LecturerSubjectListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <LecturerSubjectListSearch />
      <div className='card-toolbar'>
        <LecturerSubjectListToolbar />
      </div>
    </div>
  )
}

export {LecturerSubjectListHeader}
