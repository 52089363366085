import React, {useEffect, useRef} from 'react'
import {useField, useFormikContext} from 'formik'
import Flatpickr from 'react-flatpickr'

interface DatePickerProps {
  label: string
  name: string
  enableTime?: boolean
  dateFormat?: string
  labelFormat?: string
  minField?: string
  maxField?: string
  onChange?(val): void
  allowClear?: boolean
}

const DatePickerField: React.FC<DatePickerProps> = ({
  label,
  name,
  enableTime = false,
  dateFormat = 'Y-m-d',
  labelFormat = 'd/m/Y',
  minField,
  maxField,
  onChange,
  allowClear = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const {setFieldValue, getFieldProps} = useFormikContext()
  const [field] = useField(name)

  // useEffect(() => {
  //   if (inputRef.current) {
  //     const fp = flatpickr(inputRef.current, {
  //       altInput: true,
  //       altFormat: labelFormat,
  //       dateFormat: dateFormat,
  //       enableTime: enableTime,
  //       allowInput: true,
  //       time_24hr: true,
  //       onChange: (selectedDates) => {
  //         if (!selectedDates || selectedDates.length === 0) {
  //           return
  //         }
  //         setFieldValue(name, selectedDates[0].toISOString())
  //       },
  //       onClose: (selectedDates) => {
  //         setFieldValue(name, selectedDates[0].toISOString())
  //       },
  //     })

  //     return () => {
  //       fp.destroy()
  //     }
  //   }
  // }, [])

  return (
    <div style={{position: 'relative'}}>
      <Flatpickr
        {...field}
        options={{
          altInput: true,
          altFormat: labelFormat,
          dateFormat: dateFormat,
          enableTime: enableTime,
          allowInput: false,
          time_24hr: true,
          minDate: minField ? getFieldProps(minField).value : undefined,
          maxDate: maxField ? getFieldProps(maxField).value : undefined,
          minTime: minField ? getFieldProps(minField).value : undefined,
          maxTime: maxField ? getFieldProps(maxField).value : undefined,
        }}
        onClose={(selectedDates) => {
          if (!selectedDates || selectedDates.length === 0) {
            return
          }
          setFieldValue(name, selectedDates[0].toISOString())
        }}
        onChange={(selectedDates) => {
          if (!selectedDates || selectedDates.length === 0) {
            return
          }
          setFieldValue(name, selectedDates[0].toISOString())
          if (onChange) {
            onChange(selectedDates[0].toISOString())
          }
        }}
        className='pr-10'
      />
      {field.value && allowClear && (
        <button
          type='button'
          className='btn btn-default px-3'
          style={{position: 'absolute', top: 0, right: 0}}
          onClick={() => setFieldValue(name, null)} // Clear the date
        >
          <i className='fa-solid fa-xmark'></i>
        </button>
      )}
    </div>
  )
}

export default DatePickerField
