import axios from 'axios'
import {IBaseResponse} from '../../../../auth'
import {LecturerSubjectType} from './_model'

const API_URL = process.env.REACT_APP_BASE_API_URL
const URL = `${API_URL}/reports/lecturer-subject`

const getLecturerSubject = async (query: string): Promise<IBaseResponse<LecturerSubjectType[]>> => {
  const response = await axios.get(`${URL}?${query}`)
  return response.data
}

export {getLecturerSubject}
