import {useEffect} from 'react'
import {ClassEditModalHeader} from './ClassEditModalHeader'
import {ClassEditModalFormWrapper} from './ClassEditModalFormWrapper'

type props = {
  name?: string
}

const ClassEditModal: React.FC<props> = ({name}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
      <div
        className='modal fade show d-block'
        id='kt_modal_add_class'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-850px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <ClassEditModalHeader />
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <ClassEditModalFormWrapper name={name} />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
    </>
  )
}

export {ClassEditModal}
