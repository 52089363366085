// CustomSelect.js
import {FC, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import Select from 'react-select'
import {getSemesterSelect} from '../semester-list/core/_request'
import clsx from 'clsx'
import {stringifyRequestQuery, useDebounce} from '../../../../_metronic/helpers'

interface SemesterSelectProps {
  field: any
  form: any
  className?: string
  defaultOption?: any
  initialOptions?: any[]
  isClearable?: boolean
  portal?: any
  onChange?(value: any): void
  labelWithMajor?: boolean
  degreeId?: number
  addAll?: boolean
  isUseFormik?: boolean
  selectedValue?: number | null
}

const SemesterSelect: FC<SemesterSelectProps> = ({
  field,
  form,
  isClearable = false,
  className = '',
  defaultOption,
  initialOptions = [],
  portal,
  onChange,
  labelWithMajor = true,
  degreeId,
  addAll = false,
  isUseFormik = true,
  selectedValue,
  ...rest
}) => {
  let setFieldValue
  if (form) {
    setFieldValue = form.setFieldValue
  }
  const [keyword, setKeyword] = useState<string | undefined>(undefined)
  const debouncedSearchTerm = useDebounce(keyword, 150)

  const {isLoading, data, refetch} = useQuery(
    ['semester-select', degreeId],
    async () => {
      try {
        const response = await getSemesterSelect(
          stringifyRequestQuery({
            keyword: debouncedSearchTerm,
            filter: {
              degreeId,
            },
          })
        )
        let data = response.data?.records
        if (addAll) {
          data = [
            {
              majorName: '',
              name: '- ON GOING SEMESTER - -',
              id: -1,
            },
            ...(data || []),
          ]
        }

        return data
      } catch (ex) {
        console.log(ex)
      }
    },
    {enabled: false}
  )

  const handleChange = (selectedOption) => {
    if (isUseFormik) {
      setFieldValue(field.name, selectedOption.value)
    }
    if (onChange) onChange(selectedOption)
  }

  const options =
    keyword === undefined
      ? initialOptions
      : data?.some((item) => item.id !== null)
      ? data?.map((option) => ({
          value: option.id,
          label: labelWithMajor ? `${option.majorName} - ${option.name}` : option.name,
        }))
      : []

  const handleInputChange = (value) => {
    setKeyword(value)
  }

  useEffect(() => {
    if (debouncedSearchTerm === undefined) return
    refetch()
  }, [debouncedSearchTerm])

  useEffect(() => {
    refetch()
  }, [degreeId])

  const formatLabel = (option) => {
    return (
      <article className='custom-option text-wrap' style={{lineHeight: 1.1}}>
        {option.label}
      </article>
    )
  }

  return (
    <div className='select-container'>
      {isUseFormik ? (
        <Select
          {...rest}
          name={field.name}
          isLoading={isLoading}
          options={options}
          onChange={handleChange}
          value={field.value ? options?.find((option) => option.value === field.value) : null} // Set the initial selected value
          classNamePrefix='react-select'
          className={clsx(
            'react-select',
            'menu-outer-top',
            className,
            {'is-invalid': form.touched[field.name] && form.errors[field.name]},
            {'is-valid': form.touched[field.name] && !form.errors[field.name]}
          )}
          isClearable={isClearable}
          onInputChange={handleInputChange}
          onMenuOpen={() => handleInputChange('')}
          styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
          menuPortalTarget={document.body}
          menuShouldScrollIntoView={false}
          formatOptionLabel={(data) => formatLabel(data)}
          placeholder='Type to search...'
        />
      ) : (
        <Select
          {...rest}
          isLoading={isLoading}
          options={options}
          onChange={handleChange}
          value={options?.find((option) => {
            return option?.value === selectedValue
          })} // Set the initial selected value
          classNamePrefix='react-select'
          className={clsx('react-select', 'menu-outer-top', className)}
          isClearable={isClearable}
          onInputChange={handleInputChange}
          onMenuOpen={() => handleInputChange('')}
          styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
          menuPortalTarget={document.body}
          menuShouldScrollIntoView={false}
          formatOptionLabel={(data) => formatLabel(data)}
          placeholder='Type to search...'
        />
      )}
    </div>
  )
}

export default SemesterSelect
