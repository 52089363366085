import {FC, Fragment, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {KTIcon, stringifyRequestQuery} from '../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../../../courses/course-list/core/QueryResponseProvider'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {getLecturerAttendanceDetail} from '../core/_request'
import {showError} from '../../../../../utils/toast'
import {Row} from 'react-table'
import BlockLoading from '../../../../../components/BlockLoading'

type Props = {
  show: boolean
  onClose(): void
  classId: number
  semesterId: number
  courseName: string
}

const LecturerAttendanceDetailModal: FC<Props> = ({
  show,
  onClose,
  classId,
  semesterId,
  courseName,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<any | null>(null)
  const handleClose = () => {
    onClose()
  }

  const getData = async () => {
    setIsLoading(true)
    try {
      const resp = await getLecturerAttendanceDetail(
        classId,
        stringifyRequestQuery({
          filter: {
            semesterId: semesterId,
          },
        })
      )
      if (resp.code === '0000') {
        setData(resp.data)
      } else {
        showError(resp.message)
      }
    } catch (e: any) {
      showError(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    console.log(semesterId)
    getData()
  }, [classId, semesterId])

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        {/* <Modal.Title>Detail Report Attendance {classId}</Modal.Title> */}
        <Modal.Title>{courseName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <BlockLoading />}
        <div className='table-responsive'>
          <table
            id='kt_table_attendance'
            className='table table-striped table-rounded dataTable align-middle table-row-dashed no-footer gs-7 fs-6 gy-5'
          >
            <thead>
              <tr className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th className='text-start align-middle'>Name</th>
                <th className='text-start align-middle'>NIP</th>
                <th className='text-center align-middle'>Total Attend</th>
                {/* <th className='text-center align-middle'>Percentage</th> */}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold'>
              {data?.records?.length > 0 ? (
                data?.records?.map((row, i) => {
                  return (
                    <tr>
                      <td>{row.lecturerName}</td>
                      <td>{row.lecturerNip}</td>
                      <td className='text-center'>{row.totalAttend}</td>
                      {/* <td className='text-center'>{row.percentage}%</td> */}
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={3}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {isLoading ? '' : ' No matching records found'}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default LecturerAttendanceDetailModal
