import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {CourseListHeader} from './components/header/CourseListHeader'
import {CourseTable} from './table/CourseTable'
import {CourseEditModal} from './course-edit-modal/CourseEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import {useEffect} from 'react'

const CourseList = () => {
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <div className='d-flex flex-row justify-content-between mb-8'>
        <h1 className='page-heading text-dark fw-bold fs-3 my-0'>Courses</h1>
      </div>
      <KTCard>
        <CourseListHeader />
        <CourseTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <CourseEditModal />}
    </>
  )
}

const CourseListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CourseList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CourseListWrapper}
