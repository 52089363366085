import {useEffect, useMemo, useRef, useState} from 'react'
import {
  KTIcon,
  initialQueryState,
  stringifyRequestQuery,
} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {showError} from '../../../../../../utils/toast'
import {downloadFile} from '../../../../../../utils/file'
import MajorSelect from '../../../../../majors/components/MajorSelect'
import {Dropdown} from 'react-bootstrap'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import LoadingButtonWrapper from '../../../../../../components/LoadingButtonWrapper'

const LecturerSubjectListToolbar = () => {
  const {state, updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const [loadingExcel, setLoadingExcel] = useState<boolean>(false)
  const [loadingPdf, setLoadingPdf] = useState<boolean>(false)
  const [majorId, setMajorId] = useState<number | null>(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [totalFilter, setTotalFilter] = useState(0)
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  const url = 'reports/lecturer-subject'
  const dropdownRef = useRef<any>(null)

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const excel = async () => {
    try {
      setLoadingExcel(true)
      await downloadFile(`${url}/excel?${query}`, 'LecturerSubject.xlsx')
    } catch (err: any) {
      showError(err.message)
    } finally {
      setLoadingExcel(false)
    }
  }

  const pdf = async () => {
    try {
      setLoadingPdf(true)
      await downloadFile(`${url}/pdf?${query}`, 'LecturerSubject.pdf')
    } catch (err: any) {
      showError(err.message)
    } finally {
      setLoadingPdf(false)
    }
  }

  const changeHandler = (selected) => {
    setMajorId(selected?.value)
  }

  const resetData = () => {
    setMajorId(null)
    updateState({
      filter: {
        statusId: 1,
      },
      ...initialQueryState,
    })
    setDropdownOpen(false)
    setTotalFilter(0)
  }

  const filterData = () => {
    updateState({
      filter: {
        majorId,
      },
      ...initialQueryState,
    })
    setDropdownOpen(false)
    setTotalFilter(1)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-student-table-toolbar='base'>
      <Dropdown
        className='me-3'
        show={dropdownOpen}
        onToggle={toggleDropdown}
        autoClose={false}
        ref={dropdownRef}
      >
        <Dropdown.Toggle
          id='dropdown-custom-components'
          className='w-md-auto w-100'
          variant='light'
        >
          <KTIcon iconName='filter' className='fs-2' />
          <span className='pe-3'>Filter</span>
          {totalFilter > 0 && (
            <span className='badge badge-square badge-dark text-bg-dark' style={{lineHeight: '1'}}>
              {totalFilter}
            </span>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu className='p-8' style={{width: 300}} align='end'>
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold mb-2'>Major:</label>
            <MajorSelect selectedValue={majorId} isUseFormik={false} onChange={changeHandler} />
          </div>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-student-table-filter='reset'
            >
              Reset
            </button>
            <button className='btn btn-warning' type='button' onClick={filterData}>
              Apply
            </button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <LoadingButtonWrapper isLoading={loadingExcel}>
        <button
          type='button'
          className='btn btn-light-success me-3'
          onClick={() => excel()}
          disabled={loadingExcel}
        >
          <KTIcon iconName='exit-up' className='fs-2' />
          Export
        </button>
      </LoadingButtonWrapper>

      <LoadingButtonWrapper isLoading={loadingPdf}>
        <button
          type='button'
          className='btn btn-light-danger me-3'
          onClick={() => pdf()}
          disabled={loadingPdf}
        >
          <KTIcon iconName='file' className='fs-2' />
          PDF
        </button>
      </LoadingButtonWrapper>
    </div>
  )
}

export {LecturerSubjectListToolbar}
