import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ClassListHeader} from './components/header/ClassListHeader'
import {ClassTable} from './table/ClassTable'
import {KTCard} from '../../../../_metronic/helpers'
import {useEffect} from 'react'
import {ClassEditModal} from './class-edit-modal/ClassEditModal'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {getCourseByIdHeader} from '../course-list/core/_requests'
import {PageTitle} from '../../../../_metronic/layout/core'

const ClassList = ({id}) => {
  const {itemIdForUpdate} = useListView()
  // const {id} = useParams()

  const {isLoading, data, refetch} = useQuery(
    'course-by-id-header',
    async () => {
      try {
        const response = await getCourseByIdHeader(id)
        const el = document.getElementById('course-class-modal-title')
        if (el) {
          el.innerHTML = (response?.subjectName ?? '') + ' - ' + (response?.semesterName ?? '')
        }
        return response
      } catch (ex) {
        console.log(ex)
      }
    },
    {enabled: false}
  )

  useEffect(() => {
    if (id > 0) refetch()
  }, [id])

  return (
    <>
      <KTCard>
        <ClassListHeader />
        <ClassTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ClassEditModal name={data?.subjectName} />}
    </>
  )
}

const ClassListWrapper = ({id}) => (
  <QueryRequestProvider>
    <QueryResponseProvider id={id}>
      <ListViewProvider>
        <ClassList id={id} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ClassListWrapper}
