import {FC} from 'react'
import Skeleton from 'react-loading-skeleton'
import {useQuery} from 'react-query'
import {getActiveStudentCount} from '../core/_request'
import {ActiveStudent} from '../core/_model'
import clsx from 'clsx'

const ActiveStudentCard: FC = () => {
  const {data, isError, error, isLoading} = useQuery(
    ['get-active-student'],
    () => getActiveStudentCount(),
    {
      select: (d) => d.data,
    }
  )

  if (isLoading) {
    return <Skeleton count={5} />
  }

  if (isError) {
    // @ts-ignore
    return <p>{error?.message}</p>
  }

  return (
    <>
      <div className='d-flex justify-content-center flex-column me-3 mb-3'>
        <p className='fs-5 fw-bolder text-gray-700 text-hover-primary me-1 mb-2 mt-1 lh-1'>
          Active Student
        </p>
      </div>
      <div className='card active-student-wrapper'>
        <div className='card-body py-0'>
          {data?.map(({count, majorName, degreeName}, idx) => {
            return (
              <Item
                key={idx}
                count={count}
                isLast={idx === (data?.length) - 1}
                majorName={majorName}
                degreeName={degreeName}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

const Item: FC<ActiveStudent> = ({count, majorName, degreeName, isLast = true}) => {
  return (
    <div className={clsx('card-upcoming d-flex', {'border-bottom': !isLast})}>
      <div className='p-2 pt-4 d-flex flex-column align-items-center' style={{width: 60}}>
        <div className='symbol symbol-35px me-2'>
          <span className='symbol-label'>
            <h2 className="mb-0">{count}</h2>
          </span>
        </div>
      </div>
      <div className={clsx('flex-grow-1 px-5 py-5')}>
        <h4>{majorName}</h4>
        <div className='text-gray-500 fw-semibold d-flex fs-6 align-items-center'>
          {degreeName}
        </div>
      </div>
    </div>

    // <div className='row'>
    //   <div className='col-md-12 col-lg-2 col-sm-12'>
    //     <div
    //       className='container h-100'
    //       style={{
    //         borderRight: '2px solid #cdcdcd',
    //       }}
    //     >
    //       <div className='row h-100 justify-content-center align-items-center'>
    //         <div>
    //           <div className='fs-2hx fw-bold lh-1 ls-n2 d-flex justify-content-center'>{count}</div>
    //           <div className='d-flex justify-content-center'>Students</div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className='col-md-12 col-lg-6 col-sm-12'>
    //     <p className='opacity-80 fw-bold fs-1'>{majorName}</p>
    //     <div className='d-flex flex-row'></div>
    //   </div>
    // </div>
  )
}

export default ActiveStudentCard
