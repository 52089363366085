import axios, {AxiosError} from 'axios'
import {showError} from './toast'
import {IBaseResponse} from '../modules/auth'

const API_URL = process.env.REACT_APP_BASE_API_URL

const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result?.toString().split(',')[1] || '')
    reader.onerror = (error) => reject(error)
  })
}

function bytesToSize(bytes) {
  if (bytes === 0) {
    return '0.00 B'
  }

  let e = Math.floor(Math.log(bytes) / Math.log(1024))
  return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B'
}

const viewFile = async (apiUrl: string) => {
  try {
    var response = await axios.get(`${API_URL}/${apiUrl}`, {
      responseType: 'blob',
    })

    const data = response.data
    const blob = new Blob([data], {type: data.type})
    const url = window.URL.createObjectURL(blob)
    window.open(url, '_blank')
  } catch (err: any) {
    if (err.type === 'application/json') {
      const json: IBaseResponse<any> = JSON.parse(await err.text())

      if (json.code !== '0000') {
        throw new Error(json.message)
      }
    } else if (err.type === 'application/problem+json') {
      const json: any = JSON.parse(await err.text())

      if (json.status === 404) {
        throw new Error('File not found')
      }
    }
    throw err
  }
}

const downloadFile = async (apiUrl: string, fileName: string) => {
  try {
    const response = await axios.get(`${API_URL}/${apiUrl}`, {
      responseType: 'blob',
    })
    const blob = new Blob([response.data], {type: response.headers['content-type']})
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  } catch (err: any) {
    if (err.type === 'application/json') {
      const json: IBaseResponse<any> = JSON.parse(await err.text())

      if (json.code !== '0000') {
        throw new Error(json.message)
      }
    } else if (err.type === 'application/problem+json') {
      const json: any = JSON.parse(await err.text())

      if (json.status === 404) {
        throw new Error('File not found')
      }
    }
  }
}

const getFileType = (url: string): 'image' | 'pdf' | 'video' | 'ppt' | 'unknown' => {
  const extension = url?.split('.').pop()?.toLowerCase()

  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg']
  const pdfExtensions = ['pdf']
  const videoExtensions = ['mp4', 'avi']
  const pptExtensions = ['ppt', 'pptx']

  if (extension && imageExtensions.includes(extension)) {
    return 'image'
  } else if (extension && pdfExtensions.includes(extension)) {
    return 'pdf'
  } else if (extension && videoExtensions.includes(extension)) {
    return 'video'
  } else if (extension && pptExtensions.includes(extension)) {
    return 'ppt'
  } else {
    return 'unknown'
  }
}

export {fileToBase64, bytesToSize, downloadFile, viewFile, getFileType}
