// CustomSelect.js
import React, {ChangeEventHandler, Dispatch, FC, SetStateAction, useEffect, useState} from 'react'
import {useInfiniteQuery, useQuery} from 'react-query'
import Select from 'react-select'
import axios from 'axios'
import clsx from 'clsx'
import {ID, stringifyRequestQuery, useDebounce} from '../../../../../../../_metronic/helpers'
import { getCourseQuiz, getCourseQuizSelect, getCourseSelect } from '../../../../../courses/course-list/core/_requests'
import { CourseQuiz } from '../../../../../courses/course-list/core/_models'
import {SelectWithMoreWrapper} from '../../../../../../components/LoadMore'

interface Props {
  className?: string
  defaultOption?: any
  initialOptions?: any[]
  isClearable?: boolean
  portal?: any
  selected?: CourseQuiz
  setSelected?: Dispatch<SetStateAction<CourseQuiz | undefined>>
  placeholder?: string
  semesterId?: number
  disabled?: boolean
}

const CourseQuizSelect: FC<Props> = ({
  isClearable = false,
  className = '',
  defaultOption,
  initialOptions = [],
  portal,
  selected,
  setSelected,
  placeholder = 'Type to search...',
  semesterId,
  disabled = false,
  ...rest
}) => {
  const [keyword, setKeyword] = useState<string | undefined>(undefined)
  const [options, setOptions] = useState<any[]>([...initialOptions])
  const debouncedSearchTerm = useDebounce(keyword, 150)

  const fetchData = async ({pageParam = 1}) => {
    try {
      const response = await getCourseQuizSelect(
        stringifyRequestQuery({
          keyword: debouncedSearchTerm,
          pageSize: 10,
          pageNumber: pageParam,
          filter: {
            semesterId: semesterId
          }
        })
      )
      return response.data
    } catch (ex) {
      console.log(ex)
      return undefined
    }
  }

  const {isLoading, data, refetch, fetchNextPage, hasNextPage, dataUpdatedAt, isFetching} = useInfiniteQuery(
    'class-select',
    fetchData,
    {
      enabled: false,
      getNextPageParam: (lastPage) => {
        if (!lastPage?.totalPages) return undefined

        const hasNext = lastPage.totalPages > (lastPage.pageNumber || 0)
        if (hasNext) {
          return (lastPage.pageNumber || 0) + 1
        } else {
          return undefined
        }
      },
      getPreviousPageParam: (lastPage) => {
        if (!lastPage) return undefined

        const prev = (lastPage.pageNumber || 0) - 1
        return prev < 1 ? undefined : prev
      },
      cacheTime: 0,
    }
  )

  useEffect(() => {
    if (!selected) {
      setOptions([])
    }
  }, [selected])

  const handleChange = (selectedOption) => {
    if (setSelected) {
      if (selectedOption) {    
      let selected = {
        id: selectedOption.value,
        name: selectedOption.label,
      }
      setSelected(selected)
      } else {
        setSelected(undefined)
      }
    }
  }

  useEffect(() => {
    let list = data?.pages?.flatMap((q) => q?.records) || []
    list = list.filter((q) => q !== undefined)
    const result = list.map((q) => {
      return {
        value: q?.id,
        label: `${q?.name}`,
      }
    })

    setOptions(result)
  }, [data, dataUpdatedAt])

  const handleInputChange = (value) => {
    setKeyword(value)
  }

  useEffect(() => {
    if (debouncedSearchTerm === undefined) return
    refetch()
  }, [debouncedSearchTerm])

  const handleScrollToBottom = () => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }

  return (
    <SelectWithMoreWrapper hasMore={hasNextPage || false} isLoading={isFetching}>
      <Select
        {...rest}
        name='courseQuiz'
        isLoading={isLoading}
        options={options}
        onChange={handleChange}
        placeholder={placeholder}
        value={!selected ? null : options.find((option) => option.value === selected)} // Set the initial selected value
        classNamePrefix='react-select'
        className={clsx(
          'react-select',
          'menu-outer-top',
          className,
        )}
        isClearable={isClearable}
        onInputChange={handleInputChange}
        onMenuOpen={refetch}
        styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
        menuPortalTarget={document.body}
        menuShouldScrollIntoView={false}
        isDisabled={disabled}
        onMenuScrollToBottom={handleScrollToBottom}
      />
    </SelectWithMoreWrapper>
  )
}

export default CourseQuizSelect
