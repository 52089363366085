import {useEffect, useState} from 'react'
import {getTotalPendingSurvey} from '../../survey/core/_requests'
import {Link} from 'react-router-dom'

const PendingSurveyCard = () => {
  const [totalPending, setTotalPending] = useState(0)

  useEffect(() => {
    getTotalPendingSurvey().then((resp) => {
      setTotalPending(resp.data ?? 0)
    })
  })

  const pendingStyle = {
    backgroundColor: 'rgb(252, 223, 226)',
    color: 'rgb(232, 0, 26)',
  }

  return (
    <Link to='/student-survey'>
      <div className='card pending-survey-total-card py-4 px-5 mb-5' style={totalPending > 0 ? pendingStyle : {}}>
        <div className='d-flex justify-content-between'>
          <div>
            <span className='fw-bold'>Pending Survey</span>
          </div>
          <div>
            <span className='fw-bold'>{totalPending}</span>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default PendingSurveyCard
