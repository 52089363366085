import React, {useEffect, useState} from 'react'
import {KTCard, stringifyRequestQuery} from '../../../../../_metronic/helpers'
import {Dropdown} from 'react-bootstrap'
import {useMutation, useQuery} from 'react-query'
import {Confirm} from '../../../../components/Confirm'
import {formatDateTime} from '../../../../utils/date'
import {ClassFormMeetModal} from './ClassFormMeetModal'
import {deleteClassMeet, getClassMeet} from '../../class-list/core/_requests'
import {IClassMeet} from '../../../../models/IClassMeet'
import {useLocation} from 'react-router-dom'
import {useAuth, MenuModel, getMenuAccess} from '../../../auth'
import LoadingButtonWrapper from '../../../../components/LoadingButtonWrapper'
import BlockLoading from '../../../../components/BlockLoading'

function ClassFormMeet({id, initialClassMeets}) {
  const [showModal, setShowModal] = useState(false)
  const [classMeets, setClassMeets] = useState<IClassMeet[]>(initialClassMeets)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [selectedId, setSelectedId] = useState<number | null>(null)
  const {pathname} = useLocation()
  const {currentMenu} = useAuth()
  const [menuAccess, setMenuAccess] = useState<MenuModel | undefined>(undefined)
  const [actionReason, setActionReason] = useState<string>()
  useEffect(() => {
    setMenuAccess(getMenuAccess(currentMenu ?? [], pathname))
  }, [currentMenu])

  // useEffect(() => {
  //   if (initialClassMeets) {
  //     setClassMeets(initialClassMeets)
  //   }
  // }, [initialClassMeets])

  useEffect(() => {
    refetch()
  }, [])

  const openModalHandler = () => {
    setSelectedIndex(null)
    setSelectedId(null)
    setShowModal(true)
  }

  const editHandler = (index: number) => {
    setShowModal(true)
    setSelectedIndex(index)
  }

  const closeHandler = () => {
    setShowModal(false)
    setSelectedIndex(null)
    refetch()
  }

  const {refetch, isLoading, isFetching} = useQuery(
    'class-meet',
    async () => {
      try {
        const response = await getClassMeet(
          stringifyRequestQuery({
            pageSize: 100,
            filter: {
              classId: id,
            },
          })
        )
        setClassMeets(response.data?.records ?? [])
      } catch (ex) {
        console.log(ex)
      }
    },
    {enabled: false}
  )
  const handleCloseDelete = () => setShowDeleteConfirm(false)

  const handleDeleteAction = async () => {
    try {
      await deleteData.mutateAsync()
    } catch (err: any) {
      console.log(err)
    }
  }

  const deleteData = useMutation(() => deleteClassMeet(selectedId, actionReason), {
    onSuccess: () => {
      setSelectedId(null)
      handleCloseDelete()
      refetch()
    },
    onError: (err: any) => {},
  })

  const handleOpenConfirmation = (id) => {
    setSelectedId(id)
    setShowDeleteConfirm(true)
  }

  return (
    <KTCard className='p-8'>
      <div className='mb-8'>
        <div className='d-flex justify-content-end'>
          <LoadingButtonWrapper isLoading={isLoading}>
            <button
              type='button'
              className='btn btn-outline btn-outline-warning me-2'
              onClick={() => refetch()}
              disabled={isFetching}
            >
              <i className='fas fa-refresh me-1'></i>
              Refresh
            </button>
          </LoadingButtonWrapper>

          {menuAccess?.canCreate && (
            <button type='button' className='btn btn-warning' onClick={openModalHandler}>
              <i className='fas fa-plus'></i>
              Create Meeting
            </button>
          )}
        </div>
      </div>
      {showModal && (
        <ClassFormMeetModal
          id={id}
          show={showModal}
          data={selectedIndex !== null ? classMeets[selectedIndex] : null}
          onClose={closeHandler}
          showLecturer={true}
        />
      )}

      <div className='table-responsive'>
        {isFetching && <BlockLoading />}
        <table className='table align-middle table-striped fs-6 gy-5 dataTable no-footer'>
          <thead>
            <tr>
              <th
                style={{minWidth: 50}}
                className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                No
              </th>
              <th
                style={{minWidth: 80}}
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                Category
              </th>
              <th
                style={{minWidth: 80}}
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                Type
              </th>
              <th
                style={{minWidth: 80}}
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                Room
              </th>
              <th
                style={{minWidth: 80}}
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                Start Date
              </th>
              <th
                style={{minWidth: 80}}
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                Duration
              </th>
              <th
                style={{minWidth: 100}}
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                Pass Code
              </th>
              <th
                style={{minWidth: 100}}
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                Description
              </th>
              <th
                style={{minWidth: 100}}
                className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                Need Survey?
              </th>
              <th
                style={{minWidth: 100}}
                className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                Blast Email?
              </th>
              <th
                style={{width: 120}}
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                Actions
              </th>
            </tr>
          </thead>

          {classMeets?.length > 0 ? (
            <tbody className='text-gray-600 fw-bold'>
              {classMeets?.map((m, index) => (
                <tr key={index}>
                  <td className='text-center'>{index + 1}</td>
                  <td>{m.categoryName}</td>
                  <td>{m.typeName}</td>
                  <td>{m.roomName}</td>
                  <td>{formatDateTime(m.startDate)}</td>
                  <td>{m.duration}</td>
                  <td>{m.passCode}</td>
                  <td>{m.description}</td>
                  <td className={'text-center'}>
                    {m.isNeedSurvey ? (
                      <i className='fa fa-circle-check' style={{color: 'green'}}></i>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className={'text-center'}>
                    {m.isBlastEmail ? (
                      <i className='fa fa-circle-check' style={{color: 'green'}}></i>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className='text-center'>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='light'
                        id='dropdown-basic'
                        className='btn btn-outline btn-outline-primary btn-active-light-primary btn-sm'
                      >
                        Actions
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        popperConfig={{
                          strategy: 'fixed',
                          onFirstUpdate: () => window.dispatchEvent(new CustomEvent('scroll')),
                        }}
                        className='menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                      >
                        <Dropdown.Item onClick={() => editHandler(index)}>
                          {menuAccess?.canCreate ? 'Edit' : 'View'}
                        </Dropdown.Item>
                        {menuAccess?.canDelete && (
                          <Dropdown.Item onClick={() => handleOpenConfirmation(m.id)}>
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody className='text-gray-600 fw-bold'>
              <tr>
                <td colSpan={11}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {isLoading ? '' : ' No matching records found'}
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>

      <Confirm
        name={null}
        show={showDeleteConfirm}
        handleClose={handleCloseDelete}
        handleConfirm={handleDeleteAction}
        body={(
          <>
            <div>Are you sure you want to delete item?</div>
            <MeetReasonBody
              actionReason={actionReason}
              setActionReason={setActionReason}/>
          </>
        )}
        disableConfirm={!actionReason || actionReason.length < 5}
      />
    </KTCard>
  )
}

const MeetReasonBody = ({actionReason, setActionReason, label = 'Update/Delete Reason'}) => {
  return <div className="fv-row mb-7">
    <label className="fw-bold fs-6 mb-2 required">{label}</label>
    <textarea
      rows={2}
      className="form-control"
      minLength={5}
      maxLength={256}
      value={actionReason}
      onChange={(e) => setActionReason(e.target.value)}
    ></textarea>
    <small className="text-muted">Min. 5 characters</small>
  </div>
}

export {ClassFormMeet, MeetReasonBody}
