import {KTCard} from '../../../../../_metronic/helpers'
import {GraduatedStudentHeader} from './components/header/GraduatedStudentHeader'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {GraduatedStudentTable} from './table/GraduatedStudentTable'

const GraduatedStudentList = () => {
  return (
    <>
      <div className='d-flex flex-row justify-content-between mb-8'>
        <h1 className='page-heading text-dark fw-bold fs-3 my-0'>Graduated Student</h1>
      </div>
      <KTCard>
        <GraduatedStudentHeader />
        <GraduatedStudentTable />
      </KTCard>
    </>
  )
}

const GraduatedStudentListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <GraduatedStudentList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {GraduatedStudentListWrapper}
