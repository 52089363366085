import axios from 'axios'
import {IBaseResponse} from '../../../../auth'
import {ClassMeetType} from './_mode'

const API_URL = process.env.REACT_APP_BASE_API_URL
const URL = `${API_URL}/reports/total-classmeet-semester`

const getClassmeetSemester = async (query: string): Promise<IBaseResponse<ClassMeetType[]>> => {
  const response = await axios.get(`${URL}?${query}`)
  return response.data
}

export {getClassmeetSemester}
