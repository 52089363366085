import axios from 'axios'
import {IBaseResponse} from '../../../../auth'
import {LecturerAttendanceType} from './_model'

const API_URL = process.env.REACT_APP_BASE_API_URL
const STUDENT_SEMESTER = `${API_URL}/reports/lecturer-attendance`

const getLecturerAttendance = async (
  query: string
): Promise<IBaseResponse<LecturerAttendanceType[]>> => {
  const response = await axios.get(`${STUDENT_SEMESTER}?${query}`)
  return response.data
}

const getLecturerAttendanceDetail = async (
  classId: number,
  query: string
): Promise<IBaseResponse<LecturerAttendanceType[]>> => {
  const response = await axios.get(`${STUDENT_SEMESTER}/${classId}?${query}`)
  return response.data
}

export {getLecturerAttendance, getLecturerAttendanceDetail}
