import {useEffect, useState} from 'react'
import {answerSurveyByToken, validateToken} from '../core/_requests'
import {ISurvey} from '../../../models/ISurvey'
import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useNavigate, useParams} from 'react-router-dom'
import SurveyAnswerError from './SurveyAnswerError'
import BlockLoading from '../../../components/BlockLoading'
import SurveyClassInfo from './SurveyClassInfo'
import Countdown from 'react-countdown'
import CountDownTpl from './CountDownTpl'
import moment from 'moment'

export function SurveyAnswer() {
  const params = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<ISurvey>()
  const [token, setToken] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [countDown, setCountDown] = useState(0)
  const [countDownKey, setCountDownKey] = useState('')

  useEffect(() => {
    const _token = params.token
    if (!_token) {
      return
    }

    setToken(_token)
  }, [params])

  useEffect(() => {
    if (token) {
      setIsLoading(true)
      validateToken(token ?? '')
        .then((result) => {
          if (result.code === '0000') {
            setData(result.data)
            if(result.data?.surveyDeadline) {
              const milliseconds = moment(result.data?.surveyDeadline).valueOf()
              setCountDown(milliseconds)
              setCountDownKey(milliseconds.toString())
            }
          } else {
            setError(result.message ?? '')
          }
          setIsLoading(false)
        })
        .catch((e) => {
          setError(e.message)
          setIsLoading(false)
        })
    }
  }, [token])

  const validationSchema = Yup.object().shape({
    questions: Yup.array().of(
      Yup.object().shape({
        children: Yup.array().of(
          Yup.object().shape({
            questionAnswer: Yup.string().required('Pertanyaan harus diisi'),
          })
        ),
      })
    ),
  })

  const renderCountdown = ({days, hours, minutes, seconds, completed}) => {
    return <CountDownTpl completed={completed} days={days} hours={hours} minutes={minutes} seconds={seconds} />
  }

  return (
    <>
      {isLoading && (
        <div style={{height: 120}}>
          <BlockLoading />
        </div>
      )}
      {countDown > 0 && <div className="w-100 d-flex align-items-center justify-content-end">
        <span className="fs-5 me-2 fw-bold">Deadline:</span>
        <Countdown
          date={countDown}
          renderer={renderCountdown}
          key={countDownKey}
        ></Countdown>
      </div>}
      <div className='w-100'>
        <SurveyClassInfo data={data?.classInfo} />
      </div>
      {data && (
        <Formik
          initialValues={data}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            setIsLoading(true)
            const models = {...values, token: token}
            actions.setSubmitting(true)
            try {
              await answerSurveyByToken(token ?? '', models)
              actions.setSubmitting(true)
              setIsLoading(false)
              navigate('/survey/success', {replace: true})
            } catch (ex: any) {
              setIsLoading(false)
              console.error(ex)
              actions.setStatus(ex.message)
            } finally {
              setIsLoading(false)
              actions.setSubmitting(false)
            }
          }}
        >
          {({values, isSubmitting, status, errors, touched}) => (
            <Form>
              {status ? (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{status}</div>
                </div>
              ) : (
                ''
              )}

              <FieldArray name='questions'>
                {({push, remove}) => (
                  <>
                    <div className='survey row border-bottom mb-8'>
                      <div className='col-12'>
                        <ul style={{listStyleType: 'none', padding: 0}}>
                          {values?.questions?.map((item, index) => {
                            return (
                              <li
                                className='question-parent mb-10'
                                key={`question-parent.${index}`}
                              >
                                {/* {item.question} */}
                                <ul className='mt-4' style={{listStyleType: 'decimal'}}>
                                  <div>
                                    {item.children?.map((child, indexChild) => (
                                      <li
                                        className='question-child mb-5'
                                        key={`question-child.${index}.${indexChild}`}
                                      >
                                        {child.question}

                                        <div className={'option-group mb-2 star-rating'}>
                                          {child.typeId == 6 &&
                                            child.questionType?.options.map(
                                              (option, indexOption) => (
                                                <>
                                                  <label
                                                    key={`option.${index}.${indexChild}.${indexOption}`}
                                                    htmlFor={`questions.${index}.children.${indexChild}.questionAnswer.${option.code}`}
                                                  >
                                                    {option.code == 'A' && (
                                                      <img
                                                        src={toAbsoluteUrl(
                                                          '/media/emoticons/star.png'
                                                        )}
                                                      />
                                                    )}
                                                    {option.code == 'B' && (
                                                      <img
                                                        src={toAbsoluteUrl(
                                                          '/media/emoticons/star.png'
                                                        )}
                                                      />
                                                    )}
                                                    {option.code == 'C' && (
                                                      <img
                                                        src={toAbsoluteUrl(
                                                          '/media/emoticons/star.png'
                                                        )}
                                                      />
                                                    )}
                                                    {option.code == 'D' && (
                                                      <img
                                                        src={toAbsoluteUrl(
                                                          '/media/emoticons/star.png'
                                                        )}
                                                      />
                                                    )}
                                                    {option.code == 'E' && (
                                                      <img
                                                        src={toAbsoluteUrl(
                                                          '/media/emoticons/star.png'
                                                        )}
                                                      />
                                                    )}
                                                  </label>
                                                  <Field
                                                    type='radio'
                                                    name={`questions.${index}.children.${indexChild}.questionAnswer`}
                                                    value={option.code}
                                                    className={`option-${indexOption}`}
                                                    id={`questions.${index}.children.${indexChild}.questionAnswer.${option.code}`}
                                                  />
                                                </>
                                              )
                                            )}

                                          {child.typeId == 2 && (
                                            <Field
                                              as='textarea'
                                              name={`questions.${index}.children.${indexChild}.questionAnswer`}
                                              rows={3}
                                              className={'form-control mb-3 mb-lg-0'}
                                              autoComplete='off'
                                            />
                                          )}
                                        </div>
                                        <div style={{height: '21px'}}>
                                          <ErrorMessage
                                            name={`questions.${index}.children.${indexChild}.questionAnswer`}
                                            component={'div'}
                                            className='alert-error is-invalid'
                                          />
                                        </div>
                                      </li>
                                    ))}
                                  </div>
                                </ul>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className='float-end mt-2'>
                      <button
                        type='submit'
                        onClick={() => {
                          setTimeout(() => {
                            var modal = document.body
                            var targetDiv = modal.querySelector('.is-invalid')
                            if (modal && targetDiv) {
                              var modalRect = modal.getBoundingClientRect()
                              var targetDivRect = targetDiv.getBoundingClientRect()
                              window.scrollTo({
                                top: targetDivRect.top + 100,
                                behavior: 'smooth', // Optional: Smooth scrolling animation
                              })
                            }
                          }, 300)
                        }}
                        className='btn btn-warning'
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      )}
      {error && <SurveyAnswerError message={error} />}
    </>
  )
}
