import {FC} from 'preact/compat'
import {Link, useLocation} from 'react-router-dom'

const LecturerReportTab: FC = () => {
  const location = useLocation()

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <div className='d-flex flex-row justify-content-between mb-5'>
                    <h1 className='page-heading text-dark fw-bold fs-3 my-0'>Lecturer Reports</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-danger text-nowrap me-6 ` +
                  (location.pathname === '/reports/lecturers/lecturer-subject' && 'active')
                }
                to='/reports/lecturers/lecturer-subject'
              >
                Lecturer Subject
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-danger text-nowrap me-6 ` +
                  (location.pathname === '/reports/lecturers/lecturer-skill' && 'active')
                }
                to='/reports/lecturers/lecturer-skill'
              >
                Lecturer Skill
              </Link>
            </li>

            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-danger text-nowrap me-6 ` +
                  (location.pathname === '/reports/lecturers/lecturer-attendance' && 'active')
                }
                to='/reports/lecturers/lecturer-attendance'
              >
                Lecturer Attendance
              </Link>
            </li>

            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-danger text-nowrap me-6 ` +
                  (location.pathname === '/reports/lecturers/lecturer-not-attend' && 'active')
                }
                to='/reports/lecturers/lecturer-not-attend'
              >
                Lecturer Not Attend
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default LecturerReportTab
