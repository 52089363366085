import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useZxing} from 'react-zxing'
import {attendByQr, endMeeting, startMeeting} from '../../core/_request'
import AttendanceStudentSuccess from '../../../attendance/components/AttendanceStudentSuccess'
import AttendanceStudentError from '../../../attendance/components/AttendanceStudentError'
import BlockLoading from '../../../../components/BlockLoading'
import {ImageViewer} from '../../../../components/ImagePicker'
import {getLecturerImageThumbnailUrl, getStudentImageThumbnailUrl} from '../../../../utils/img'
import {formatTime} from '../../../../utils/date'
import MeetStatusEnum from '../../../../enums/MeetStatusEnum'
import {showError} from '../../../../utils/toast'
import {KTIcon} from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import {FormattedTime} from 'react-intl'
import {IClassMeet} from '../../../../models/IClassMeet'

type AttendResponse = {
  isSuccess?: boolean
  message?: string
  name?: string
  rawPicture?: string
  picture?: string
  id?: number
}

const Scanner = ({onChange}) => {
  const [scanResult, setScanResult] = useState('')

  useEffect(() => {
    if (scanResult) {
      onChange(scanResult)
    }
  }, [scanResult])

  const {ref} = useZxing({
    onDecodeResult(result) {
      setScanResult(result.getText())
    },
  })

  return <video ref={ref} />
}

const OperatorScanModal = ({classId, classMeets, show, onClose, classInfo}) => {
  const [isFullscreen, setIsFullscreen] = useState<true | string | undefined>(undefined)
  const [attendResponse, setAttendResponse] = useState<AttendResponse | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedClassMeet, setSelectedClassMeet] = useState<IClassMeet|null>(null)
  // const [actualDates, setActualDates] = useState({
  //   start: data.actualStartTime,
  //   end: data.actualEndTime,
  // })
  // const [statusId, setStatusId] = useState<number | null>(data.statusId ?? 0)
  const handleClose = () => {
    onClose()
  }

  console.log(classInfo)

  const toggleFullscreen = () => {
    setIsFullscreen((prev) => (prev === undefined ? true : undefined))
  }

  const submitHandler = async (token) => {
    try {
      setIsLoading(true)
      const response = await attendByQr(classId, token, selectedClassMeet?.id)
      if (response.code === '0000') {
        const picUrl =
          response.data.roleCode === 'S'
            ? `${getStudentImageThumbnailUrl(response.data.id)}`
            : `${getLecturerImageThumbnailUrl(response.data.id)}`

        setAttendResponse({
          isSuccess: true,
          id: response.data.id,
          name: response.data.name,
          rawPicture: picUrl,
          picture: `${picUrl}?${Date.now()}`,
        })
      } else {
        setAttendResponse({
          isSuccess: false,
          message: response.message,
        })
      }
    } catch (e: any) {
      setAttendResponse({
        isSuccess: false,
        message: e.message,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const changeHandler = async (text) => {
    // alert(text)
    await submitHandler(text)
  }

  const backToCapture = () => {
    setAttendResponse(null)
  }

  const reload = () => {
    setAttendResponse((prev) => ({
      ...prev,
      picture: prev?.rawPicture + '?' + Date.now(),
    }))
  }

  // const startMeetingHandler = async () => {
  //   try {
  //     setIsLoading(true)
  //     const {code, message, data} = await startMeeting(classId)
  //     if (code === '0000') {
  //       setActualDates((prev) => ({...prev, start: data}))
  //       setStatusId(+MeetStatusEnum.OnProgress)
  //     } else {
  //       showError(message)
  //     }
  //   } catch (e: any) {
  //     showError(e.message)
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }

  // const endMeetingHandler = async () => {
  //   try {
  //     setIsLoading(true)
  //     const {code, message, data} = await endMeeting(classId)
  //     if (code === '0000') {
  //       setActualDates((prev) => ({...prev, end: data}))
  //       setStatusId(+MeetStatusEnum.Completed)
  //     } else {
  //       showError(message)
  //     }
  //   } catch (e: any) {
  //     showError(e.message)
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }

  useEffect(() => {
    setTimeout(() => {
      setAttendResponse(null)
    }, 300)
  }, [show])

  const handleMeetClick = (item: IClassMeet) => {
    setSelectedClassMeet(item)
  }

  return (
    <Modal show={show} onHide={handleClose} fullscreen={isFullscreen} centered={true}>
      {isFullscreen}
      <Modal.Header closeButton className='pt-2 pb-0'>
        <div className='d-flex justify-content-between w-100'>
          <Modal.Title>
            <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
              <span className='text-gray-900 fw-bold text-hover-primary fs-4'>
                {classInfo.courseName}
              </span>
              <span className='text-gray-600 fw-bold text-hover-primary fs-6'>
                {classInfo.code}{selectedClassMeet &&
                  <>
                    <span> - <FormattedTime value={selectedClassMeet.startDate} hour12={false}></FormattedTime></span>
                  </>
                }
              </span>
            </div>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className='p-4 scanner-modal-body' id='modal-body'>
        {isLoading && <BlockLoading />}
        <div
          className={clsx('w-100 d-flex flex-center flex-column',
            {'flex-start': attendResponse === null && classMeets.length > 0 && selectedClassMeet === null})}
          style={{flexGrow: 1}}>
          {attendResponse && attendResponse.isSuccess && (
            <div className='d-flex flex-column flex-center scan-result-body'>
              <div style={{flexGrow: 1}}>
                <AttendanceStudentSuccess
                  message={attendResponse?.name}
                  picture={attendResponse.picture}
                />
              </div>
              <div className='d-flex justify-content-between w-100'>
                <button
                  type='button'
                  className='btn btn-outline btn-outline-danger btn-sm mt-8'
                  onClick={reload}
                >
                  <i className='fa-solid fa-rotate-right'></i> Reload
                </button>
                <button
                  type='button'
                  className='btn btn-danger btn-sm mt-8'
                  onClick={backToCapture}
                >
                  <i className='fa-solid fa-arrow-right'></i> Continue
                </button>
              </div>
            </div>
          )}
          {attendResponse && !attendResponse.isSuccess && (
            <div className='d-flex flex-column flex-center scan-result-body'>
              <div style={{flexGrow: 1}}>
                <AttendanceStudentError message={attendResponse?.message} />
              </div>
              <div className='d-flex justify-content-between w-100'>
                <div></div>
                <button
                  type='button'
                  className='btn btn-danger btn-sm mt-8'
                  onClick={backToCapture}
                >
                  <i className='fa-solid fa-arrow-right'></i>
                  Continue
                </button>
              </div>
            </div>
          )}
          {attendResponse === null && (classMeets.length <= 1 || selectedClassMeet !== null) && (
            <>
              <div className='video-container'>{show && <Scanner onChange={changeHandler} />}</div>
            </>
          )}
          {attendResponse === null && classMeets.length > 1 && selectedClassMeet === null && (
            <div className='d-flex flex-column w-100 overflow-y-auto' style={{height: 'calc(100vh - 326px)'}}>
              <div className='fw-bolder fs-3'>Select Class Meet</div>
              <div style={{flexGrow: 1}}>
                {classMeets.map((item, i) =>
                  <div key={i} className='card mt-2 cursor-pointer' onClick={() => handleMeetClick(item)}>
                    <div className='card-body d-flex flex-row p-2 align-items-center'>
                      <div className='d-flex flex-column' style={{flexGrow: 1}}>
                        <div>
                          <KTIcon iconName='time' className="me-2"></KTIcon> <FormattedTime value={item.startDate} hour12={false}></FormattedTime>
                        </div>
                        <div>
                          <KTIcon iconName='pencil' className="me-2"></KTIcon> {item.description || 'No Description'}
                        </div>
                        <div>
                          <KTIcon iconName='home-1' className="me-2"></KTIcon> {item.roomName || '-'}
                        </div>
                      </div>
                      <KTIcon iconName='right' className='fs-3'></KTIcon>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default OperatorScanModal
