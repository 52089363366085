import {IBaseResponse} from '../../../../auth'
import axios from 'axios'
import {SurveyStudentInfo} from './_model'

const API_URL = process.env.REACT_APP_BASE_API_URL
const URL = `${API_URL}/surveys/student-info`

const getSurveyStudentInfo = async (query: string): Promise<IBaseResponse<SurveyStudentInfo[]>> => {
  const response = await axios.get(`${URL}?${query}`)
  return response.data
}

export {getSurveyStudentInfo}
