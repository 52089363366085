import {useEffect, useState} from 'react'
import {QuizResultByGroupListSearch} from './QuizResultByGroupListSearch'
import {QuizResultByGroupListToolbar} from './QuizResultByGroupListToolbar'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import CourseQuizSelect from '../../../result-quiz-list/components/select/CourseQuizSelect'
import {ISemester} from '../../../../../../models/ISemester'
import {CourseQuiz} from '../../../../../courses/course-list/core/_models'
import {initialQueryState} from '../../../../../../../_metronic/helpers'
import SemesterSelect from '../../../../../classes/components/SemesterSelect'

const QuizResultByGroupListHeader = () => {
  const [selectedSemester, setSelectedSemester] = useState<ISemester>()
  const [selectedQuiz, setSelectedQuiz] = useState<CourseQuiz>()
  // const [orderBy, setOrderBy] = useState('rank')
  const [keyword, setKeyword] = useState('')
  const {refetch} = useQueryResponse()
  const {updateState} = useQueryRequest()
  // const debouncedKeyword = useDebounce(keyword, debouceTimeout)

  // useEffect(() => {
  //   if (debouncedKeyword !== undefined && keyword != undefined) {
  //     updateState({...initialQueryState, keyword: debouncedKeyword})
  //     setTimeout(() => {
  //       refetch()
  //     }, 500)
  //   }
  // }, [debouncedKeyword])

  // useEffect(() => {
  //   if (selectedQuiz) {
  //     updateState({
  //       ...initialQueryState,
  //       keyword: keyword,
  //       filter: {
  //         courseQuizId: selectedQuiz?.id,
  //         courseQuizName: selectedQuiz?.name,
  //         // order: orderBy,
  //       },
  //     })
  //     setTimeout(() => {
  //       refetch()
  //     }, 500)
  //   }
  // }, [selectedQuiz])

  const filter = () => {
    if (selectedQuiz) {
      updateState({
        ...initialQueryState,
        keyword: keyword,
        filter: {
          courseQuizId: selectedQuiz?.id,
          courseQuizName: selectedQuiz?.name,
          // order: orderBy,
        },
      })
      setTimeout(() => {
        refetch()
      }, 100)
    }
  }

  const handleChangeSemester = (selected) => {
    if (!selected || selected !== selectedSemester) {
      setSelectedSemester(selected)
      setSelectedQuiz(undefined)
    }
  }

  // const handleChangeOrder = (order: string) => {
  //   setOrderBy(order)
  // }

  return (
    <div className='card-header border-0 pt-6'>
      <div className='d-flex align-items-center'>
        <SemesterSelect
          selected={selectedSemester}
          setSelected={handleChangeSemester}
          className='w-350px me-4'
        />
        <CourseQuizSelect
          selected={selectedQuiz}
          setSelected={setSelectedQuiz}
          className='w-100 w-xl-350px me-4'
          semesterId={selectedSemester?.id}
          disabled={!selectedSemester}
        />
        <input
          type='text'
          className='form-control w-250px'
          placeholder='Search By Name'
          value={keyword}
          disabled={!selectedQuiz}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <button
          onClick={filter}
          disabled={!selectedQuiz}
          type='button'
          className='btn btn-primary w-100 w-xl-auto ms-xl-2 ms-0 mt-2 mt-xl-0'
          style={{height: '43px'}}
        >
          Go
        </button>
      </div>
      <div className='card-toolbar'>
        <QuizResultByGroupListToolbar />
      </div>
    </div>
  )
}

export {QuizResultByGroupListHeader}
