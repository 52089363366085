import {useEffect, useRef, useState} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTIcon} from '../../../../../../_metronic/helpers'
import {getInitQuery, useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import MajorSelect from '../../../../majors/components/MajorSelect'
import {Dropdown} from 'react-bootstrap'
import {DataStatusSelect} from '../../../../data-status/components/DataStatusSelect'
import SubjectSelect from '../../../../subjects/components/SubjectSelect'
import {ISubject} from '../../../../../models/ISubject'
import DegreeSelect from '../../../../degrees/components/DegreeSelect'
import {getSavedState, setSavedState} from '../../../../../utils/saved-state'
import Select from 'react-select'
import {PeriodStatusEnum} from '../../../../../enums/PeriodStatusEnum'

const CourseListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [subject, setSubject] = useState<ISubject | undefined>(undefined)
  const [degreeId, setDegreeId] = useState<number | null>(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [totalFilter, setTotalFilter] = useState(0)
  const [status, setStatus] = useState<number>(1)
  const [subjectInit, setSubjectInit] = useState<any | null>(null)
  const [periodStatus, setPeriodStatus] = useState<PeriodStatusEnum | null>(
    PeriodStatusEnum.Current
  )

  const periodStatusData = [
    {
      label: 'All',
      value: PeriodStatusEnum.All,
    },
    {
      label: 'Current',
      value: PeriodStatusEnum.Current,
    },
    {
      label: 'Past',
      value: PeriodStatusEnum.Past,
    },
  ]

  useEffect(() => {
    MenuComponent.reinitialization()
    const savedState = getSavedState('course-table')
    if (savedState) {
      if (!savedState.filter) {
        return
      }
      if (savedState?.filter?.degreeId) {
        setDegreeId(savedState?.filter?.degreeId)
      }
      if (savedState?.filter?.statusId) {
        setStatus(savedState?.filter?.statusId)
      }
      if (savedState?.filter.periodStatus) {
        setPeriodStatus(savedState?.filter.periodStatus)
      }
      if (savedState?.filter?.subjectId) {
        const subjectItem = {
          id: savedState?.filter?.subjectId,
          name: savedState?.filter?.subjectName,
          periodStatus: savedState?.filter.periodStatus,
        }
        setSubjectInit(subjectItem)
        setSubject(subjectItem)
      }
      countFilter()

      updateState(savedState)
    } else {
      resetData()
    }
  }, [])

  const resetData = () => {
    setDegreeId(null)
    setSubject(undefined)
    setDropdownOpen(false)
    setStatus(1)
    setTotalFilter(2)
    setPeriodStatus(PeriodStatusEnum.Current)
    handleRefetch(null)

    updateState({
      ...initialQueryState,
      filter: {
        statusId: 1,
        periodStatus: PeriodStatusEnum.Current,
      },
    })
  }

  const filterData = () => {
    updateState({
      page: 1,
      pageNumber: 1,
      pageSize: 10,
      filter: {
        degreeId,
        subjectId: subject?.id,
        subjectName: subject?.name,
        statusId: status === -1 ? null : status || null,
        periodStatus: periodStatus,
      },
    })
    setDropdownOpen(false)
    countFilter()
  }

  const countFilter = () => {
    let count = subject !== undefined ? 1 : 0
    count += degreeId !== null ? 1 : 0
    count += status == -1 ? 0 : 1
    count++ // period status always set
    setTotalFilter(count)
  }

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const dropdownRef = useRef<any>(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const refetchRef = useRef<(newDegreeId?: number | null) => void>()

  const handleRefetch = (newDegreeId: number | null) => {
    if (refetchRef.current) {
      refetchRef.current(newDegreeId)
    }
  }

  return (
    <Dropdown
      className='me-3'
      show={dropdownOpen}
      onToggle={toggleDropdown}
      autoClose={false}
      ref={dropdownRef}
    >
      <Dropdown.Toggle id='dropdown-custom-components' className='w-md-auto w-100' variant='light'>
        <KTIcon iconName='filter' className='fs-2' />
        <span className='pe-3'>Filter</span>
        {totalFilter > 0 && (
          <span className='badge badge-square badge-dark text-bg-dark' style={{lineHeight: '1'}}>
            {totalFilter}
          </span>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className='p-8' style={{width: 300}} align='end'>
        {/* <div className="mb-10">
          <label className="form-label fs-6 fw-bold mb-2">Major:</label>
          <MajorSelect selectedValue={majorId} isUseFormik={false} onChange={changeHandler} />
        </div> */}
        <div className='mb-10'>
          <label className='form-label fs-6 fw-bold mb-2'>Degree:</label>
          <DegreeSelect
            selectedValue={degreeId}
            isUseFormik={false}
            onChange={(selected) => {
              setDegreeId(selected.value)
              handleRefetch(selected.value)
            }}
          />
        </div>
        <div className='mb-10'>
          <label className='form-label fs-6 fw-bold mb-2'>Subject:</label>
          <SubjectSelect
            initialOptions={[subjectInit]}
            selected={subject}
            setSelected={setSubject}
            degreeId={degreeId}
            refetchCallback={(refetch) => {
              refetchRef.current = refetch
            }}
          />
        </div>
        <div className='mb-10'>
          <label className='form-label fs-6 fw-bold mb-2'>Status:</label>
          <DataStatusSelect
            selectedValue={status}
            allowClear={false}
            isUseFormik={false}
            onChange={(selected) => setStatus(selected?.value)}
          />
        </div>
        <div className='mb-10'>
          <label className='form-label fs-6 fw-bold mb-2'>Range Semester:</label>
          <Select
            options={periodStatusData}
            onChange={(selected) => {
              if (selected) {
                setPeriodStatus(selected.value)
              }
            }}
            value={periodStatusData.find((q) => q.value === periodStatus)}
            classNamePrefix='react-select'
            isMulti={false}
            className={'react-select'}
            isSearchable={false}
            styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
            menuPortalTarget={document.body}
            menuShouldScrollIntoView={false}
          />
        </div>
        <div className='d-flex justify-content-end'>
          <button
            type='button'
            disabled={isLoading}
            onClick={resetData}
            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
            data-kt-menu-dismiss='true'
            data-kt-student-table-filter='reset'
          >
            Reset
          </button>
          <button className='btn btn-warning' type='button' onClick={filterData}>
            Apply
          </button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export {CourseListFilter}
