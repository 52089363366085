import {useEffect} from 'react'

function usePreventScrollOnNumberInput(): void {
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (
        document.activeElement instanceof HTMLInputElement &&
        document.activeElement.type === 'number'
      ) {
        event.preventDefault()
      }
    }

    window.addEventListener('wheel', handleWheel, {passive: false})

    return () => {
      window.removeEventListener('wheel', handleWheel)
    }
  }, [])
}

export default usePreventScrollOnNumberInput
