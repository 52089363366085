// @ts-nocheck
import { Column } from 'react-table'
import { StudentAttendanceSummary } from '../../core/_model'
import { StudentAttendanceSummaryCustomHeader } from './StudentAttendanceSummaryCustomHeader'

const StudentAttendanceSummaryColumns: ReadonlyArray<Column<StudentAttendanceSummary>> = [
  {
    Header: (props) => (
      <StudentAttendanceSummaryCustomHeader
        tableProps={props}
        title='Name'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'name',
    id: 'name',
  },
  {
    Header: (props) => (
      <StudentAttendanceSummaryCustomHeader
        tableProps={props}
        title='Total Student'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'total',
    id: 'total',
  },
]

export { StudentAttendanceSummaryColumns }

