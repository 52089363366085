import {FC, useState} from 'react'
import {KTIcon, stringifyRequestQuery} from '../../../../_metronic/helpers'
import ScheduleCardDetail from '../../schedules/components/ScheduleCardDetail'
import {useQuery} from 'react-query'
import {getClassmeetSchedule} from '../../schedules/schedule-list/core/_request'
import './CardCounter.css'
import {ReactComponent as IconSvg} from './icons/classroom.svg'
import moment from 'moment'
import clsx from 'clsx'
import ClassroomIcon from './icons/ClassroomIcon'

const TodayClassCard: FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [majorId, setMajorId] = useState<number | undefined>()
  const [filtered, setFiltered] = useState<any[]>([])

  const colors = ['warning', 'danger', 'success']

  const {data, isLoading} = useQuery(
    'schedule-only-classmeet',
    async () => {
      try {
        const response = await getClassmeetSchedule(
          stringifyRequestQuery({
            keyword: '',
            pageSize: 30,
            filter: {
              startDate: moment().startOf('day').toISOString(),
              endDate: moment().endOf('day').toISOString(),
            },
          })
        )
        return response.data
      } catch (ex) {
        console.log(ex)
      }
    },
    {enabled: true, cacheTime: 0}
  )

  return (
    <>
      <ScheduleCardDetail
        show={showModal}
        items={majorId && data?.group ? filtered : data?.group ?? []}
        handleClose={() => setShowModal(false)}
      />
      <div className='d-flex justify-content-center flex-column me-3 mb-3'>
        <div className='d-flex justify-content-between'>
          <p className='fs-5 fw-bolder text-gray-700 text-hover-primary me-1 mb-2 mt-4 lh-1'>
            Today Class
          </p>
        </div>
      </div>

      <div className=''>
        <div className='row'>
          <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 d-flex mt-4'>
            <CustomCard
              name='TOTAL'
              type='primary'
              isUsePointerCursor={data?.total > 0}
              count={isLoading ? '...' : data?.total ?? 0}
              onClick={() => {
                setMajorId(undefined)
                if (data?.total > 0) {
                  setShowModal(true)
                }
              }}
            />
          </div>

          <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-9 mt-4'>
            <div className='row'>
              {data?.majors?.map((value, idx) => {
                return (
                  <div className='col-sm-12 col-md-4 col-lg-6 col-xl-4 mb-4' key={idx}>
                    <CustomChildCard
                      name={`S${value.degreeId}`}
                      isUsePointerCursor={value.count > 0}
                      majorName={value.code ?? value.name}
                      type={colors[idx % colors.length]}
                      count={isLoading ? '...' : value?.count ?? 0}
                      onClick={() => {
                        const temp = data?.group
                          ?.map((dayEntry) => ({
                            ...dayEntry,
                            items: dayEntry.items.filter((item) => item.majorId === value.majorId),
                          }))
                          .filter((dayEntry) => dayEntry.items.length > 0)

                        if (temp.length <= 0) {
                          return
                        }

                        setMajorId(value.majorId)
                        setFiltered(temp)
                        setShowModal(true)
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

type CustomCardProps = {
  type: string
  count: string | number
  name: string
  isUsePointerCursor: boolean
  onClick: () => void
}

const CustomCard: FC<CustomCardProps> = ({type, count, name, isUsePointerCursor, onClick}) => {
  return (
    // <div
    //   className={`card-counter bg-${type}`}
    //   onClick={onClick}
    //   role={isUsePointerCursor ? 'button' : ''}
    // >
    //   <div className='row'>
    //     <div className='col-6'>
    //       <div className='row'>
    //         <div className='col-12 col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12'>
    //           <IconSvg
    //             className='icon'
    //             style={{
    //               width: '50%',
    //               height: '100%',
    //             }}
    //           />
    //         </div>
    //         <div className='col-12 col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12'>
    //           <span className='count-name text-white'>{name}</span>
    //         </div>
    //       </div>
    //     </div>

    //     <div className='col-6'>
    //       <span className='count-numbers text-white'>{count}</span>
    //     </div>
    //   </div>
    // </div>
    <div
      className={`card d-flex flex-row align-items-center w-100 bg-${type}`}
      style={{height: '10rem'}}
      onClick={onClick}
      role={isUsePointerCursor ? 'button' : ''}
    >
      <div
        className='d-flex align-items-center'
        style={{width: '50px', margin: '10px', flexShrink: 0}}
      >
        <ClassroomIcon className='responsive-svg' />
      </div>

      <div className='card-body p-3 flex-grow-1'>
        <h5 className='card-title mb-0 text-white fs-3 d-inline d-md-block'>{name}</h5>
      </div>

      <div className='p-3 text-end d-flex align-items-center justify-content-center flex-column flex-md-row'>
        <span className='fs-2x fs-xs-3x me-8 text-white'>{count}</span>
      </div>
    </div>
  )
}

type CustomChildCardProps = {
  type: string
  count: string | number
  name: string
  majorName: string
  isUsePointerCursor: boolean
  onClick: () => void
}

const CustomChildCard: FC<CustomChildCardProps> = ({
  type,
  count,
  name,
  majorName,
  isUsePointerCursor,
  onClick,
}) => {
  return (
    <div
      className={`card d-flex flex-row align-items-center bg-${type}`}
      onClick={onClick}
      role={isUsePointerCursor ? 'button' : ''}
      style={{height: 100}}
    >
      <div
        className='d-flex align-items-center'
        style={{width: '42px', margin: '10px', flexShrink: 0}}
      >
        <ClassroomIcon className='responsive-svg' />
      </div>

      <div className='card-body p-3 text-center flex-grow-1 d-flex jsutify-content-between align-items-center'>
        <h5 className='card-title mb-0 text-white fs-4 d-inline d-md-block'>{majorName}</h5>
      </div>
      <div className='p-3 text-end d-flex align-items-center justify-content-center flex-column flex-md-row'>
        <span className='fs-2x fs-xs-3x text-white me-5'>{count}</span>
      </div>
    </div>
  )
}

export default TodayClassCard
