import {SurveyResultFilter} from './SurveyResultFilter'
import {SurveyResultToolbar} from './SurveyResultToolbar'

const SurveyResultHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <SurveyResultFilter />

      <div className='card-toolbar'>
        <SurveyResultToolbar />
      </div>
    </div>
  )
}

export {SurveyResultHeader}
