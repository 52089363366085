import {KTCard} from '../../../../../_metronic/helpers'
import {StudentSemesterListHeader} from './components/header/StudentSemesterListHeader'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {StudentSemesterTable} from './table/StudentSemesterTable'

const StudentSemesterList = () => {
  return (
    <>
      <div className='d-flex flex-row justify-content-between mb-8'>
        <h1 className='page-heading text-dark fw-bold fs-3 my-0'>Report Student Semester</h1>
      </div>
      <KTCard>
        <StudentSemesterListHeader />
        <StudentSemesterTable />
      </KTCard>
    </>
  )
}

const StudentSemesterListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <StudentSemesterList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {StudentSemesterListWrapper}
