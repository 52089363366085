import {FC} from 'react'
import {Link} from 'react-router-dom'
import {Lecturer} from '../../lecturers/lecturer-list/core/_models'
import {ImageViewer} from '../../../components/ImagePicker'
import {getLecturerImageThumbnailUrl, getLecturerImageUrl} from '../../../utils/img'
import {IClass} from '../../../models/IClass'
import {MyCourseModel} from '../../profile/core/_model'
import {IClassLecturer} from '../../../models/IClassLecturer'

type DataProps = {
  id: number
  title: string
  code: string
  description: string
  color: string
  progress: number
  // primaryLecturer: Lecturer
  // secondaryLecturer: Lecturer
  percentage: number
  subjectName: string
  degreeName: string
  majorName: string
  courseName: string
  // primaryLecturerId?: number
  // primaryLecturerName?: string
  // secondaryLecturerId?: number
  // secondaryLecturerName?: string
  classLecturers: IClassLecturer[]
  totalStudent?: number
  totalActiveStudent?: number
  name?: string
}

type ClassCardProps = {
  data: DataProps | IClass | MyCourseModel
}

const ClassCard: FC<ClassCardProps> = ({data}) => {
  return (
    <Link to={`/classes/${data.id}`}>
      <div
        className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end mb-5 h-100'
        style={{
          backgroundColor: 'white',
        }}
      >
        <div className='card-header pt-5'>
          <div className='d-flex flex-stack'>
            <div className='d-flex'>
              <div className='symbol symbol-65px me-5'>
                <span
                  className='symbol-label d-flex flex-column'
                  style={{backgroundColor: '#fcdfe2'}}
                >
                  <i className='fas fa-user-friends fs-2' style={{color: '#e8001a'}}></i>
                  <span style={{lineHeight: 1, color: '#e8001a', fontWeight: 600, marginTop: 2}}>
                    {data.totalActiveStudent}
                  </span>
                </span>
              </div>
              <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
                <span className='card-class-name text-gray-900 fw-bold text-hover-primary fs-4'>
                  {data?.name !== null && data?.name !== '' ? data.name : data.courseName}
                </span>
                <span className='text-gray-600 fw-bold text-hover-primary fs-6 mb-1'>
                  {data.code}
                </span>

                <div className='d-flex'>
                  <span className='text-muted fw-bold pe-5'>
                    <i className='fas fa-bookmark pe-1'></i> {data.degreeName} - {data.majorName}
                  </span>
                  {/* <span className='text-muted fw-bold'>
                    <i className='fas fa-user-friends pe-1'></i> {data.totalActiveStudent}
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body pt-0 pb-0'>
          <div className='d-flex align-items-center mt-5 w-100 mb-8'>
            <div
              className='h-8px me-3 w-100 bg-opacity-70 rounded'
              style={{backgroundColor: '#dedede'}}
            >
              <div
                className='rounded h-8px'
                role='progressbar'
                style={{width: `${data.progress}%`, backgroundColor: '#3e97ff'}}
                aria-valuenow={data.progress}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
            <div className='fw-bold fs-7 opacity-75'>
              <span>{data.progress}%</span>
            </div>
          </div>

          {data.classLecturers.map((l, i) => (
            <div className='fs-6 d-flex align-items-center mb-2' key={i}>
              <div className='symbol-group symbol-hover flex-nowrap'>
                <div data-bs-toggle='tooltip' title={l.name}>
                  <ImageViewer
                    initialValue={getLecturerImageThumbnailUrl(l.lecturerId)}
                    width={25}
                    height={25}
                    className='symbol symbol-25px symbol-circle me-2'
                  />
                </div>
              </div>
              <div className='fw-bold fs-7'>
                <span>{l.name}</span>
              </div>
            </div>
          ))}

          {/*{data.primaryLecturerId && (*/}
          {/*  <div className='fs-6 d-flex align-items-center mb-2'>*/}
          {/*    <div className='symbol-group symbol-hover flex-nowrap'>*/}
          {/*      <div data-bs-toggle='tooltip' title={data.primaryLecturerName}>*/}
          {/*        <ImageViewer*/}
          {/*          initialValue={getLecturerImageThumbnailUrl(data.primaryLecturerId)}*/}
          {/*          width={25}*/}
          {/*          height={25}*/}
          {/*          className='symbol symbol-25px symbol-circle me-2'*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className='fw-bold fs-7'>*/}
          {/*      <span>{data.primaryLecturerName}</span>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}

          {/*{data.secondaryLecturerId && (*/}
          {/*  <div className='fs-6 d-flex align-items-center mb-2'>*/}
          {/*    <div className='symbol-group symbol-hover flex-nowrap'>*/}
          {/*      <div data-bs-toggle='tooltip' title={data.secondaryLecturerName}>*/}
          {/*        <ImageViewer*/}
          {/*          initialValue={getLecturerImageThumbnailUrl(data.secondaryLecturerId)}*/}
          {/*          width={25}*/}
          {/*          height={25}*/}
          {/*          className='symbol symbol-25px symbol-circle me-2'*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className='fw-bold fs-7'>*/}
          {/*      <span>{data.secondaryLecturerName}</span>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </div>
    </Link>
  )
}

export default ClassCard
