import {FC, useEffect} from 'react'
import {AnnouncementModalHeader} from './AnnouncementModalHeader'
import {IAnnouncement} from '../../../../models/IAnnouncement'
import {ImageViewer} from '../../../../components/ImagePicker'
import {getAnnouncementImageUrl} from '../../../../utils/img'
import SafeHTMLRenderer from '../../../../components/SafeHtmlRenderer'
import {DownloadButton} from '../../../../components/Button'
import {Modal} from 'react-bootstrap'
import {useAnnouncement} from './AnnouncementProvider'

interface Prop {
  data?: IAnnouncement
}

const AnnouncementModal: FC<Prop> = ({data}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
      <div
        className='modal fade show d-block'
        id='kt_modal_add_subject'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-center announcement-modal'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <AnnouncementModalHeader data={data} />
            {/* begin::Modal body */}
            <div className='modal-body scroll-y p-10'>
              <div className='row'>
                <div className='col-12'>
                  {data?.coverUniqueId && (
                    <ImageViewer
                      className='mb-5'
                      initialValue={getAnnouncementImageUrl(data?.coverUniqueId ?? '')}
                    />
                  )}
                </div>
                {/* <div className='col-12'>{data?.shortDescription}</div> */}
                <div className='col-12 mt-5 fs-5'>
                  <SafeHTMLRenderer htmlString={data?.body} />
                </div>
                {data?.attachmentUniqueId && (
                  <div className='col-12 mt-5'>
                    <div className='text-end'>
                      <DownloadButton
                        className='btn btn-light-warning'
                        fileName={`Announcement ${data?.title}`}
                        path={`announcements/file/attachment/${data?.attachmentUniqueId}`}
                      >
                        Download Attachment
                      </DownloadButton>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
    </>
  )
}

export {AnnouncementModal}
