import {FC, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import {useFormik, Field} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {initialCourse, Course} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {CourseListLoading} from '../components/loading/CourseListLoading'
import {createCourse, updateCourse} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import MaritalStatusSelect from '../../../marital-status/components/MaritalStatusSelect'
import CitySelect from '../../../cities/components/CitySelect'
import ProvinceSelect from '../../../provinces/components/ProviceSelect'
import DomicileSelect from '../../../domiciles/components/DomicileSelect'
import NationalitySelect from '../../../nationalities/components/NationalitySelect'
import DatePicker from '../../../../components/DatePicker'
import SubjectSelect from '../../../students/components/SubjectSelect'
import SemesterSelect from '../../../semester/components/SemesterSelect'
import {useNavigate} from 'react-router-dom'
import LoadingButtonWrapper from '../../../../components/LoadingButtonWrapper'

type Props = {
  isLoading: boolean
  course: Course
}

let yupValidation = {
  subjectId: Yup.number().required('Subject is required'),
  semesterId: Yup.number().required('Semester is required'),
}

const addCourseSchema = Yup.object().shape({
  ...yupValidation,
})
const editCourseSchema = Yup.object().shape(yupValidation)

function CourseEditModalForm({course, isLoading}) {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [degreeId, setDegreeId] = useState<number | undefined>()
  const navigate = useNavigate()

  const [courseForEdit] = useState<Course>({
    ...course,
    id: course.id || initialCourse.id,
    subjectId: course.subjectId || initialCourse.subjectId,
    semesterId: course.semesterId || initialCourse.semesterId,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: courseForEdit,
    validationSchema: isNotEmpty(course.id) ? editCourseSchema : addCourseSchema,
    onSubmit: async (values, {setSubmitting, setStatus}) => {
      setStatus(null)
      const models = {...values}
      setSubmitting(true)
      try {
        if (isNotEmpty(course.id)) {
          await updateCourse(models)
        } else {
          const res = await createCourse(models)
          navigate('/courses/' + res?.id)
        }
        setSubmitting(true)
        cancel(true)
      } catch (ex: any) {
        console.error(ex)
        setStatus(ex.message)
      } finally {
      }
    },
  })

  return (
    <>
      <form
        id='kt_modal_add_course_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div className='d-flex flex-column scroll-y me-n7 pe-7' style={{maxHeight: 450}}>
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            ''
          )}

          <div className='row'>
            <div className='col-12 col-lg-6'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Subject</label>
                <SubjectSelect
                  isClearable={false}
                  isMulti={false}
                  onChange={(data) => {
                    if (degreeId !== data.degreeId) {
                      setDegreeId(data.degreeId)
                      formik.setFieldValue('semesterId', undefined)
                    }
                  }}
                  className='menu-outer-top'
                  field={formik.getFieldProps('subjectId')}
                  initialOptions={[]}
                  form={formik}
                />
                {formik.touched.subjectId && formik.errors.subjectId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.subjectId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-12 col-lg-6'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Semester</label>
                <SemesterSelect
                  isClearable={false}
                  className='menu-outer-top'
                  field={formik.getFieldProps('semesterId')}
                  form={formik}
                  degreeId={degreeId}
                />
                {formik.touched.semesterId && formik.errors.semesterId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.semesterId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='text-end pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-courses-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Discard
          </button>

          <LoadingButtonWrapper isLoading={formik.isSubmitting || isLoading}>
            <button
              type='submit'
              className='btn btn-warning'
              data-kt-courses-modal-action='submit'
              disabled={formik.isSubmitting || isLoading}
            >
              <span className='indicator-label'>Submit</span>
            </button>
          </LoadingButtonWrapper>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <CourseListLoading />}
    </>
  )
}

export {CourseEditModalForm}
